import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DoctorSelect from "../../ReusableComponents/SelectComponent/DoctorSelect";
import SpecializationSelect from "../../ReusableComponents/SelectComponent/SpecializationSelect";
import SelectDoctorImg from "../../asstes/images/select-doctor.png";
import "./selectdoctor.scss";
import { useDispatch, useSelector } from "react-redux";
import afterLoginApiAxios from "../../Shared/afterLogin";
import swal from "sweetalert";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { setDoctorName } from "../../features/user/userSlice";
import Footer from "../Footer";
import { setDoctorId, setDoctorSlug } from "../../features/auth/authSlice";
import { getPathNameHelper } from "../../Shared/Helper";
import { setStepper } from "../../features/stepper/StepperSlice";

const SelectDoctor = (props) => {
  const SelectedSpecialization = useSelector(
    (state) => state.user.specialization
  );
  const SelectedDoctorID = useSelector((state) => state.auth.doctor_id);
  //const clinicId = useSelector((state) => state.auth.clinic_id);
  const currentStepper = useSelector((state) => state.stepper.currentStepper);
  const clinicId = sessionStorage.getItem("clinicId");

  const { isVirtual } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [selectDoctorID, setSelectDoctorID] = useState(null);
  const [selectDoctorInfo, setSelectDoctorInfo] = useState(null);
  const [nextDisabled, setNextDisabled] = useState(true);

  useEffect(() => {
    if (isVirtual === "isVirtual") {
      dispatch(setStepper(currentStepper + 1));
      navigate(`${getPathNameHelper(location.pathname)}/selectDateTime`);
    }
  }, []);

  useEffect(() => {
    if (SelectedDoctorID != null) {
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }
  }, [selectDoctorID, SelectedDoctorID]);

  const searchDoctorList = () => {
    handleSearch();
    setTimeout(() => {
      //Profile pic url validation
      handleSearch();
    }, 100);
  };

  const handleSearch = () => {
    afterLoginApiAxios
      .get(
        `api/v1/public/specializations/${
          SelectedSpecialization.id ? SelectedSpecialization.id : "all"
        }/doctors?clinic_id=${clinicId ? clinicId : "all"}` // "doctor list",
      )
      .then((response) => {
        if (response.data.statusCode == 200) {
          const updatedList = getSelectedDoctorUpdatedList(response.data.data);
          const imageCheck = updatedList.map((e) => {
            return {
              ...e,
              img: getImageOrFallback(e.profile_picture_url),
            };
          });
          setDoctorList(imageCheck);
        } else {
          setDoctorList([]);
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.status ? error.message : "Something went wrong!",
          timer: 5000,
        });
        setDoctorList([]);
      });
  };

  const getSelectedDoctorUpdatedList = (list) => {
    if (SelectedDoctorID) {
      return list.filter((item) => item.id === SelectedDoctorID);
    }
    return list;
  };

  const doctorClick = (doctor) => {
    dispatch(setDoctorId(doctor.id), setDoctorName(doctor.name));
    setSelectDoctorID(doctor.id);
    dispatch(setDoctorSlug(doctor.short_url_slug));
  };

  function getImageOrFallback(urlToFile) {
    var image = new Image();
    image.src = urlToFile;
    if (image.width === 0) {
      return null;
    } else {
      return urlToFile;
    }
  }

  return (
    <>
      <div className="SD-main-div">
        <div className="SD-header">
          <Col md={5} className="SD-spec-border">
            <SpecializationSelect
              setDoctorList={setDoctorList}
              setSelectDoctorInfo={selectDoctorInfo}
            />
          </Col>
          <Col md={1} className="SD-vl-width SD-vl-border">
            <div className="SD-vl"></div>
          </Col>
          <Col md={5} className="SD-dtr-border">
            <DoctorSelect
              setSelectDoctorID={setSelectDoctorID}
              setSelectDoctorInfo={setSelectDoctorInfo}
            />
          </Col>
          <Col md={1} className="SD-searchBtn-container">
            <Button
              disabled={
                SelectedSpecialization.id || SelectedDoctorID ? false : true
              }
              className="SD-searchBtn"
              onClick={searchDoctorList}
            >
              Search
            </Button>
          </Col>
        </div>

        <div className="SD-dtr-list">
          <div className="SD-dtr-list-view">
            <Row>
              {doctorList.length > 0 || SelectedDoctorID != null ? (
                doctorList.map((doctor, index) => {
                  return (
                    <Col md={4}>
                      <Card
                        className="SD-dtr-list-card"
                        onClick={() => doctorClick(doctor)}
                        style={
                          doctor.id == selectDoctorID
                            ? { border: "2px solid #17BAE0" }
                            : { borderColor: "#DDDDDD" }
                        }
                      >
                        <Card.Body>
                          <Card.Title className="SD-dtr-list-view-div">
                            {doctor.img !== null ? (
                              <img
                                src={doctor.img}
                                alt=""
                                className="SD-dtr-profile"
                                // onError={(e)=>{e.target.onerror = null; e.target.src=placeholder_profile }}
                              />
                            ) : (
                              <div className="profile-container no-image">
                                {doctor.doctor_first_name.charAt(0)}
                                {doctor.doctor_last_name
                                  ? doctor.doctor_last_name.charAt(0)
                                  : ""}
                              </div>
                            )}

                            <div className="ps-2">
                              <h5 className="SD-dtr-list-view-tlt">
                                {doctor.name}
                              </h5>
                              <h6 className="SD-dtr-list-view-spec">
                                {doctor.specialization}
                              </h6>
                            </div>
                          </Card.Title>
                          <Card.Text className="SD-dtr-list-view-bodyTxt">
                            <p className="SD-dtr-list-view-BioTxt">Bio</p>
                            {doctor.description}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })
              ) : (
                <div className="SD-dtr-image">
                  <img src={SelectDoctorImg} alt="" />
                </div>
              )}
            </Row>
          </div>
        </div>
      </div>
      <Footer nextIsDisabled={nextDisabled} />
      {/* <Footer nextIsDisabled={false}/> */}
    </>
  );
};

export default SelectDoctor;
