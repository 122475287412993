export const APPOINTMENT_TYPE = {
  0: "patient",
  1: "doctor",
};

// 1 : patient/clinic/isClinic -> select doctor
// 2 : patient/clinic/isVirtual -> select Date and Time(select doctor checked)
// 3 : patient/doctor/isClinic -> selct date and time(clinic selection required)
// 4 : patient/doctor/isVirtual -> select date and time

export const STEPPER_DATA_PATIENT = {
  1: [
    { label: "Select Doctor", value: 1 },
    { label: "Select Date & Time", value: 2 },
    { label: "Select Patient", value: 3 },
    { label: "Enter Details & Confirm", value: 4 },
  ],
  // 2: [
  //   { label: "Select Doctor", value: 1 },
  //   { label: "Select Date & Time", value: 2 },
  //   { label: "Select Patient", value: 3 },
  //   { label: "Enter Details & Confirm", value: 4 },
  // ],
  2: [
    { label: "Select Date & Time", value: 1 },
    { label: "Select Patient", value: 2 },
    { label: "Enter Details & Confirm", value: 3 },
  ],
  3: [
    { label: "Select Date & Time", value: 1 },
    { label: "Select Patient", value: 2 },
    { label: "Enter Details & Confirm", value: 3 },
  ],
  4: [
    { label: "Select Date & Time", value: 1 },
    { label: "Select Patient", value: 2 },
    { label: "Enter Details & Confirm", value: 3 },
  ],
  5: [
    { label: "Select Patient", value: 1 },
    { label: "Select Date & Time", value: 2 },
    { label: "Enter Details & Confirm", value: 3 },
  ],
};

export const STEPPER_ROUTE_DATA_PATIENT = {
  1: {
    1: "/patient/clinic/isClinic/selectDoctor",
    2: "/patient/clinic/isClinic/selectDateTime",
    3: "/patient/clinic/isClinic/selectPatient",
    4: "/patient/clinic/isClinic/enterDetails",
  },
  // 2: {
  //   1: "/patient/clinic/isVirtual/selectDoctor",
  //   2: "/patient/clinic/isVirtual/selectDateTime",
  //   3: "/patient/clinic/isVirtual/selectPatient",
  //   4: "/patient/clinic/isVirtual/enterDetails",
  // },
  2: {
    1: "/patient/clinic/isVirtual/selectDateTime",
    2: "/patient/clinic/isVirtual/selectPatient",
    3: "/patient/clinic/isVirtual/enterDetails",
  },
  3: {
    1: "/patient/doctor/isClinic/selectDateTime",
    2: "/patient/doctor/isClinic/selectPatient",
    3: "/patient/doctor/isClinic/enterDetails",
  },
  4: {
    1: "/patient/doctor/isVirtual/selectDateTime",
    2: "/patient/doctor/isVirtual/selectPatient",
    3: "/patient/doctor/isVirtual/enterDetails",
  },

  5: {
    1: "/patient/doctor/isVirtual/selectPatient",
    2: "/patient/doctor/isVirtual/selectDateTime",
    3: "/patient/doctor/isVirtual/enterDetails",
  },
};
