export const SESSIONSTORAGEKEY = {
  appointmentDoctorName: "appointmentDoctorName",
  transactionDoctorNameFilter: "transactionDoctorNameFilter",
  dashboardStartDate: "dashboardStartDate",
  dashboardEndDate: "dashboardEndDate",
  dashboardConsultation: "dashboardConsultation",
  dashboardDoctorName: "dashboardDoctorName",
  CLINIC_ID: "clinicId",
  USER_TYPE: "user_type",
  USER_ID: "user_id",
  ID_TOKEN: "id_token",
  SELECTED_SPECIALIZATIONS: "selectedSpecializations",
  DOCTOR_CLINIC_ASSOCIATION: "doctor_clinic_association",
  STAFF_CLINIC_ASSOCIATION: "staff_clinic_association",
  STAFF_ID: "staff_id",
  DOCTOR_ROLE: "doctorRole",
  MEETING_ID: "meeting_id",
  CAREGIVER_NAME: "caregiver_name",
  CAREGIVER_GOVT_ID: "caregiver_govt_id",
  CONSULTATION_TYPE: "procedure_consultation_type",
};
