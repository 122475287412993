import React, { useEffect, useState } from "react";
import CalendarIcon from "../../asstes/images/calendar-icon.png";
import AddIcon from "../../asstes/images/Add-icon.png";
import CheckIcon from "../../asstes/images/SP-check.png";
import cancelIcon from "../../asstes/images/alert-cancel-icon.svg";
import "./selectPatient.scss";
import { Alert, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import afterLoginApiAxios from "../../Shared/afterLogin";
import swal from "sweetalert";
import Loader from "../../ReusableComponents/Loader/Loader";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import { setPatient } from "../../features/user/userSlice";
import { setStepper } from "../../features/stepper/StepperSlice";
import moment from "moment";
import { NoUserFoundModal } from "../TeleConsultModal/NoUserFoundModal";
import { CantTakeFollowupConfirmationModal } from "../TeleConsultModal/CantTakeFollowupConfirmationModal";

const SelectPatient = () => {
  const SelectedDoctorID = useSelector((state) => state.auth.doctor_id);
  const CurrentStepper = useSelector((state) => state.stepper.currentStepper);
  const clinicId = sessionStorage.getItem("clinicId");
  const IsShowTeleConsult = useSelector(
    (state) => state.isTeleConsultSlice.IsTeleConsult
  );
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [patientList, setPatientList] = useState([]);
  const [selectedPatientID, setSelectedPatientID] = useState();
  const [nextDisabled, setNextDisabled] = useState(true);
  const [showRegularBookingModal, setShowRegularBookingModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [disableNewPatient, setDisableNewPatient] = useState(true);
  const [clinicNumber, setClinicNumber] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedPatientID != null) {
      setNextDisabled(false);
    }
  }, [selectedPatientID]);

  useEffect(() => {
    afterLoginApiAxios
      .post(
        "user/get-patient-accounts-rev2", // "patient/list-past-attendees",
        {
          patient_id: sessionStorage.getItem("user_id"), //newly added
          ...(clinicId && { clinic_id: clinicId }),
          ...(!clinicId && { doctor_id: SelectedDoctorID }),
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response) {
          let responseData = response?.data;
          if (responseData) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                setLoader(false);
                setPatientList(responseData.patient_accounts);
                setDisableNewPatient(
                  responseData.disable_public_patient_registration
                );
                setClinicNumber(responseData.clinic_number);
              } else {
                IsShowTeleConsult && setShowRegularBookingModal(true);
                setLoader(false);
              }
            } else {
              setPatientList([]);
              IsShowTeleConsult && setShowRegularBookingModal(true);
              setLoader(false);
            }
          } else {
            IsShowTeleConsult && setShowRegularBookingModal(true);
            setLoader(false);
          }
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.status ? error.message : "Something went wrong!",
          timer: 5000,
        });
        setPatientList([]);
        IsShowTeleConsult && setShowRegularBookingModal(true);
      });
  }, []);

  const IsTeleConsultApi = (account, index) => {
    afterLoginApiAxios
      .post(
        "/patient/follow-up-validity-checking-rev1",
        {
          patient_id: account.patient_id,
          patient_name: account.patient_name,
          patient_dob: account.patient_dob,
          doctor_id: SelectedDoctorID,
          patient_account_id: account.patient_account_id,
          ...(clinicId && { clinic_id: clinicId }),
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("id_token"),
          },
        }
      )
      .then((res) => {
        if (res.data.statusCode === 403) {
          setShowConfirmationModal(true);
        }
        if (res?.data?.follow_up_possible) {
          sessionStorage.setItem("lastAppointment", res.data.last_appointment);
          dispatch(setPatient(account));
          setSelectedPatientID(account.patient_account_id);
          sessionStorage.setItem("isNew", false);
          if (account && ["0", "1", "2"].includes(account.registration_type)) {
            for (const [key, value] of Object.entries(account)) {
              sessionStorage.setItem([key], value);
            }
            sessionStorage.setItem("selectedPatient", JSON.stringify(account));
            sessionStorage.setItem("patient_email", account.email);
            sessionStorage.setItem(
              "epatientPhone",
              account.patient_phone
                ? account.patient_phone.split("").splice(3).join("")
                : ""
            );
          }
        } else {
          setShowConfirmationModal(true);
        }
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          //      unAuthorizedError(() => {
          //      this.props.history.push("/patient/login");
          //     });
        } else {
          swal({
            icon: "error",
            title: "Something went wrong",
          });
        }
      });
  };
  const patientSelect = (patient, index) => {
    if (IsShowTeleConsult) {
      IsTeleConsultApi(patient, index);
    } else {
      dispatch(setPatient(patient));
      setSelectedPatientID(patient.patient_account_id);
      sessionStorage.setItem("isNew", false);
      PatientsessionStorageSetup(patient, index);
    }
  };

  const removesessionStoragePatientDetails = () => {
    sessionStorage.removeItem("patient_name");
    sessionStorage.removeItem("patient_email");
    sessionStorage.removeItem("patient_address");
    sessionStorage.removeItem("patient_pincode");
    sessionStorage.removeItem("state_code");
    sessionStorage.removeItem("patient_is_drug_allergy_present");
    sessionStorage.removeItem("patient_is_habit_present");
    sessionStorage.removeItem("is_taking_medicine");
    sessionStorage.removeItem("medication");
    sessionStorage.removeItem("patient_drug_allergy");
    sessionStorage.removeItem("patient_habits");
    sessionStorage.removeItem("patient_reg_type");
    sessionStorage.removeItem("ecaregiverName");
    sessionStorage.removeItem("ecaregiverEmail");
    sessionStorage.removeItem("ecaregiverageProof");
    sessionStorage.removeItem("ecaregivergovtID");
    sessionStorage.removeItem("epatientPhone");
    sessionStorage.removeItem("selectedPatient");
    sessionStorage.removeItem("patient_gender");
    sessionStorage.removeItem("patient_dob");
    sessionStorage.removeItem("patient_reg_type");
    sessionStorage.removeItem("caregiver_email");
    sessionStorage.removeItem("caregiver_govt_id");
    sessionStorage.removeItem("caregiver_name");
    sessionStorage.removeItem("patient_phone");
    sessionStorage.setItem("registration_type", "0");
  };

  const PatientsessionStorageSetup = (patient, index) => {
    sessionStorage.setItem("selectedPatient", JSON.stringify(patient));
    sessionStorage.setItem(
      "epatientPhone",
      patient.patient_phone
        ? patient.patient_phone.split("").splice(3).join("")
        : ""
    );
    sessionStorage.setItem("registration_type", patient.registration_type);
    sessionStorage.setItem("patient_name", patient.patient_name);
    sessionStorage.setItem("patient_gender", patient.patient_gender);
    sessionStorage.setItem("patient_email", patient.email);
    sessionStorage.setItem("patient_dob", patient.patient_dob);
    sessionStorage.setItem("patient_address", patient.patient_address);
    sessionStorage.setItem("patient_pincode", patient.patient_pincode);
    sessionStorage.setItem("state_name", patient.state_name);
    sessionStorage.setItem("state_code", patient.state_code);
    sessionStorage.setItem(
      "patient_is_drug_allergy_present",
      patient.patient_is_drug_allergy_present
    );
    sessionStorage.setItem(
      "patient_is_habit_present",
      patient.patient_is_habit_present
    );
    sessionStorage.setItem("is_taking_medicine", patient.is_taking_medicine);
    sessionStorage.setItem("medication", patient.medication);
    sessionStorage.setItem(
      "patient_drug_allergy",
      patient.patient_drug_allergy
    );
    sessionStorage.setItem("patient_habits", patient.patient_habits);
    sessionStorage.setItem(
      "existing_user_book_is_follow_up",
      patient.is_follow_up
    );
    sessionStorage.setItem("meeting_status", patient.meeting_status);
    sessionStorage.setItem("consultation_type", patient.consultation_type);
    sessionStorage.setItem("patient_phone", patient.patient_phone);
    sessionStorage.setItem(
      "appointment_start_date_time",
      patient.appointment_start_date_time
    );
    sessionStorage.setItem("meeting_status", patient.meeting_status);
    sessionStorage.setItem("patient_account_id", patient.patient_account_id);
    sessionStorage.setItem("user_id", patient.patient_id);
    sessionStorage.setItem("appointment_date", patient.appointment_date);
    sessionStorage.setItem("appointment_id", patient.appointment_id);
    sessionStorage.setItem("start_time", patient.start_time);
  };

  const addNewPaitent = (event) => {
    var enterDetailsPage =
      location.pathname.split("/").slice(0, -1).join("/") + "/enterDetails";
    dispatch(setStepper(CurrentStepper + 1));
    sessionStorage.setItem("isNew", true);
    navigate(enterDetailsPage);
    removesessionStoragePatientDetails();
  };

  return (
    <>
      {loader && <Loader />}
      <div className="select-patient-container">
        {!IsShowTeleConsult && (
          <div className="SP-header-div">
            <span className="SP-header-tlt">Appointment Date And Time</span>
            <h4 className="mt-3 time-display-box">
              <img src={CalendarIcon} alt="" />{" "}
              <span className="SP-header-stlt">
                {sessionStorage.getItem("date")
                  ? moment(sessionStorage.getItem("date"), "YYYY-MM-DD").format(
                      "DD MMM YYYY"
                    )
                  : moment().format("DD MMM YYYY")}{" "}
                AT{" "}
                {sessionStorage.getItem("meeting_id") !== null
                  ? this.state.currentTime
                  : sessionStorage.getItem("time")}
              </span>
            </h4>
          </div>
        )}
        {!IsShowTeleConsult && <hr />}
        <div className="SP-body-div">
          <h4 className="SP-body-div-tlt">
            Select the Patient you want to book an appointment for
          </h4>
          {!loader &&
            disableNewPatient &&
            patientList.length === 0 &&
            clinicId && (
              <div className="no-slot-found-alert-wrapper">
                <Alert
                  variant={"danger"}
                  style={{
                    opacity: 1,
                    maxWidth: "max-content",
                    marginTop: "24px",
                  }}
                >
                  <div className="alert-text-box" style={{ display: "flex" }}>
                    <img src={cancelIcon} alt="" />
                    <p
                      style={{
                        marginBottom: "0px",
                        padding: "0px 10px",
                        color: "#000",
                        fontWeight: "500",
                      }}
                    >
                      Patient profile creation is restricted, as you are not an
                      existing patient for this clinic. Please visit our
                      facility or call us at{" "}
                      <strong
                        style={{
                          color: "#000",
                          textDecoration: "underline",
                        }}
                      >
                        {clinicNumber}
                      </strong>
                    </p>
                  </div>
                </Alert>
              </div>
            )}
          {
            <Row
              style={{ padding: window.innerWidth > 768 ? "30px" : "5px 0" }}
            >
              {!loader &&
                !IsShowTeleConsult &&
                (!disableNewPatient || !clinicId) && (
                  <Col md={3} className="SP-add-card-new">
                    <div
                      className="SP-body-card"
                      onClick={() => addNewPaitent()}
                    >
                      <img src={AddIcon} alt="" width="18px" />
                      <h5 className="SP-body-card-tlt">Add New Patient</h5>
                    </div>
                  </Col>
                )}

              {patientList.length > 0
                ? patientList.map((patient, index) => {
                    return (
                      <>
                        <Col
                          md={3}
                          className="SP-PD-card"
                          onClick={() => patientSelect(patient, index)}
                          style={
                            patient.patient_account_id == selectedPatientID
                              ? { border: "2px solid #17BAE0" }
                              : { borderColor: "#DDDDDD" }
                          }
                        >
                          <div className="SP-PD-card-body">
                            <p className="SP-PD-card-body-tlt mb-1">
                              Name
                              {patient.patient_account_id ==
                              selectedPatientID ? (
                                <span className="SP-PD-checkIcon">
                                  <img alt="" src={CheckIcon} width="22px" />
                                </span>
                              ) : null}
                            </p>
                            <h6 className="SP-PD-card-body-stlt mb-3 patient_name_in_card">
                              {patient.patient_name
                                ? patient.patient_name
                                : null}
                            </h6>

                            <p className="SP-PD-card-body-tlt mb-1 relationship-text">
                              Relationship{" "}
                            </p>
                            <h6 className="SP-PD-card-body-stlt mb-3">
                              {patient.relationship
                                ? patient.relationship
                                : null}
                            </h6>
                            {patient.formatted_uhid && (
                              <b
                                style={{
                                  fontSize: "13.5px",
                                  overflowWrap: "anywhere",
                                  textTransform: "uppercase",
                                }}
                              >
                                UHID: {patient.formatted_uhid}
                              </b>
                            )}
                          </div>
                        </Col>
                      </>
                    );
                  })
                : null}
            </Row>
          }
        </div>
        {showRegularBookingModal && <NoUserFoundModal />}

        <CantTakeFollowupConfirmationModal
          setShowConfirmationModal={setShowConfirmationModal}
          showConfirmationModal={showConfirmationModal}
        />
        <Outlet />
        <Footer nextIsDisabled={nextDisabled} />
      </div>
    </>
  );
};

export default SelectPatient;
