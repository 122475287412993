import React, { Component } from "react";
import { Link } from "react-router-dom";
import afterLoginApiAxios from "../../Shared/afterLogin";
import Loader from "../../ReusableComponents/Loader/Loader";
import swal from "sweetalert";
import CountdownTimer from "./CountdownTimer";
import Favicon from "../../asstes/images/favicon.svg";
import { DEV, QA, UAT } from "../../Shared/config";
import { withRouter } from "../../Shared/withRouter";
import { getPathNameHelper } from "../../Shared/Helper";
import { STEPPER_ROUTE_DATA_PATIENT } from "../../Shared/constants";
import { setStepper } from "../../features/stepper/StepperSlice";
import c2cLogo from "../../asstes/images/C2C-Logo.png";
import { connect } from "react-redux";

const formValid = (formErrors) => {
  let valid = true;
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });
  return valid;
};

const onlyDigitRegex = RegExp(/^[0-9]+$/);

class ReviewPage extends Component {
  constructor(props) {
    super(props);

    this.logOut = this.logOut.bind(this);
    this.checkout = this.checkout.bind(this);
    this.paymentDone = this.paymentDone.bind(this);
    //this.successApi = this.successApi.bind(this);

    var name = sessionStorage.getItem("patient_name");
    var email = sessionStorage.getItem("patient_email");

    this.state = {
      name: name,
      email: email,
      doctor: this.props.doctorDetails,
      additional_fee: 0,
      order_id: "",
      phone: sessionStorage.getItem("user_name"),
      txnID: "",
      convenience_fee: 0,
      rounded_amount: 0,
      gst_on_convenience_fee: 0,
      showLoader: false,
      modalOpen: false,
    };
  }

  componentDidMount() {
    // disableHelpButton();
    sessionStorage.removeItem("inclinic-online-id");
    sessionStorage.removeItem("inclinic-online");
  }

  componentWillUnmount() {
    // enableHelpButton();
  }
  logOut() {
    afterLoginApiAxios
      .post(
        "auth/user-logout",
        {
          user_token: `${sessionStorage.getItem("id_token")}`,
        },
        {
          headers: {
            Authorization: `${window.sessionStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        sessionStorage.removeItem("id_token");
        sessionStorage.removeItem("user_type");
        sessionStorage.removeItem("user_id");
        sessionStorage.removeItem("user_name");
        sessionStorage.removeItem("full_name");
        this.props.router.navigate("/patientLogin");
      })
      .catch((err) => {
        swal("", "An error occured, logging out now!", "info").then(() => {
          sessionStorage.removeItem("id_token");
          sessionStorage.removeItem("user_type");
          sessionStorage.removeItem("user_id");
          sessionStorage.removeItem("user_name");
          sessionStorage.removeItem("full_name");
          this.props.router.navigate("/patientLogin");
        });
      });
  }

  successApi(paymentID, orderID, signature) {
    let self = this;
    if (paymentID && orderID) {
      afterLoginApiAxios
        .post(
          "/public/verify-payu-hash-response",
          {
            transaction_id: this.state.txnID,
            payment_id: paymentID,
            order_id: orderID,
            signature: signature,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: sessionStorage.getItem("id_token"),
            },
          }
        )
        .then((response) => {
          if (response) {
            var responseData = response.data;
            if (responseData) {
              if (response.data.hasOwnProperty("statusCode")) {
                if (response.data.statusCode == 200) {
                  self.paymentDone();
                  sessionStorage.removeItem("clinicId");
                  sessionStorage.removeItem("clinic_id");
                  this.props.router.navigate("/thankyou");
                }
              } else {
                alert("Error ! Please try again.");
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  paymentDone() {
    if (sessionStorage.getItem("meeting_id") !== null) {
      afterLoginApiAxios({
        method: "post",
        url: "/patient/patient_invitation_action",
        headers: {
          authorization: sessionStorage.getItem("id_token"),
        },
        data: {
          patient_id: sessionStorage.getItem("user_id"),
          event_id: 4,
          instant_consult_key: sessionStorage.getItem("meeting_id"),
        },
      })
        .then((res) => {})
        .catch((err) => console.log(err));
    }
  }

  checkout = () => {
    let self = this;
    let razorpayID = "rzp_live_jTzrEHHIbpY2M3";
    if (
      window.location.hostname === "localhost" ||
      process.env.REACT_APP_ENV === DEV ||
      process.env.REACT_APP_ENV === QA ||
      process.env.REACT_APP_ENV === UAT
    ) {
      razorpayID = "rzp_test_rKJ2mGfSEsNNP7";
    } else {
      razorpayID = ["prod"].includes(process.env.REACT_APP_ENV)
        ? "rzp_live_jTzrEHHIbpY2M3"
        : "rzp_live_qYyiASeyYtOJqb";
    }

    var props = this.props;
    this.setState({
      modalOpen: true,
    });
    let options = {
      key: razorpayID, //,
      amount: Number(this.state.additional_fee) * 100, // 2000 paise = INR 20, amount in paisa
      name: "Connect2Clinic",
      description: "Online Consultation",
      image: Favicon,
      order_id: this.state.order_id,
      handler: (response) => {
        sessionStorage.setItem("paymentID", response.razorpay_payment_id);
        sessionStorage.setItem("orderID", response.razorpay_order_id);
        sessionStorage.setItem("signature", response.razorpay_signature);
        if (response.razorpay_payment_id && response.razorpay_order_id) {
          self.paymentDone();
          sessionStorage.removeItem("clinicId");
          sessionStorage.removeItem("clinic_id");
          this.props.router.navigate("/thankyou");
        } else {
          sessionStorage.removeItem("clinicId");
          sessionStorage.removeItem("clinic_id");
          this.props.router.navigate("/failed");
        }
      },
      prefill: {
        name: this.state.name,
        contact: this.state.phone,
        email: this.state.email,
      },
      theme: {
        color: "#17BAE0",
      },
    };
    let rzp = new window.Razorpay(options);
    rzp.open();

    const modalDocument = document.querySelector("#modal.mchild");

    if (modalDocument) {
      modalDocument.style.top = "60px";
    }
  };

  componentWillMount() {
    let apiData = {};
    if (sessionStorage.getItem("meeting_id") !== null) {
      afterLoginApiAxios({
        method: "post",
        url: "/patient/patient_invitation_action",
        headers: {
          authorization: sessionStorage.getItem("id_token"),
        },
        data: {
          patient_id: sessionStorage.getItem("user_id"),
          event_id: 2,
          instant_consult_key: sessionStorage.getItem("meeting_id"),
        },
      })
        .then((res) => {})
        .catch((err) => console.log(err));
    }
    sessionStorage.removeItem("free");

    if (sessionStorage.getItem("id_token")) {
    } else {
      this.props.router.navigate("/patientLogin");
    }
    sessionStorage.setItem("appointment_slot_locked", true);
    this.setState({
      showLoader: true,
    });

    if (
      sessionStorage.getItem("order_id") &&
      sessionStorage.getItem("amount")
    ) {
      this.setState({
        order_id: sessionStorage.getItem("order_id"),
        additional_fee: sessionStorage.getItem("amount"),
      });
    }
    if (sessionStorage.getItem("meeting_id") !== null) {
      apiData = {
        appointment_id: sessionStorage.getItem("flag_appointment_id"),
        meeting_id: sessionStorage.getItem("meeting_id"),
        consultation_type: 2,
      };
    } else {
      apiData = {
        appointment_id: sessionStorage.getItem("flag_appointment_id"),
      };
    }
    const clinicId = sessionStorage.getItem("clinicId");
    if (clinicId !== "" && clinicId !== undefined && clinicId !== null) {
      apiData["clinic_id"] = clinicId;
    }

    afterLoginApiAxios({
      method: "post",
      url:
        clinicId !== "" && clinicId !== undefined && clinicId !== null
          ? "/clinic/get-calculated-hash_rev1"
          : "/public/get-calculated-hash_rev1",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("id_token"),
      },
      data: apiData,
    })
      .then((response) => {
        if (response) {
          var responseData = response.data;
          if (responseData) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                this.setState({
                  order_id: responseData.RequestData.order_id,
                  additional_fee: responseData.RequestData.amount,
                  txnID: responseData.RequestData.txnid,
                  convenience_fee: responseData.RequestData.convenience_fee,
                  rounded_amount: responseData.RequestData.rounded_amount,
                  gst_on_convenience_fee:
                    responseData.RequestData.gst_on_convenience_fee,
                  showLoader: false,
                });
                sessionStorage.setItem("txnID", responseData.RequestData.txnid);
                sessionStorage.setItem(
                  "order_id",
                  responseData.RequestData.order_id
                );
                sessionStorage.setItem(
                  "amount",
                  responseData.RequestData.amount
                );
                sessionStorage.setItem(
                  "convenience_fee",
                  responseData.RequestData.convenience_fee
                );
                sessionStorage.setItem(
                  "rounded_amount",
                  responseData.RequestData.rounded_amount
                );
              } else if (response.data.statusCode == 599) {
                sessionStorage.setItem("free", "free booking");
                sessionStorage.removeItem("clinicId");
                sessionStorage.removeItem("clinic_id");
                this.props.router.navigate("/thankyou");
              } else {
                let gaLabel =
                  response.data.statusCode +
                  ": " +
                  sessionStorage.getItem("user_id");

                sessionStorage.removeItem("clinicId");
                sessionStorage.removeItem("clinic_id");
                this.props.router.navigate("/failed");
              }
            } else {
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  regularBookingUrl =
    STEPPER_ROUTE_DATA_PATIENT[this.props.redux.appointmentType][1];
  followUpBookingUrl =
    STEPPER_ROUTE_DATA_PATIENT[this.props.redux.appointmentType][1];

  render() {
    return (
      <div className="login-container">
        <div className="widgetModalDiv widgetModalBg">
          <div
            style={{
              width: window.innerWidth > 768 ? "75%" : "100%",
              backgroundColor: "#fff",
            }}
          >
            {this.state.showLoader ? <Loader /> : null}
            <div>
              <CountdownTimer />
            </div>
            <div
              className="row"
              style={{ marginTop: "2vh", overflowY: "hidden" }}
            >
              <div className="col-sm-12 col-lg-12 text-center">
                <h2>
                  <b>Payment</b>
                </h2>
              </div>
              <div className="col-sm-12 col-lg-4 offset-lg-4 text-center loginHeader pb-4">
                <span className="patient_name_in_card">
                  Patient Name : <b>{this.state.name}</b>
                </span>
                <br />
                <span>
                  Patient Email : <b>{this.state.email}</b>
                </span>
                <br />
                <br />
                <br />
                <span>
                  Dr. {this.state.doctor.first_name}
                  {"'s"} consultation fees
                </span>
                <span>
                  <b>&nbsp;&nbsp;Rs. {this.state.additional_fee}</b>
                </span>
                <br />
                <br />
                <br />
                <span>Total</span>
                <span>
                  <b>&nbsp;&nbsp;Rs. {this.state.additional_fee}</b>
                </span>
                <br />
                <br />
                <Link
                  className="proceed_to_pay_btn btn text-center"
                  onClick={this.checkout}
                  style={{
                    backgroundColor: "#17BAE0",
                    marginRight: window.innerWidth > 768 ? "" : "20px",
                  }}
                >
                  Proceed to pay
                </Link>
                <Link
                  className=" btn text-center ml-3"
                  to={
                    sessionStorage.getItem("is_followup") === "1"
                      ? this.followUpBookingUrl
                      : this.regularBookingUrl
                  }
                  onClick={() =>
                    this.props.redux.dispatch(
                      setStepper(this.props.redux.currentStepper - 2)
                    )
                  }
                  style={{ backgroundColor: "#D6DAE5" }}
                >
                  Back
                </Link>
              </div>
            </div>
          </div>
          <div className="WidgetModalFooterLogo">
            Powered By <img alt="" src={c2cLogo} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    doctorDetails: state.auth.doctorDetails,
  };
};

export default withRouter(connect(mapStateToProps)(ReviewPage));
