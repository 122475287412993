import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import beforeLoginAxios from "../../../Shared/beforeLogin";
import swal from "sweetalert";
import Spinner from "react-bootstrap/Spinner";
import C2CLogo from "../../../asstes/images/C2C-Logo.png";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import "./login.scss";
import { setPhoneNumber } from "../../../features/setNumber/phoneNumberSlice";
const Login = ({ showModal, setShowModal }) => {
  const phoneNumber = useSelector((state) => state?.selectNumber?.number);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [continueAllowed, setContinueAllowed] = useState(
    phoneNumber?.length == 10
  );

  const handleContinueClick = (e) => {
    e.preventDefault();
    setLoading(true);

    beforeLoginAxios
      .post("public/send-otp", {
        user_type: "patient",
        channel_id: "patient-login",
        phone_no: `+91${phoneNumber}`,
        login_type: "password",
      })
      .then((response) => {
        if (response) {
          setLoading(false);
          if (response.data && response.data.statusCode === 200) {
            if (response.data.password_set === 1) {
              setShowModal("enter-password");
            } else {
              setShowModal("enter-otp");
            }
          } else if (response.data && response.data.statusCode === 500) {
          } else if (response.data && response.data.statusCode === 501) {
            swal({
              title: "Invalid Phone Number Entered",
              type: "info",
            });
          } else {
            swal({
              title: "Something Went Wrong!",
              type: "info",
            });
          }
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  function handlePhoneNumber(num) {
    if (num == "") {
      dispatch(setPhoneNumber(num));
    }
    if (num.length < 10) setContinueAllowed(false);
    if (num.length == 10) setContinueAllowed(true);
    if (num.length > 10) return;
    const onlyDigitRegex = RegExp(/^\d+$/);
    if (onlyDigitRegex.test(num)) {
      dispatch(setPhoneNumber(num));
    }
    onlyDigitRegex.lastIndex = 0;
  }
  return (
    <div className="login-container">
      <div className="widgetModalDiv widgetModalBg">
        <div className="container WidgetModalContentDiv">
          <span className="login-container-title">Log In</span>
          <span className="login-container-subtitle">
            Log in to continue with booking appointment
          </span>
          <div className="w-100 d-flex align-items-center justify-content-center flex-column">
            <form
              className="d-flex flex-column align-items-center justify-content-center"
              onSubmit={(e) => {
                handleContinueClick(e);
              }}
            >
              <FormControl
                hiddenLabel="true"
                size="small"
                margin="dense"
                fullWidth="true"
              >
                <FilledInput
                  style={{
                    backgroundColor: "#F5F6F7",
                  }}
                  className="shadow-sm"
                  placeholder="Enter Phone Number"
                  id="OTP-input"
                  aria-describedby="my-helper-text"
                  aria-label="otp-input"
                  autoFocus={true}
                  startAdornment={<Plus91 />}
                  disableUnderline="true"
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={(e) => {
                    handlePhoneNumber(e.target.value);
                  }}
                  value={phoneNumber}
                />
              </FormControl>
              <span
                className="login-container-links"
                style={{ textAlign: "center" }}
              >
                By clicking on continue, you agree to our{" "}
                <a
                  className="textlink"
                  style={{
                    color: "#17bae0",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                  href="/privacy.html"
                  target="_blank"
                >
                  Privacy Policy
                </a>{" "}
                &{" "}
                <a
                  className="textlink"
                  style={{
                    color: "#17bae0",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                  href="/terms.html"
                  target="_blank"
                >
                  Terms of Use
                </a>
              </span>
              <button
                className="btn primary-cta-button"
                style={{
                  paddingTop: window.innerWidth < 768 && loading ? "9px" : "",
                  paddingBottom:
                    window.innerWidth < 768 && loading ? "9px" : "",
                }}
                disabled={!continueAllowed}
              >
                {loading ? <Spinner animation="border" /> : "Continue"}
              </button>
            </form>
          </div>
        </div>

        <div className="WidgetModalFooterLogo">
          Powered By <img alt="" src={C2CLogo} />
        </div>
      </div>
    </div>
  );
};

const Plus91 = () => <div className="adornment-beautify">+91</div>;
export default Login;
