import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAppointmentType, setStepper } from "../../features/stepper/StepperSlice";
import { setClinicId, setDoctorSlug } from "../../features/auth/authSlice";
import { STEPPER_ROUTE_DATA_PATIENT } from "../../Shared/constants";

const Controller = () => {
  const [searchParams] = useSearchParams();
  const { bookingType, type, isVirtual } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appointmentType = useSelector((state) => state.stepper.appointmentType);
  const doctorSlug = searchParams.get("doctor_slug");
  const clinicId = searchParams.get("clinic_id");

  useEffect(() => {
    dispatch(setStepper(1));
    dispatch(setClinicId(clinicId));
    dispatch(setDoctorSlug(doctorSlug));

    if (clinicId) {
      //  localStorage.setItem("clinicId", clinicId);
      sessionStorage.setItem("clinicId", clinicId);
    }

    if (type === "patient" && bookingType === "clinic") {
      if (isVirtual === "isVirtual") {
        dispatch(setAppointmentType(2));
        navigate("/patient/clinic/isVirtual/selectDateTime");
      } else {
        dispatch(setAppointmentType(1));
        navigate("/patient/clinic/isClinic/selectDoctor");
      }
    } else if (type === "patient" && bookingType === "doctor") {
      if (isVirtual === "isVirtual") {
        dispatch(setAppointmentType(4));
        navigate("/patient/doctor/isVirtual/selectDateTime");
      } else {
        dispatch(setAppointmentType(3));
        navigate("/patient/doctor/isClinic/selectDateTime");
      }
    }
  }, []);

  useEffect(() => {
    window.onpopstate = function () {
      const obj = STEPPER_ROUTE_DATA_PATIENT[appointmentType];
      const LastParmsSender = (props) => {
        return props ?? props.split("/")[props.split("/").length - 1];
      };
      for (const key in obj) {
        if (LastParmsSender(obj[key]) === LastParmsSender(window.location.pathname)) {
          dispatch(setStepper(Number(key)));
        }
      }
    };
  }, []);

  return <>Loading...</>;
};

export default Controller;
