import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import CachedIcon from "@material-ui/icons/Cached";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useLocation } from "react-router-dom";
import "./styles.css";
import "./login.scss";
//import { useTimer } from "../../Hooks/useTimer";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import beforeLoginAxios from "../../../Shared/beforeLogin";
import ErrorIcon from "@material-ui/icons/Error";
import ReactGA from "react-ga";
import afterLoginApiAxios from "../../../Shared/beforeLogin";
import { useSelector, useDispatch } from "react-redux";
import { setAuthToken } from "../../../features/auth/authSlice";
import C2CLogo from "../../../asstes/images/C2C-Logo.png";

function ResetPassword({ showModal, setShowModal }) {
  const phoneNumber = useSelector((state) => state.selectNumber.number);

  const dispatch = useDispatch();
  let location = useLocation();
  const [time, setTime] = useState(60);
  const [showPassword, setShowPassword] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [resendAllowed, setResendAllowed] = useState(false);
  const [otpApproved, setOtpApproved] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  useEffect(() => {
    swal({
      title:
        "An OTP has been sent to your registered Phone number and Email Address ",
      type: "warning",
    });
  }, []);

  const handleOTPInput = (input) => {
    setOtpApproved(false);
    setOtpInput(input);
  };

  function start() {
    const interval = setTimeout(() => {
      if (time > 0) {
        setTime(time - 1);
      }

      if (time === 0) {
        setTime("");
        setResendAllowed(true);
        clearTimeout(interval);
      }
    }, 1000);

    return () => {
      clearTimeout(interval);
    };
  }

  useEffect(() => {
    start();
  }, [time]);

  function handleForgotPassword() {
    if (!resendAllowed) return;
    setTime(60);
    start();

    setResendAllowed(false);
    beforeLoginAxios
      .post("public/send-otp", {
        user_type: "patient",
        channel_id: "patient-login",
        phone_no: `+91${phoneNumber}`,
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            swal({
              title:
                "An OTP has been sent to your registered Phone number and Email Address ",
              type: "warning",
            });
          }
        }
      });
  }

  let getBrowserName = () => {
    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== "undefined";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 79
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

    // Blink engine detection
    var isBlink = isChrome && !!window.CSS;

    if (isFirefox) return "FireFox";
    if (isSafari) return "Safari";
    if (isIE && !isEdge) return "IE";
    if (isEdge) return "Edge";
    if (isChrome) return "Chrome";
    if (isEdgeChromium) return "EdgeChromium";
    if (isBlink) return "Blink-based";
  };

  let registerAnalytics = () => {
    afterLoginApiAxios
      .post(
        "user/engagement-analytics-data",
        {
          usecase: "login",
          user_type: "2",
          device_details: {
            device_type: "desktop",
            device_manufacturer: "",
            os_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            os_name: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            application_type: "web",
            application_version: "1.0",
            browser_name: getBrowserName(),
            browser_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.lastIndexOf(")") + 1
            ),
          },
          patient_login_data: {
            patient_id: sessionStorage.getItem("user_id"),
          },
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("id_token"),
          },
        }
      )
      .then(() => {})
      .catch((err) => console.error(err));
  };

  const handleContinueClick = (e) => {
    e.preventDefault();
    setPasswordError(false);
    if (otpInput.length == 0) return;
    if (otpInput.length !== 6) {
      setOtpError("OTP must be exactly 6 characters");
      return;
    }
    setOtpError(false);
    if (passwordInput.length <= 5) {
      setPasswordError("Password must be at least 6 characters");
      return;
    }
    setPasswordError(false);
    setOtpLoading(true);
    beforeLoginAxios
      .post("auth/login", {
        channel_id: "patient-login",
        phone_no: `+91${phoneNumber}`,
        user_type: "patient",
        user_otp: otpInput,
      })
      .then((response) => {
        if (response) {
          setOtpLoading(false);
          if (response.data.hasOwnProperty("statusCode")) {
            if (response.data.statusCode == 200) {
              sessionStorage.setItem("id_token", response.data.user.id_token);
              sessionStorage.setItem("user_name", response.data.user.user_name);
              sessionStorage.setItem("user_type", response.data.user.user_type);
              sessionStorage.setItem("user_id", response.data.user.user_id);
              sessionStorage.setItem(
                "last_login_date",
                response.data.user.last_login_date
              );
              if (!response.data.user.last_login_date) {
                sessionStorage.setItem("last_login_date", "");
                sessionStorage.setItem("Home", true);
                sessionStorage.setItem("Appointment History", true);
              }
              ReactGA.event({
                category: "login",
                action: "patient_login",
                label: response.data.user.user_id,
              });
              registerAnalytics();
              setOtpApproved(true);
              afterLoginApiAxios
                .post("patient/set-password", {
                  id_token: response.data.user.id_token,
                  new_password: passwordInput,
                  headers: {
                    Authorization: sessionStorage.getItem("id_token"),
                  },
                })
                .then((resInner) => {
                  if (resInner) {
                    if (resInner.data && resInner.data.statusCode === 200) {
                      if (
                        location &&
                        location.state &&
                        location.state.redirectTo == false
                      ) {
                        return;
                      }
                      dispatch(setAuthToken(response.data.user.id_token));
                    } else {
                      setPasswordError("Something Went Wrong!");
                    }
                  }
                });
            } else if (response.data.statusCode == 513) {
              setOtpError("OTP you entered was incorrect");
            } else {
              setOtpError(response.data.message);
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getOTPAdornment = () => {
    if (otpLoading)
      return <CachedIcon className="rotating" style={{ color: "#4AC9E7" }} />;
    if (otpApproved) return <CheckCircleIcon style={{ color: "#10A74A" }} />;
    if (otpError) return <ErrorIcon style={{ color: "#e84444" }} />;
    return null;
  };

  if (phoneNumber == "") {
    // phoneNumber = "1234123412";
    return setShowModal("");
  }

  return (
    <div className="login-container">
      <div className="widgetModalDiv widgetModalBg">
        <div className="WidgetModalContentDiv">
          <div className="w-100 d-flex align-items-center justify-content-center">
            <ArrowBackIcon
              onClick={() => {
                setShowModal("login");
              }}
              style={{
                position: "relative",
                right: window.innerWidth > 768 ? "70px" : "36px",
              }}
              className="color-blue cursor-pointer"
            />
            <span className="login-container-title">
              {location && location.state && location.state.title
                ? location.state.title
                : "Verify Number"}
            </span>
          </div>
          <span className="login-container-subtitle">Please Enter the OTP</span>
          <div
            className={
              "w-100 d-flex flex-row justify-content-between " +
              (window.innerWidth > 768 ? "mt-3" : "")
            }
          >
            <div className="d-inline-block login-muted-text">
              +91-{phoneNumber}
            </div>
            <Edit setShowModal={setShowModal} />
          </div>
          <div
            className={"w-100 " + (window.innerWidth > 768 ? "mt-2" : "mt-3")}
          >
            <form
              className="d-flex flex-column align-items-center justify-content-center"
              onSubmit={(e) => {
                handleContinueClick(e);
              }}
            >
              <FormControl
                hiddenLabel="true"
                size="small"
                margin="dense"
                fullWidth="true"
              >
                <FilledInput
                  id="OTP-input"
                  aria-describedby="my-helper-text"
                  required
                  aria-required
                  placeholder="Enter OTP"
                  aria-label="otp-input"
                  autoFocus={true}
                  inputProps={{
                    autoComplete: "off",
                    inputMode: "numeric",
                  }}
                  disableUnderline="true"
                  value={otpInput}
                  endAdornment={getOTPAdornment()}
                  onChange={(e) => {
                    handleOTPInput(e.target.value);
                  }}
                />
              </FormControl>
              <div
                className="row justify-content-between w-100"
                style={{ marginTop: window.innerWidth > 768 ? "" : "10px" }}
              >
                <div className="col-8 p-0">
                  {resendAllowed ? (
                    <div
                      className="align-self-start login-muted-text"
                      style={{
                        textAlign: window.innerWidth > 768 ? "" : "left",
                      }}
                    >
                      OTP expired
                    </div>
                  ) : (
                    <div
                      className="align-self-start login-muted-text"
                      style={{
                        textAlign: window.innerWidth > 768 ? "" : "left",
                      }}
                    >
                      {time} seconds left to expire
                    </div>
                  )}
                </div>
                <div className="col-4 p-0 d-flex justify-content-end align-items-start">
                  <div
                    className={`cursor-pointer login-primary-text ${
                      !resendAllowed
                        ? "login-muted-text pointer-disabled"
                        : "textLink"
                    }`}
                    style={{
                      textAlign: "right",
                      marginBottom: window.innerWidth > 768 ? "" : "20px",
                      width: window.innerWidth > 768 ? "" : "auto",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleForgotPassword();
                    }}
                  >
                    Resend OTP
                  </div>
                </div>
              </div>
              <div
                className={
                  "input-error text-center " +
                  (window.innerWidth > 768 ? "mt-1" : "")
                }
              >
                {otpError ? otpError : ""}
              </div>
              <div
                className={
                  "text-center " +
                  (window.innerWidth > 768
                    ? "mt-3"
                    : "login-container-subtitle")
                }
                style={{ marginBottom: window.innerWidth > 768 ? "" : "10px" }}
              >
                {location && location.state && location.state.resetTitle
                  ? location.state.resetTitle
                  : "Set password for easier access (minimum 6 characters required)"}
              </div>
              <FormControl
                hiddenLabel={true}
                size="small"
                margin="dense"
                fullWidth={true}
              >
                <FilledInput
                  id="pwd-input"
                  aria-describedby="my-helper-text"
                  aria-label="Set-password"
                  placeholder="Enter Password"
                  disableUnderline={true}
                  required
                  aria-required
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <ShowPassword
                      setShowPassword={setShowPassword}
                      showPassword={showPassword}
                    />
                  }
                  value={passwordInput}
                  onChange={(e) => {
                    setPasswordInput(e.target.value);
                  }}
                />
              </FormControl>
              <div
                className={
                  "input-error text-center " +
                  (window.innerWidth > 768 ? "mt-1" : "")
                }
              >
                {passwordError ? passwordError : ""}
              </div>
              <button
                className="btn primary-cta-button"
                disabled={resendAllowed}
                style={{ marginTop: window.innerWidth > 768 ? "" : "5px" }}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="WidgetModalFooterLogo">
        Powered By <img alt="" src={C2CLogo} />
      </div>
    </div>
  );
}

export default ResetPassword;

const Edit = ({ setShowModal }) => {
  return (
    <span
      className="login-primary-text cursor-pointer"
      onClick={() => {
        setShowModal("login");
      }}
    >
      Edit
      <EditIcon className="color-blue cursor-pointer" fontSize="inherit" />
    </span>
  );
};

function ShowPassword(props) {
  const { setShowPassword, showPassword = false } = props;

  if (showPassword === false) {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityIcon />
      </span>
    );
  } else {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityOffIcon />
      </span>
    );
  }
}
