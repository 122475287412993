import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import ErrorIcon from "@material-ui/icons/Error";
//import moment from "moment-timezone";
import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import "./styles.css";
import "./login.scss";
import afterLoginApiAxios from "../../../Shared/afterLogin";
import beforeLoginAxios from "../../../Shared/beforeLogin";

import { useSelector, useDispatch } from "react-redux";
import C2CLogo from "../../../asstes/images/C2C-Logo.png";
import { setAuthToken } from "../../../features/auth/authSlice";

function OtpLoginFullDoctor({ showModal, setShowModal }) {
  const phoneNumber = useSelector((state) => state.selectNumber.number);
  const dispatch = useDispatch();

  let location = useLocation();
  const [time, setTime] = useState(60);
  const [otpInput, setOtpInput] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [resendAllowed, setResendAllowed] = useState(false);
  const [sendOTP, setSendOTP] = useState(0);
  const [stayLogin, setStayLogin] = useState(false);
  // const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);

  useEffect(() => {
    swal({
      title:
        "An OTP has been sent to your registered Phone number and Email Address ",
      type: "warning",
    });
    beforeLoginAxios.post("public/send-otp", {
      user_type: "patient",
      channel_id: "patient-login",
      phone_no: `+91${phoneNumber}`,
    });
  }, [sendOTP]);

  function start() {
    const interval = setTimeout(() => {
      if (time > 0) {
        setTime(time - 1);
      }

      if (time === 0) {
        setTime("");
        setResendAllowed(true);
        clearTimeout(interval);
      }
    }, 1000);

    return () => {
      clearTimeout(interval);
    };
  }

  useEffect(() => {
    start();
  }, [time]);

  if (phoneNumber == "") {
    return setShowModal("");
  }

  let getBrowserName = () => {
    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== "undefined";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 79
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

    // Blink engine detection
    var isBlink = isChrome && !!window.CSS;

    if (isFirefox) return "FireFox";
    if (isSafari) return "Safari";
    if (isIE && !isEdge) return "IE";
    if (isEdge) return "Edge";
    if (isChrome) return "Chrome";
    if (isEdgeChromium) return "EdgeChromium";
    if (isBlink) return "Blink-based";
  };

  let registerAnalytics = () => {
    afterLoginApiAxios
      .post(
        "user/engagement-analytics-data",
        {
          usecase: "login",
          user_type: "2",
          device_details: {
            device_type: "desktop",
            device_manufacturer: "",
            os_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            os_name: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            application_type: "web",
            application_version: "1.0",
            browser_name: getBrowserName(),
            browser_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.lastIndexOf(")") + 1
            ),
          },
          patient_login_data: {
            patient_id: sessionStorage.getItem("user_id"),
          },
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("id_token"),
          },
        }
      )
      .then(() => {})
      .catch((err) => console.error(err));
  };

  function handleForgotPassword() {
    if (!resendAllowed) return;
    setTime(60);
    start();
    setResendAllowed(false);
    setSendOTP(sendOTP + 1);
  }

  const handleContinueClick = (e) => {
    e.preventDefault();
    if (otpInput.length !== 6) {
      setOtpError("OTP must be exactly 6 characters");
      return;
    }

    beforeLoginAxios
      .post("auth/login-rev2", {
        channel_id: "patient-login",
        phone_no: `+91${phoneNumber}`,
        user_type: "patient",
        user_otp: otpInput,
        stay_login: stayLogin,
        source: "web", // add new parameter
      })
      .then((response) => {
        if (response) {
          if (response.data.hasOwnProperty("statusCode")) {
            if (response.data.statusCode == 200) {
              sessionStorage.setItem("id_token", response.data.user.id_token);
              sessionStorage.setItem("user_name", response.data.user.user_name);
              sessionStorage.setItem("user_type", response.data.user.user_type);
              sessionStorage.setItem("user_id", response.data.user.user_id);
              sessionStorage.setItem(
                "last_login_date",
                response.data.user.last_login_date
              );
              if (!response.data.user.last_login_date) {
                sessionStorage.setItem("last_login_date", "");
                sessionStorage.setItem("Home", true);
                sessionStorage.setItem("Appointment History", true);
              }
              ReactGA.event({
                category: "login",
                action: "patient_login",
                label: response.data.user.user_id,
              });

              registerAnalytics();
              dispatch(setAuthToken(response.data.user.id_token));
              if (
                location &&
                location.state &&
                location.state.redirectTo == false
              ) {
                return;
              }
            } else if (response.data.statusCode == 513) {
              setOtpError("OTP you entered was incorrect");
            } else {
              setOtpError("Something went wrong");
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // }
  };

  return (
    <div className="login-container">
      <div className="widgetModalDiv widgetModalBg">
        <div className="WidgetModalContentDiv">
          <div className="w-100 d-flex align-items-center justify-content-center">
            <ArrowBackIcon
              onClick={() => {
                setShowModal("login");
              }}
              style={{ position: "relative", right: "70px" }}
              className="color-blue cursor-pointer"
            />
            <span className="login-container-title">Enter OTP</span>
          </div>
          <span className="login-container-subtitle">
            Please Enter the OTP to login
          </span>
          <div
            className={
              "w-100 d-flex flex-row justify-content-between " +
              (window.innerWidth > 768 ? "mt-3" : "")
            }
          >
            <div className="d-inline-block login-muted-text">
              +91-{phoneNumber}
            </div>
            <Edit setShowModal={setShowModal} />
          </div>
          <div
            className={"w-100 " + (window.innerWidth > 768 ? "mt-2" : "mt-3")}
          >
            <form
              className="d-flex flex-column align-items-center justify-content-center"
              onSubmit={(e) => {
                handleContinueClick(e);
              }}
            >
              <FormControl
                hiddenLabel={true}
                size="small"
                margin="dense"
                fullWidth={true}
              >
                <FilledInput
                  id="OTP-input"
                  aria-describedby="my-helper-text"
                  aria-label="otp-input"
                  autoFocus={true}
                  placeholder="Enter OTP"
                  disableUnderline={true}
                  value={otpInput}
                  inputProps={{
                    autoComplete: "off",
                  }}
                  endAdornment={
                    otpError ? <ErrorIcon style={{ color: "#e84444" }} /> : null
                  }
                  onChange={(e) => {
                    setOtpInput(e.target.value);
                  }}
                />
              </FormControl>
              <div className="row justify-content-between w-100">
                <div className="d-flex stay-login justify-content-end">
                  {resendAllowed != "false" ? (
                    <div
                      className="col-8 align-self-start login-muted-text start-item"
                      style={{
                        textAlign: window.innerWidth > 768 ? "" : "left",
                      }}
                    >
                      {" "}
                      {time} seconds left to expire
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-4 p-0 d-flex justify-content-end align-items-start">
                    <div
                      className={`align-self-start cursor-pointer login-primary-text ${
                        !resendAllowed
                          ? "login-muted-text pointer-disabled"
                          : "textLink"
                      }`}
                      style={{
                        textAlign: "right",
                        marginBottom: window.innerWidth > 768 ? "" : "30px",
                        width: window.innerWidth > 768 ? "" : "auto",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleForgotPassword();
                      }}
                    >
                      Resend OTP
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "input-error text-center " +
                  (window.innerWidth > 768 ? "mt-1" : "")
                }
              >
                {otpError ? otpError : ""}
              </div>
              <button
                className="btn primary-cta-button"
                disabled={resendAllowed}
              >
                Verify OTP
              </button>
            </form>
          </div>
          <div className="separator w-50 mt-3 align-self-center">OR</div>
          <div
            className="mt-3 textLink font-weight-bold align-self-center"
            style={{ minWidth: "fit-content" }}
          >
            <div
              onClick={() => {
                setShowModal("enter-password");
              }}
            >
              Login with Password
            </div>
          </div>
        </div>

        <div className="WidgetModalFooterLogo">
          Powered By <img alt="" src={C2CLogo} />
        </div>
      </div>
    </div>
  );
}

export default OtpLoginFullDoctor;

const Edit = ({ setShowModal }) => {
  return (
    <span
      className="login-primary-text cursor-pointer"
      onClick={() => {
        setShowModal("login");
      }}
    >
      Edit
      <EditIcon className="color-blue cursor-pointer" fontSize="inherit" />
    </span>
  );
};
