import React, { useState, useEffect } from "react";
import "./selectDateAndTime.scss";
import Footer from "../Footer";
import { Outlet } from "react-router-dom";
import DateAndClinicDropdown from "./DateAndClinicDropdown";
import { useDispatch, useSelector } from "react-redux";
import { setSpecialization } from "../../features/user/userSlice";

export const SelectDateAndTime = () => {
  const [nextDisabled, setNextDisabled] = useState(true);
  const [sloatNumber, setSloatNumber] = useState("");
  const dispatch = useDispatch();
  const [doctor, setDoctor] = useState();
  useEffect(() => {
    setNextDisabled(sloatNumber ? false : true);
  }, [sloatNumber]);

  //reset the specialization for select doctor
  function backFunctionSelectDoctor(appointmentType, stepperValue) {
    if (appointmentType === 1 && stepperValue === 2) {
      dispatch(
        setSpecialization({ specilizations_id: null, specializations: null })
      );
    }
  }

  return (
    <div>
      <DateAndClinicDropdown
        doctor={doctor}
        setDoctor={setDoctor}
        setSloatNumber={setSloatNumber}
      />
      <Footer
        nextIsDisabled={nextDisabled}
        backFunction={backFunctionSelectDoctor}
      />
      <Outlet />
    </div>
  );
};
