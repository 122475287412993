import React from "react";
import { Route, Routes } from "react-router-dom";
import Controller from "../Components/Controller/Controller";
import PatientDetails from "../Components/EnterDetailsAndConfirm/EnterDetailsAndConfirmNew";
import Login from "../Components/Login/Login";
import ReviewPage from "../Components/ReviewPage/ReviewPage";
import { SelectDateAndTime } from "../Components/SelectDateAndTime";
import SelectDoctor from "../Components/SelectDoctor/SelectDoctor";
import SelectPatient from "../Components/SelectPatient";
import Thankyou from "../Components/ThankyouPage/ThankyouPage";
import BookAppointmentModel from "../ReusableComponents/BookAppointmentModel";
import PrivateRoute from "./PrivateRoute";

const RouteComponent = () => {
  return (
    <>
      <BookAppointmentModel>
        <Routes>
          <Route path="/" element={<Controller />} />
          <Route path="/selectDoctor" element={<SelectDoctor />} />
          <Route path="/selectDateTime" element={<SelectDateAndTime />} />
          <Route element={<PrivateRoute />}>
            <Route path="/selectPatient" element={<SelectPatient />} />
            <Route path="/enterDetails" element={<PatientDetails />} />
          </Route>
          {/* <Route path="/patientLogin" element={<Login />} /> */}
          {/* <Route path="/reviewpage" element={<ReviewPage/>}/> */}
          {/* <Route path="/thankyou" element={<Thankyou/>} /> */}
        </Routes>
      </BookAppointmentModel>
    </>
  );
};

export default RouteComponent;
