import { createSlice } from "@reduxjs/toolkit";

export const selectPatientSlice = createSlice({
  name: "selectPatient",
  initialState: {
    selectedPatientID: null
  },
  reducers: {
    setPatientID: (state, action) => {
      state.selectedPatientID= action.payload;
    },
  },
});

export const { setPatientID } = selectPatientSlice.actions;

export default selectPatientSlice.reducer;
