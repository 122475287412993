import React, { Component } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";

import { connect } from "react-redux";
import afterLoginApiAxios from "../../Shared/afterLogin";
import c2cLogo from "../../asstes/images/C2C-Logo.png";

class Failed extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      token: "",
      txnid: sessionStorage.getItem("txnID"),
      message: "",
      failure_msg: "",
    };

    this.logOut = this.logOut.bind(this);
  }
  componentWillMount() {
    var token = sessionStorage.getItem("id_token");
    var transaction_id = sessionStorage.getItem("bolt_txnid");
    var failure_msg = sessionStorage.getItem("failure_msg");
    this.setState(
      {
        token: token,
        failure_msg: failure_msg,
        //txnid:txnid
      },
      () => {}
    );
  }

  logOut() {
    afterLoginApiAxios
      .post(
        "auth/user-logout",
        {
          user_token: `${sessionStorage.getItem("id_token")}`,
        },
        {
          headers: {
            Authorization: `${window.sessionStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        sessionStorage.removeItem("id_token");
        sessionStorage.removeItem("user_type");
        sessionStorage.removeItem("user_id");
        sessionStorage.removeItem("user_name");
        sessionStorage.removeItem("full_name");
        sessionStorage.removeItem("meeting_id");
        this.props.history.push("/patient/login");
      })
      .catch((err) => {
        console.log("error found");
        console.log(err);
        swal("", "An error occured, logging out now!", "info").then(() => {
          sessionStorage.removeItem("id_token");
          sessionStorage.removeItem("user_type");
          sessionStorage.removeItem("user_id");
          sessionStorage.removeItem("user_name");
          sessionStorage.removeItem("full_name");
          this.props.history.push("/patient/login");
        });
      });
  }

  componentDidMount() {}

  render() {
    return (
      <div className="login-container">
        <div className="widgetModalDiv widgetModalBg">
          <section
            style={{
              width: window.innerWidth > 768 ? "60%" : "100%",
            }}
          >
            <div
              className="bg-white shadow-md p-5 text-center mt-5"
              style={{ borderRadius: 15 }}
            >
              <h2 style={{ fontSize: 22 }} className="mb-4">
                Failed to book an appointment
              </h2>
              {this.state.failure_msg ? (
                <p style={{ color: "#404760" }}>
                  {this.state.failure_msg} <br />
                  <br />
                  Sorry for the inconvenience caused. For refund related queries
                  please contact support team at{" "}
                  <b style={{ color: "#F969A4" }}>
                    {this.props.mobileNumber
                      ? this.props.mobileNumber
                      : "1800 267 9899"}
                  </b>
                </p>
              ) : (
                <p style={{ color: "#404760" }}>
                  Your payment process failed. Please try again.
                </p>
              )}
            </div>
          </section>
          <div className="WidgetModalFooterLogo">
            Powered By <img alt="" src={c2cLogo} />
          </div>
        </div>
      </div>
    );
  }
}

export default Failed;
