import { createSlice } from "@reduxjs/toolkit";

export const phoneNumber = createSlice({
  name: "selectPatient",
  initialState: {
    number: "",
  },
  reducers: {
    setPhoneNumber: (state, action) => {
      state.number = action.payload;
    },
  },
});

export const { setPhoneNumber } = phoneNumber.actions;

export default phoneNumber.reducer;
