import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { STEPPER_ROUTE_DATA_PATIENT } from "../../Shared/constants";
import { useDispatch, useSelector } from "react-redux";
import { setAppointmentType, setStepper } from "../../features/stepper/StepperSlice";
import { IsTeleConsult } from "../../features/setIsTeleConsult/setIsTeleConsultSlice";
import RedCross from "../../asstes/images/red_close.png";
export const NoUserFoundModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentStepper, appointmentType } = useSelector((state) => state.isTeleConsultSlice);

  const handleSendBackToRegularFollow = () => {
    dispatch(IsTeleConsult(false));
    dispatch(setAppointmentType(appointmentType));
    dispatch(setStepper(currentStepper));
    navigate(STEPPER_ROUTE_DATA_PATIENT[appointmentType][currentStepper]);
  };

  return (
    <>
      <Modal size="lg" dialogClassName="modal-width" centered show aria-labelledby="contained-modal-title-vcenter">
        <Modal.Body>
          <div className="text-center mt-3">
          <img src={RedCross} className="times-circle-icon mb-3" alt=""/>
            <h3 className="text-center mt-3">
              <b>Follow up is not allowed since you haven’t done Regular Consultation.</b>
            </h3>
            <p className="mb-4">Kindly proceed with Regular Consultation.</p>
            <button onClick={handleSendBackToRegularFollow} className="footer_save_cont_btn-for-tele-consult mb-4">
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
