import React, { useState } from "react";

import classes from "./DoctorProfile.module.css";

import Popover from "react-popover";

const Slot = ({
  startTime,
  endTime,
  selected,
  type,
  slot,
  reschedule,
  className,
  ...props
}) => {
  const slotStyle =
    type == "modal" ? classes.modalSlotWidth : classes.slotWidth;
  const style = selected
    ? classes.timeBox + " " + classes.selected + " " + slotStyle
    : classes.timeBox + " " + slotStyle;
  const walkInStyle =
    classes.walk_in_slot +
    " " +
    (selected ? classes.walk_in_slot_selected : "");

  const user_type = sessionStorage.getItem("user_type");

  const [isOpen, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Actions ==>

  const handlePopoverOpen = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  // <===

  return (
    <button
      className={`available-slots ${className || style}`}
      {...props}
      style={{ borderRadius: "6px" }}
    >
      {slot?.is_walkin &&
      (user_type === "doctor" || user_type === "staff") &&
      window.location.pathname.includes("/dashboard") &&
      !reschedule ? (
        <>
          <Popover
            isOpen={isOpen}
            preferPlace={`below`}
            place={null}
            anchorEl={anchorEl}
            body="Walk In"
          >
            <div
              className={walkInStyle}
              onMouseOver={handlePopoverOpen}
              onMouseOut={handlePopoverClose}
            >
              W
            </div>
          </Popover>
          <p className={classes.slotTimes}>{startTime}</p>
        </>
      ) : (
        <>
          <p className={classes.slotTimes}>{startTime}</p>
          <p>-</p>
          <p className={classes.slotTimes}>{endTime}</p>
        </>
      )}
    </button>
  );
};
export default Slot;
