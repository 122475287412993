import { SESSIONSTORAGEKEY } from "../../../Shared/Helper/sessionStorageUtils";
import afterLoginApiAxios from "../../../Shared/afterLogin";

export const getPinCodeValidation = async (payload, url) => {
  const res = await afterLoginApiAxios({
    method: "post",
    url: url,
    data: payload,
    headers: {
      authorization: sessionStorage.getItem(SESSIONSTORAGEKEY.ID_TOKEN),
    },
  });

  if (res) {
    return [res, false];
  }
  return [null, true];
};
