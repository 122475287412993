import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import ErrorIcon from "@material-ui/icons/Error";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";
import "./styles.css";
import "./login.scss";
import ReactGA from "react-ga";
import { Navigate, useLocation } from "react-router-dom";
import afterLoginApiAxios from "../../../Shared/afterLogin";
import beforeLoginAxios from "../../../Shared/beforeLogin";
import { setAuthToken } from "../../../features/auth/authSlice";
import C2CLogo from "../../../asstes/images/C2C-Logo.png";
import { useSelector, useDispatch } from "react-redux";

function LoginEnterPassword({ setShowModal }) {
  const phoneNumber = useSelector((state) => state.selectNumber.number);

  let location = useLocation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  function handleForgotPassword() {
    beforeLoginAxios
      .post("public/send-otp", {
        user_type: "patient",
        channel_id: "patient-login",
        phone_no: `+91${phoneNumber}`,
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            setShowModal("forgot-password");
          }
        }
      });
  }

  function handlePassword(val) {
    setPassword(val);
  }

  let getBrowserName = () => {
    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== "undefined";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 79
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

    // Blink engine detection
    var isBlink = isChrome && !!window.CSS;

    if (isFirefox) return "FireFox";
    if (isSafari) return "Safari";
    if (isIE && !isEdge) return "IE";
    if (isEdge) return "Edge";
    if (isChrome) return "Chrome";
    if (isEdgeChromium) return "EdgeChromium";
    if (isBlink) return "Blink-based";
  };

  let registerAnalytics = () => {
    afterLoginApiAxios
      .post(
        "user/engagement-analytics-data",
        {
          usecase: "login",
          user_type: "2",
          device_details: {
            device_type: "desktop",
            device_manufacturer: "",
            os_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            os_name: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            application_type: "web",
            application_version: "1.0",
            browser_name: getBrowserName(),
            browser_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.lastIndexOf(")") + 1
            ),
          },
          patient_login_data: {
            patient_id: sessionStorage.getItem("user_id"),
          },
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("id_token"),
          },
        }
      )
      .then(() => {})
      .catch((err) => console.error(err));
  };

  const handleContinueClick = (e) => {
    e.preventDefault();
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      return;
    } else {
      setPasswordError(false);
    }

    beforeLoginAxios
      .post("auth/login-rev2", {
        channel_id: "patient-login",
        phone_no: `+91${phoneNumber}`,
        user_type: "patient",
        password: password,
        stay_login: false,
        source: "web", // add new parameter
      })
      .then((response) => {
        if (response) {
          if (response.data.hasOwnProperty("statusCode")) {
            if (response.data.statusCode == 200) {
              sessionStorage.setItem("id_token", response.data.user.id_token);
              sessionStorage.setItem("user_name", response.data.user.user_name);
              sessionStorage.setItem("user_type", response.data.user.user_type);
              sessionStorage.setItem("user_id", response.data.user.user_id);
              sessionStorage.setItem(
                "last_login_date",
                response.data.user.last_login_date
              );
              sessionStorage.setItem("stay-login", false);

              if (!response.data.user.last_login_date) {
                sessionStorage.setItem("last_login_date", "");
                sessionStorage.setItem("Home", true);
                sessionStorage.setItem("Appointment History", true);
              }
              ReactGA.event({
                category: "login",
                action: "patient_login",
                label: response.data.user.user_id,
              });

              registerAnalytics();

              if (
                location &&
                location.state &&
                location.state.redirectTo == false
              ) {
                return;
              }
              dispatch(setAuthToken(response.data.user.id_token));
            } else if (response.data.statusCode == 510) {
              setPasswordError("Incorrect password entered. Please try again");
            }
          }
        }
      });
  };

  if (phoneNumber == "") {
    return <Navigate to="/patient/login/" />;
  }

  return (
    <div className="login-container">
      <div className="widgetModalDiv widgetModalBg">
        <div className="WidgetModalContentDiv">
          <div className="w-100 d-flex align-items-center justify-content-center">
            <ArrowBackIcon
              onClick={() => {
                setShowModal("login");
              }}
              style={{
                position: "relative",
                right: window.innerWidth > 768 ? "115px" : "85px",
              }}
              className="color-blue cursor-pointer"
            />
            <span className="login-container-title">Login</span>
          </div>
          <span className="login-container-subtitle">
            Enter your password to login
          </span>
          <div
            className={
              "w-100 d-flex flex-row justify-content-between " +
              (window.innerWidth > 768 ? "mt-4" : "")
            }
          >
            <div className="d-inline-block login-muted-text">
              +91-{phoneNumber}
            </div>
            <Edit setShowModal={setShowModal} />
          </div>
          <div className="w-100 mt-3">
            <form
              className="d-flex flex-column align-items-center justify-content-center"
              onSubmit={(e) => {
                handleContinueClick(e);
              }}
            >
              <FormControl
                hiddenLabel="true"
                size="small"
                margin="dense"
                fullWidth="true"
              >
                <FilledInput
                  id="password-input"
                  placeholder="Enter Password"
                  aria-describedby="my-helper-text"
                  aria-label="Set-password"
                  disableUnderline="true"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    passwordError ? (
                      <ErrorIcon style={{ color: "#e84444" }} />
                    ) : (
                      <ShowPassword
                        setShowPassword={setShowPassword}
                        showPassword={showPassword}
                      />
                    )
                  }
                  onChange={(e) => {
                    handlePassword(e.target.value);
                  }}
                  value={password}
                />
              </FormControl>

              <div
                style={{ textAline: "right", justifyContent: "right" }}
                className={
                  "d-flex stay-login " + (window.innerWidth > 768 ? "pt-2" : "")
                }
              >
                <span
                  className="align-self-end cursor-pointer textLink border-bottom-0 login-primary-text"
                  style={{ textAline: "right", fontWeight: "600" }}
                  onClick={() => {
                    handleForgotPassword();
                  }}
                >
                  Forgot Password?
                </span>
              </div>
              <div
                className={
                  "input-error align-self-center " +
                  (window.innerWidth > 768 ? "mt-3 text-center" : "")
                }
              >
                {passwordError ? passwordError : ""}
              </div>
              <button className="btn primary-cta-button">Login</button>
            </form>
          </div>
          <div className="separator w-50 mt-3">OR</div>
          <div
            className="mt-2 textLink font-weight-bold"
            style={{ minWidth: "fit-content" }}
          >
            <div
              onClick={() => {
                setShowModal("otp-login");
              }}
            >
              Login with OTP
            </div>
          </div>
        </div>

        <div className="WidgetModalFooterLogo">
          Powered By <img alt="" src={C2CLogo} />
        </div>
      </div>
    </div>
  );
}

export default LoginEnterPassword;

const Edit = ({ setShowModal }) => {
  return (
    <span
      className="login-primary-text cursor-pointer"
      onClick={() => {
        setShowModal("login");
      }}
    >
      Edit
      <EditIcon className="color-blue cursor-pointer" fontSize="inherit" />
    </span>
  );
};

export function ShowPassword(props) {
  const { setShowPassword, showPassword = false } = props;

  if (showPassword === false) {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityIcon />
      </span>
    );
  } else {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityOffIcon />
      </span>
    );
  }
}
