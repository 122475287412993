import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import classes from "./DoctorProfile.module.css";
import afterLoginApiAxios from "../../../Shared/afterLogin";
import Slot from "./Slots";
import { Alert } from "react-bootstrap";
import {
  getWalkInId,
  groupSlotsByTime,
  checkOfffline,
  getMessage,
  PROCEDURE_SLOTS,
} from "../../../Shared/Helper";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Grow from "@material-ui/core/Grow";
import { Spinner } from "react-bootstrap";
import { ShowMoreButton } from "./ShowMoreButton";

import { MONTH_NAMES } from "./constant";

import { NEXT_SLOTS } from "./BookScheduleApiHandler.js/apiContants";
import { getNextSlots } from "./BookScheduleApiHandler.js/apiIndex.js";
import { SESSIONSTORAGEKEY } from "../../../Shared/Helper/sessionStorageUtils";
import Loader from "../../../ReusableComponents/Loader/Loader.jsx";

const Slots = ({
  selectedDate,
  doctorId,
  onSelect,
  setStartTime,
  consultType, //= 0
  setConsultData = () => {},
  followUpInfo = {},
  setCounter = () => {},
  selectedProcedure,
  doctor,
  slugUrl,
  reschedule,
  apiConsultationType,
  getSlotTime,
  handleSlotToEMpty,
  handleInstruction,
  doctorSlug,
  procedure_id,
  type = "modal",
  selectedClinic,
  setDateSelectEnable,
  setFollowUpEndDate,
  handleDate,
  clinicID, // passing for patient side rescheduling
  procedures,
  procedureLoading,
}) => {
  const currentRef = useRef({});
  const userType = sessionStorage.getItem(SESSIONSTORAGEKEY.USER_TYPE);
  const consultationType = sessionStorage.getItem(
    SESSIONSTORAGEKEY.CONSULTATION_TYPE
  );
  const navigate = useNavigate();
  const [morningSlots, setMorningSlots] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [afternoonSlots, setAfternoonSlots] = useState([]);
  const [eveningSlots, setEveningSlots] = useState([]);
  const [midnightSlots, setMidnightSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedSlotObj, setSelectedSlotObj] = useState({});
  const [totalSlots, setTotalSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextAvailableDate, setNextAvailableDate] = useState(null);
  const [slotsNotAvailable, setSlotsNotAvailable] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("No slots available.");
  const [doctorName, setdoctorName] = useState("");
  const [procedureDetails, setprocedureDetails] = useState("");
  let id = sessionStorage.getItem("procedure_id");
  const [consultationDetails, setConsultationDetails] = useState("");
  const [doctorDetails, setDoctorDetails] = useState("");
  const [label, setConsultationLabel] = useState("");
  const [midnightRowsToDisplay, setMidnightRowsToDisplay] = useState(
    type !== "modal" ? 16 : 20
  );
  const [morningRowsToDisplay, setMorningRowsToDisplay] = useState(
    type !== "modal" ? 16 : 20
  );
  const [afternoonRowsToDisplay, setAfternoonRowsToDisplay] = useState(
    type !== "modal" ? 16 : 20
  );
  const [eveningRowsToDisplay, setEveningRowsToDisplay] = useState(
    type !== "modal" ? 16 : 20
  );
  const [showMidnightSlot, setShowMidnightSlot] = useState(false);
  const [showEveningSlot, setShowEveningSlot] = useState(false);
  const [showMorningSlot, setShowMorningSlot] = useState(false);
  const [showAfternoonSlot, setShowAfternoonSlot] = useState(false);
  const [showNextSlotButton, setShowNextSlotButton] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [slotStyle, setSlotstyle] = useState("");
  const [alternateNo, setAlternateNo] = useState("");
  const clinicId = sessionStorage.getItem("clinicId");
  let isPatient = sessionStorage.getItem("user_type") === "patient";

  //life cycle ==>
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      if (
        window.innerWidth != windowSize.width &&
        window.innerHeight != windowSize.height
      ) {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize]);

  useEffect(() => {
    setSlotstyle(type === "modal" ? classes.modalWrap : classes.slotWrap);
    showMoreOption();
  }, [windowSize]);

  useEffect(() => {
    let slug = false;
    let clinic_id = sessionStorage.getItem("clinicId");
    let meeting_id = sessionStorage.getItem("meeting_id"); // to check if its an instant consultation
    if (doctor && doctor.doctor_slug) slug = doctor.doctor_slug;
    if (slugUrl) slug = slugUrl;
    if (doctorSlug) {
      slug = doctorSlug;
    }

    afterLoginApiAxios
      .post("/public/get-doctor-details-by-slug-rev1", {
        doctor_url_short_code: slug ? slug : "",
        ...(clinicID && { clinic_id: clinicID }),
      })
      .then((response) => {
        setLoading(false);
        if (response.data.statusCode == 200) {
          setDoctorDetails(response.data.doctor);
          setAlternateNo(
            response.data?.alternate_numbers?.length > 0
              ? response.data?.alternate_numbers[0].phone_no
              : ""
          );
          setprocedureDetails(response.data.procedure_list);
          sessionStorage.setItem(
            "is_associated_to_clinic",
            response.data.is_associated_to_clinic
          );
          if (!sessionStorage.getItem("doctor")) {
            sessionStorage.setItem(
              "doctor",
              JSON.stringify(response.data.doctor)
            );
          }
          setConsultationDetails([...response.data.regular_followup_list]);
          if (handleInstruction) {
            handleData(response.data);
          }
        }
      })
      .catch((err) => console.log(err));
  }, [selectedProcedure, selectedClinic]);

  useEffect(() => {
    if (doctorSlug !== undefined) {
      if (procedures?.length > 0) {
        fetchApi(2);
      } else {
        setShowNextSlotButton(false);
        setDisplayMessage("offline_payment");
        setSlotsNotAvailable(true);
      }
    }
  }, [consultationDetails, procedureDetails, procedures]);

  useEffect(() => {
    if (procedures?.length > 0) {
      fetchApi(1);
    } else {
      setShowNextSlotButton(false);
      setDisplayMessage("offline_payment");
      setSlotsNotAvailable(true);
    }
  }, [
    selectedDate,
    doctorId,
    selectedProcedure,
    selectedClinic,
    consultType,
    clinicId,
    procedures,
  ]); //newly added consultationDetails,procedureDetails
  useEffect(() => {
    setMorningSlots([]);
    setAfternoonSlots([]);
    setEveningSlots([]);
    if (procedures && procedures?.length == 0) {
      setSlotsNotAvailable(true);
    } else {
      setSlotsNotAvailable(false);
    }
    setSelectedSlot("");
    setSelectedSlotObj({});
  }, [selectedDate]);

  useEffect(() => {
    //? Slot Selected
    const selectedSlotObject = totalSlots.find((slot) =>
      selectedSlotObj.is_walkin
        ? slot.walkin_id === selectedSlotObj.walkin_id
        : !slot.is_walkin && slot.id === selectedSlot
    );
    onSelect(selectedSlot, selectedSlotObject, selectedSlotObj);
    setStartTime(selectedSlotObject?.start_time);
  }, [selectedSlot, selectedSlotObj, onSelect, totalSlots]);

  useEffect(() => {
    if (apiConsultationType && selectedDate && doctorId) {
      const clinicId = sessionStorage.getItem("clinicId");
      handleSlotToEMpty();
      let payload = {
        user_type: sessionStorage.getItem("user_type"),
        doctor_id: doctorId,
        date: moment(selectedDate).format("YYYY-MM-DD"),
        consultation_type: apiConsultationType.id,
        walkin_slot_required: true,
      };

      if (clinicId !== "" && clinicId !== undefined && clinicId !== null) {
        payload["clinic_id"] = clinicId;
      }
      if (apiConsultationType.id == "4") {
        payload["procedure_id"] = apiConsultationType.value;
      }
      setDateSelectEnable(false);
      afterLoginApiAxios({
        method: "post",
        url:
          clinicId !== "" && clinicId !== undefined && clinicId !== null
            ? "/clinic/get-appointments-slots-by-doctor-id"
            : "public/get-appointments-slots-by-doctor-id",

        headers: {
          authorization: sessionStorage.getItem("id_token"),
        },
        data: payload,
      }).then((res) => {
        setDateSelectEnable(true);
        if (res?.data?.statusCode == 200) {
          setLoading(false);
          setSlotsNotAvailable(false);
          let slots = getWalkInId(res.data.time_slots);
          setTotalSlots(slots);
          setShowNextSlotButton(false);
          return saveSlots(slots, 0);
        } else if (res?.data?.statusCode == 507) {
          nextAvailableSlots();
          // setDisplayMessage(res.data.message);
        } else if (res?.data?.statusCode == 505) {
          nextAvailableSlots();
          // setDisplayMessage(res.data.message);
        } else if (res?.data?.statusCode == 510) {
          setShowNextSlotButton(false);
          setSlotsNotAvailable(true);
          setDisplayMessage("offline_payment");
          // setDisplayMessage(res.data.message);
        } else {
          saveSlots([], 0);
          setLoading(false);
        }
      });
    }
  }, [selectedDate, doctorId]);
  //<==

  //helper methods ==>

  const handleData = (data) => {
    handleInstruction(data);
  };

  const checkConsultationOfffline = (consultationData) => {
    if (selectedProcedure && selectedProcedure.value) {
      if (
        sessionStorage.getItem("user_type") != "doctor" &&
        sessionStorage.getItem("user_type") != "staff"
      ) {
        if (consultationData && consultationData.length) {
          let selectedOne = consultationData.find(
            (data) => data.consultation_type == selectedProcedure.id
          );
          if (
            selectedOne &&
            (selectedOne.online_payment_enabled == "False" ||
              selectedOne.online_payment_enabled == false) // selectedOne.consultation_method === "False" &&
          ) {
            let name =
              doctor.prefix_name +
              " " +
              " " +
              doctor.first_name +
              " " +
              " " +
              doctor.middle_name +
              " " +
              " " +
              doctor.last_name +
              " ";
            setdoctorName(name);
            setDisplayMessage("offline_payment");
            setSlotsNotAvailable(true);
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    } else {
      if (
        sessionStorage.getItem("user_type") != "doctor" &&
        sessionStorage.getItem("user_type") != "staff"
      ) {
        if (consultationData && consultationData.length) {
          let selectedOne = consultationData.find(
            (data) => data.consultation_type == consultType
          );
          if (
            (followUpInfo?.rescheduling_available &&
              (selectedOne.online_payment_enabled == "False" ||
                selectedOne.online_payment_enabled == false)) ||
            (selectedOne &&
              (selectedOne.online_payment_enabled == "False" ||
                selectedOne.online_payment_enabled == false)) // selectedOne.consultation_method === "False" &&
          ) {
            let name =
              doctorDetails?.prefix_name +
              " " +
              " " +
              doctorDetails?.first_name +
              " " +
              " " +
              doctorDetails?.middle_name +
              " " +
              " " +
              doctorDetails?.last_name +
              " ";
            setdoctorName(name);
            setConsultationLabel(
              consultType == 5
                ? "In-Clinic Regular"
                : consultType == 5
                ? "In-Clinic Follow-Up"
                : ""
            );
            setDisplayMessage("offline_payment");
            setSlotsNotAvailable(true);
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  const showMoreOption = () => {
    setShowEveningSlot(true);
    setShowMorningSlot(true);
    setShowAfternoonSlot(true);
    setShowMidnightSlot(true);
    if (
      (windowSize.width == 320 && windowSize.height == 568) ||
      (windowSize.width == 320 && windowSize.height == 480)
    ) {
      setRows(5);
    } else if (windowSize.width == 768 && windowSize.height == 1024) {
      setRows(12);
    } else if (windowSize.width < 640) {
      setRows(10);
    } else if (windowSize.width >= 640 && windowSize.width < 770) {
      setRows(12);
    } else if (windowSize.width >= 770 && windowSize.width <= 1024) {
      setRows(12);
    } else if (windowSize.width > 1024 && windowSize.width < 1200) {
      setRows(12);
      //setRows(type === "modal" ? 12 : 12);
    } else if (windowSize.width >= 1200 && windowSize.width < 1300) {
      setRows(type !== "modal" ? 12 : 20);
    } else if (windowSize.width >= 1300) {
      setRows(type !== "modal" ? 16 : 20);
    } else {
      setRows(type !== "modal" ? 16 : 24);
    }
  };

  const setRows = (rows) => {
    setEveningRowsToDisplay(rows);
    setAfternoonRowsToDisplay(rows);
    setMorningRowsToDisplay(rows);
    setMidnightRowsToDisplay(rows);
  };

  // creating an unique id for walk-in slots since the id is same for walk-in and normal slot

  // Splits slots in Morning, Afternoon, Evening slots
  const saveSlots = (fetchedSlots, counter) => {
    const {
      tempMorningSlots,
      tempAfternoonSlots,
      tempEveningSlots,
      tempMidnightSlots,
    } = groupSlotsByTime(fetchedSlots, selectedDate, counter);
    if (
      tempMorningSlots.length === 0 &&
      tempAfternoonSlots.length === 0 &&
      tempEveningSlots.length === 0 &&
      tempMidnightSlots.length === 0
    ) {
      nextAvailableSlots();
    } else {
      setDisplayMessage(false);
      setSlotsNotAvailable(false);
    }

    setMorningSlots(tempMorningSlots);
    setAfternoonSlots(tempAfternoonSlots);
    setEveningSlots(tempEveningSlots);
    setMidnightSlots(tempMidnightSlots);
  };

  //, apiConsultationType

  const showMore = (type) => {
    if (type === "midnightSlots") {
      setMidnightRowsToDisplay(midnightSlots.length);
      setShowMidnightSlot(false);
    } else if (type === "eveningSlots") {
      setEveningRowsToDisplay(eveningSlots.length);
      setShowEveningSlot(false);
    } else if (type === "afternoonSlots") {
      setAfternoonRowsToDisplay(afternoonSlots.length);
      setShowAfternoonSlot(false);
    } else {
      setMorningRowsToDisplay(morningSlots.length);
      setShowMorningSlot(false);
    }
  };

  const handleNextSlotClick = () => {
    const formattedDate = moment(nextAvailableDate).format("YYYY-MM-DD");
    handleDate(new Date(formattedDate));
  };

  //<===

  //Api calls

  const nextAvailableSlots = () => {
    let term = {
      doctor_id: doctorId,
      user_type: userType,
      date: moment(selectedDate).format("YYYY-MM-DD"),
      consultation_type: consultType,
      ...(id && {
        procedure_id: id,
        consultation_type: consultationType,
      }),
      ...(procedure_id && {
        procedure_id: procedure_id,
        consultation_type: "4",
      }),
    };
    if (clinicID) {
      term["clinic_id"] = clinicID;
    } else {
      if (clinicId) {
        term["clinic_id"] = clinicId;
      }
    }
    setLoading(true);
    getNextSlots(term, NEXT_SLOTS)
      .then(([res, error]) => {
        if (res?.data?.statusCode === 200) {
          const nextSlotDate = res.data.date;
          const parts = nextSlotDate.split("-");
          const day = parseInt(parts[2]);
          const month = MONTH_NAMES[parseInt(parts[1]) - 1];
          const year = parseInt(parts[0]);
          const formattedDate = `${day} ${month} ${year}`;
          setNextAvailableDate(formattedDate);
          setShowNextSlotButton(true);
          setSlotsNotAvailable(false);
          setDisplayMessage(null);
          const nextDate = moment(nextSlotDate);
          const afterAnYear = moment().add(1, "years");
          const isMoreThanOneYear = nextDate.isAfter(afterAnYear);
          if (isMoreThanOneYear) {
            setShowNextSlotButton(false);
            setSlotsNotAvailable(true);
            setDisplayMessage("No slots found.");
          }
          setLoading(false);
        } else if (res?.data?.statusCode === 500) {
          setShowNextSlotButton(false);
          setSlotsNotAvailable(true);
          setDisplayMessage("offline_payment");
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false); // Set loading state to false in case of an error
        console.error(error);
      });
  };

  const fetchApi = (num) => {
    setSlotsNotAvailable(false);
    showMoreOption();
    //? Fetch API

    setMorningSlots([]);
    setAfternoonSlots([]);
    setEveningSlots([]);
    setMidnightSlots([]);
    let clinic_id = sessionStorage.getItem("clinicId");

    if (selectedDate && doctorId) {
      if (
        (consultType == 0 || consultType == 5 || consultType == 6) &&
        sessionStorage.getItem("Tele_Consult_Follow_Up") !== "Follow-Up"
      ) {
        setLoading(true);

        //=== to ==
        //? Regular time slots
        let term = {
          doctor_id: doctorId,
          user_type: sessionStorage.getItem("user_type"),
          date: moment(selectedDate).format("YYYY-MM-DD"),
          consultation_type: consultType,
          ...(id && {
            procedure_id: id,
            consultation_type: sessionStorage.getItem(
              "procedure_consultation_type"
            ),
          }),
          ...(procedure_id && {
            procedure_id: procedure_id,
            consultation_type: "4",
          }), //sending procedure id and consultatuon type while reschedule
        };
        if (clinicID) {
          term["clinic_id"] = clinicID;
        } else {
          if (clinic_id) {
            term["clinic_id"] = clinic_id;
          }
        }
        //if from patient side rescheduling, use clinic id from props else from sessionStorage

        currentRef.current = term;
        let url = isPatient
          ? "/public/get-booking-slots-by-doctor-id-rev1"
          : clinicID !== "" && clinicID !== undefined && clinicID !== null
          ? "/clinic/get-appointments-slots-by-doctor-id"
          : "public/get-appointments-slots-by-doctor-id";

        afterLoginApiAxios.post(url, term).then((response) => {
          setLoading(false);

          if (currentRef.current === term) {
            if (response.data?.statusCode == 200) {
              let current_consultaion_type = isPatient
                ? response.data.data.consultation_type
                : response.data.consultation_type;
              sessionStorage.setItem(
                "consultation_type",
                current_consultaion_type
              );
              sessionStorage.setItem(
                "consultation-type",
                current_consultaion_type
              );
              if (response.data?.vacation_data?.start_date !== undefined) {
                setSlotsNotAvailable(true);
                setStartDate(response.data.vacation_data.start_date);
                setEndDate(response.data.vacation_data.end_date);
                setDisplayMessage("vac-error");
              }
              // else if(response.data.data.time_slots){

              // }
              else {
                // setDisplayMessage("No slots found.");
                let slots_data = [];
                if (isPatient) {
                  slots_data = response.data.data.time_slots;
                } else {
                  slots_data = response.data.time_slots;
                }

                const consultationSettings = {
                  fees: response.data?.fees,

                  duration: PROCEDURE_SLOTS.find(
                    (slot) => slot.slotType === +response.data?.slot_type
                  ),
                };
                setConsultData(consultationSettings);
                setTotalSlots(slots_data);
                setShowNextSlotButton(false);
                setCounter(response.data?.data?.counter);

                let isOffline = false;
                if (id || procedure_id) {
                  //newly added || procedure_id
                  isOffline = checkOfffline(
                    selectedProcedure,
                    procedureDetails,
                    doctor,
                    doctorDetails,
                    setdoctorName,
                    setDisplayMessage,
                    setSlotsNotAvailable,
                    procedure_id,
                    followUpInfo
                  );
                } else if (consultType == 5 || consultType == 6) {
                  isOffline = checkConsultationOfffline(consultationDetails);
                }
                if (isOffline == false) {
                  return saveSlots(slots_data, slots_data?.length);
                }
              }
            } else if (response.data?.statusCode == 502) {
              nextAvailableSlots();
            } else if (response.data?.statusCode == 505) {
              nextAvailableSlots();
            } else {
              if (!isPatient) {
                nextAvailableSlots();
                saveSlots([], 0);
                setLoading(false);
              }
            }
            // setSlotsNotAvailable(true);
          }
        });
      } else if (consultType == 1) {
        //? Follow Up Time Slots

        let params = {};
        if (sessionStorage.getItem("followup_consultation_type")) {
          params["consultation_type"] = sessionStorage.getItem(
            "followup_consultation_type"
          );
        }
        afterLoginApiAxios
          .post(
            "patient/patient-follow-up-slots-by-doctor-id",
            {
              patient_id:
                followUpInfo.patient_id || sessionStorage.getItem("user_id"),
              patient_name:
                followUpInfo.patient_name ||
                sessionStorage.getItem("patient_name"),
              patient_dob:
                followUpInfo.patient_dob ||
                sessionStorage.getItem("patient_dob"),
              patient_account_id:
                followUpInfo.patient_account_id ||
                sessionStorage.getItem("patient_account_id"),
              consultation_type: consultType,
              doctor_id: followUpInfo.doctor_id || doctor?.doctor_id,
              date: moment(selectedDate).format("YYYY-M-D"),
              ...(clinic_id && { clinic_id: clinic_id }),
            },
            {
              headers: {
                authorization: sessionStorage.getItem("id_token"),
              },
            }
          )
          .then((response) => {
            setLoading(false);
            if (response.data?.statusCode === 200) {
              // added condition to check whether the doctor has taken vaccation for follow up consultation
              if (response?.data?.follow_up_end_date) {
                setFollowUpEndDate(response.data.follow_up_end_date);
              }
              if (response.data?.vacation_data?.start_date !== undefined) {
                setSlotsNotAvailable(true);
                setStartDate(response.data.vacation_data.start_date);
                setEndDate(response.data.vacation_data.end_date);
                setDisplayMessage("vac-error");
              } else {
                const slots = response.data.data.time_slots;

                const consultData = {
                  fees: response.data.fees,
                  duration: PROCEDURE_SLOTS.find(
                    (slot) => slot.slot_type === +response.data.slot_type
                  ), //.label
                  valid: response.data.follow_up_end_date,
                };
                setConsultData(consultData);
                setTotalSlots(slots);
                setCounter(response.data?.data?.counter);

                return saveSlots(slots, response.data?.data?.counter);
              }
            }
            if (response.data?.statusCode === 513) {
              swal({
                icon: "error",
                title:
                  "Time expired to Book a Followup against this appointment.",
              });
            }
            setSlotsNotAvailable(true);
          })
          .catch((err) => {
            if (err.response?.status === 403) {
              //  unAuthorizedError(() => {
              //    navigate.push("/patient/login");
              //  });
            }
          });
      } else if (
        sessionStorage.getItem("Tele_Consult_Follow_Up") === "Follow-Up"
      ) {
        setLoading(false);
      }
    }
  };
  //<===

  //Display UI
  const creatNextSlotsUI = () => {
    return (
      <div className="next-avaliablity" onClick={() => handleNextSlotClick()}>
        Next Available Slot On {nextAvailableDate}
      </div>
    );
  };

  const displaySlots = (
    slotList,
    title,
    imgPath,
    rowsToDisplay,
    showSlot,
    slotName
  ) => {
    return (
      <Grow in={slotList.length > 0} timeout={500}>
        <div>
          {slotList?.length ? (
            <div className={classes.slots}>
              <div className="morning-top">
                <img src={process.env.PUBLIC_URL + imgPath} alt="" /> {title}
              </div>
              <div className={"a " + classes.slotTimes + " " + slotStyle}>
                {slotList.slice(0, rowsToDisplay).map((slot, index) => (
                  <Slot
                    key={index}
                    slot={slot}
                    startTime={slot.start_time}
                    endTime={slot.end_time}
                    selected={
                      selectedSlotObj.is_walkin
                        ? slot.walkin_id === selectedSlotObj.walkin_id
                        : !slot.is_walkin && slot.id === selectedSlot
                    }
                    id={slot.walkin_id ? slot.walkin_id : slot.id}
                    onClick={() => {
                      if (getSlotTime) {
                        getSlotTime(slot.start_time, slot);
                      }
                      setSelectedSlot(slot.id);
                      setSelectedSlotObj(slot);
                    }}
                    type={type}
                    reschedule={reschedule}
                  />
                ))}
              </div>
              {showSlot && slotList?.length > rowsToDisplay && (
                <ShowMoreButton
                  isShow={showSlot}
                  onClick={() => showMore(slotName)}
                />
              )}
            </div>
          ) : null}
        </div>
      </Grow>
    );
  };

  const displayAlertUI = () => {
    return (
      <div className="text-center">
        {/* <span className="text-danger">No slots available</span> */}
        <Alert variant={"danger"} style={{ opacity: 1 }}>
          {" "}
          {/**,marginTop:"5rem" */}
          {displayMessage === "vac-error" && (
            <span>
              Doctor is not available for consultation during the period:{" "}
              <strong>
                {moment(startDate).format("DD MMM, YYYY")} to{" "}
                {moment(endDate).format("DD MMM, YYYY")}.
              </strong>{" "}
              Please select any other day.
            </span>
          )}
          {displayMessage === "offline_payment" && (
            <p>{getMessage(alternateNo, clinicId)}</p>
          )}
          {displayMessage === "No slots found." && displayMessage}
        </Alert>
      </div>
    );
  };

  const creatLoadingUI = () => {
    return (
      <div className="row align-items-center justify-content-center ">
        <Spinner
          animation="border"
          style={{ color: "rgb(144, 202, 249)", borderWidth: "3px" }}
        />
      </div>
    );
  };
  //<==
  return (
    <div
      className={
        "book-schedule w-100 " + (window.innerWidth > 768 ? "mt-3" : "")
      }
    >
      {(loading || procedureLoading) && creatLoadingUI()}
      {procedureLoading && <Loader />}
      {showNextSlotButton && creatNextSlotsUI()}
      {slotsNotAvailable && !procedureLoading ? (
        displayAlertUI()
      ) : (
        <>
          {displaySlots(
            midnightSlots,
            "midnight: 12:00 AM - 06:00 Am",
            "/images/morning-icon-new.svg",
            midnightRowsToDisplay,
            showMidnightSlot,
            "midnightSlots"
          )}
          {displaySlots(
            morningSlots,
            "morning: 06:00 AM - 12:00 Pm",
            "/images/morning-icon-new.svg",
            morningRowsToDisplay,
            showMorningSlot,
            "morningSlots"
          )}
          {displaySlots(
            afternoonSlots,
            "Afternoon: 12:00 pM - 06:00 Pm",
            "/images/afternoon-icon.png",
            afternoonRowsToDisplay,
            showAfternoonSlot,
            "afternoonSlots"
          )}
          {displaySlots(
            eveningSlots,
            "Evening: 06:00 pM - 12:00 Am",
            "/images/evening-icon-new.svg",
            eveningRowsToDisplay,
            showEveningSlot,
            "eveningSlots"
          )}
        </>
      )}
    </div>
  );
};

export default Slots;
