import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    const currentStepper = useSelector((store) => store.stepper.currentStepper);
    const appointmentType = useSelector(
      (store) => store.stepper.appointmentType
    );
    const IsShowTeleConsult = useSelector(
      (state) => state.isTeleConsultSlice.IsTeleConsult
    );
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    let dispatch = useDispatch();
    return (
      <Component
        {...props}
        redux={{ currentStepper, dispatch, appointmentType, IsShowTeleConsult }}
        router={{ location, navigate, params }}
      />
    );
  }
  return ComponentWithRouterProp;
};
