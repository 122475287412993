import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { STEPPER_ROUTE_DATA_PATIENT } from "../../Shared/constants";
import { useDispatch, useSelector } from "react-redux";
import { setAppointmentType, setStepper } from "../../features/stepper/StepperSlice";
import { IsTeleConsult } from "../../features/setIsTeleConsult/setIsTeleConsultSlice";
import RedCross from "../../asstes/images/red_close.png";

export const CantTakeFollowupConfirmationModal = ({ setShowConfirmationModal, showConfirmationModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentStepper, appointmentType } = useSelector((state) => state.isTeleConsultSlice);

  const handleSendBackToRegularFollow = () => {
    dispatch(IsTeleConsult(false));
    dispatch(setAppointmentType(appointmentType));
    dispatch(setStepper(currentStepper));
    navigate(STEPPER_ROUTE_DATA_PATIENT[appointmentType][currentStepper]);
  };

  return (
    <>
      <Modal size="lg" dialogClassName="modal-width" centered show={showConfirmationModal} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Body>
          <div className="text-center mt-3">
            <img src={RedCross} className="times-circle-icon mb-3" alt="" />
            <h3 className="text-center mt-3">
              <b>You are not eligible for a follow up appointment.</b>
            </h3>
            <p className="mb-4">Do you want to proceed with Regular Booking?</p>
            <button onClick={handleSendBackToRegularFollow} className="footer_save_cont_btn-for-tele-consult mb-4">
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirmationModal(false);
              }}
              className="footer_save_cont_btn-for-tele-consult no-button-for-tele-consult mb-4">
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
