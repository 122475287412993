import axios from "axios";
import swal from "sweetalert";
import { LOCALENV } from "./config";

const USER_TYPE = {
  DOCTOR: "doctor",
  STAFF: "staff",
  PATIENT: "patient",
  SUPPORT: "support",
};
let apiMapUrl = {
  dev: "https://api-dev.c2cassets.com/",
  dev1: "https://api-dev1.c2cassets.com/",
  dev2: "https://api-dev2.c2cassets.com/",
  qa: "https://api-qa.c2cassets.com/",
  uat: "https://api-uat.c2cassets.com/",
  prod: "https://api.connect2clinic.com/",
  maint: "https://api-maint.c2cassets.com/",
  stage: "https://api-stage.c2cassets.com/",
};

const api =
  window.location.hostname === "localhost"
    ? LOCALENV
    : process.env.REACT_APP_ENV;
const afterLoginApiAxios = axios.create({
  baseURL: apiMapUrl[api],
  headers: {
    "X-Application-Source": "web",
    Authorization: sessionStorage.getItem("token"),
  },
});

afterLoginApiAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      swal({
        icon: "error",
        type: "error",
        title: "Your session got expired please login again",
        timer: 10000,
      })
        .then(function () {
          // window.sessionStorage.clear();
          const userType = window.location.pathname.split("/")[1];
          switch (userType) {
            case USER_TYPE.PATIENT:
              sessionStorage.removeItem("id_token");
              window.location.replace("/patientLogin");
              break;
            default:
              sessionStorage.removeItem("id_token");
              window.location.replace("/patientLogin");
              break;
          }
          // const userType = window.location.pathname.split("/")[1];
          // switch (userType) {
          //   case USER_TYPE.PATIENT:
          //     window.location.replace(`/${window.location.pathname.split("/")[1]}/login`);
          //     break;
          //   case USER_TYPE.DOCTOR:
          //     window.location.replace(`/${USER_TYPE.DOCTOR}/login`);
          //     break;
          //   default:
          //     window.location.replace(`/${USER_TYPE.DOCTOR}/login`);
          //     break;
          // }
        })
        .catch(function () {
          window.sessionStorage.clear();
          window.location.replace(`/`);
        });
    }
  }
);

export default afterLoginApiAxios;
