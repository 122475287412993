import React from "react";
import classes from "./DoctorProfile.module.css";

export const ShowMoreButton = ({isShow, onClick, className, ...props}) => {
  return (
    <div
      style={{display: "flex", justifyContent: "center", alignItems: "center"}}
      className="mt-2"
    >
      <button
        className={`available-slots ${classes.timeBox} ${classes.btnShowMore}`}
        onClick={() => onClick()}
      >
        <p
          className={classes.slotTimes}
          style={{
            color: "#17BAE0",
            fontSize: 12,
            textTransform: "uppercase",
            opacity: 1,
            fontWeight: "bold",
          }}
        >
          SHOW MORE
        </p>
      </button>
    </div>
  );
};
