import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setStepper } from "../../features/stepper/StepperSlice";
import { STEPPER_ROUTE_DATA_PATIENT } from "../../Shared/constants";
import { STEPPER_DATA_PATIENT } from "../../Shared/constants";
import C2CLogo from "../../asstes/images/C2C-Logo.png";
import "./footer.scss";

const Footer = (props) => {
  const {
    backIsDisabled,
    nextIsDisabled,
    nextHandlerPromise,
    nextHandler,
    afterEnableFunction,
    backFunction,
  } = props;

  const dispatch = useDispatch();
  const appointmentType = useSelector((state) => state.stepper.appointmentType);
  const data = STEPPER_DATA_PATIENT[appointmentType];
  const navigate = useNavigate();
  const currentStepper = useSelector((state) => state.stepper.currentStepper);
  const createOnClickHandler = () => {
    if (afterEnableFunction) {
      afterEnableFunction();
    } else {
      if (nextHandlerPromise) {
        nextHandlerPromise()
          .then(() => {
            data.length > currentStepper &&
              navigate(
                STEPPER_ROUTE_DATA_PATIENT[appointmentType][currentStepper + 1]
              );
            dispatch(
              setStepper(
                data.length > currentStepper
                  ? currentStepper + 1
                  : currentStepper
              )
            );
          })
          .catch((error) => console.log({ error }));
      } else if (nextHandler) {
        nextHandler();
        data.length > currentStepper &&
          navigate(
            STEPPER_ROUTE_DATA_PATIENT[appointmentType][currentStepper + 1]
          );
        dispatch(
          setStepper(
            data.length > currentStepper ? currentStepper + 1 : currentStepper
          )
        );
      } else {
        data.length > currentStepper &&
          navigate(
            STEPPER_ROUTE_DATA_PATIENT[appointmentType][currentStepper + 1]
          );
        dispatch(
          setStepper(
            data.length > currentStepper ? currentStepper + 1 : currentStepper
          )
        );
      }
    }
  };
  return (
    <div className="ba-modal-footer">
      <div className="ba-modal-footerbtn">
        <button
          className="back_btn"
          disabled={
            backIsDisabled
              ? backIsDisabled
              : currentStepper === 1
              ? true
              : false
          }
          style={{
            cursor:
              backIsDisabled || currentStepper === 1
                ? "not-allowed"
                : "pointer",
          }}
          onClick={() => {
            backFunction && backFunction(appointmentType, currentStepper);
            1 < currentStepper &&
              navigate(
                STEPPER_ROUTE_DATA_PATIENT[appointmentType][currentStepper - 1]
              );
            dispatch(
              setStepper(
                1 < currentStepper ? currentStepper - 1 : currentStepper
              )
            );
          }}
        >
          Back{" "}
        </button>
        <button
          className="footer_save_cont_btn"
          disabled={nextIsDisabled ? nextIsDisabled : false}
          onClick={() => {
            createOnClickHandler();
          }}
        >
          Save & Continue{" "}
        </button>
      </div>
      <div className="side-footer-logo">
        <span>Powered By</span> <img src={C2CLogo} alt="c2c" />{" "}
      </div>
    </div>
  );
};

export default Footer;
