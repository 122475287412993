// dependecies import ==>
import React from "react";
import { useSelector } from "react-redux";
// assets and scss import ==>
import "./stepper.scss";
import CheckIcon from "../../asstes/images/check-icon.svg";
// project file import ==>
// constant import ==>

const Stepper = ({ data }) => {
  const currentStepper = useSelector((state) => state.stepper.currentStepper);
  return (
    <div className="stepper-main-box">
      {data.map(({ value, label }) => (
        <div key={value} className="stepper-value-container">
          <div
            className={
              currentStepper >= value
                ? "current-stepper"
                : "stepper-not-selected"
            }
          >
            {currentStepper > value ? (
              <img className="stepper-img-style" src={CheckIcon} alt="" />
            ) : (
              value
            )}
          </div>
          <span
            className={
              currentStepper >= value
                ? "current-stepper-label"
                : "stepper-not-selected-label"
            }
          >
            {window.innerWidth > 768
              ? label
              : currentStepper === value && label}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
