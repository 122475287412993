import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import swal from "sweetalert";
import { setDoctorId } from "../../features/auth/authSlice";
import { setSpecialization } from "../../features/user/userSlice";
import afterLoginApiAxios from "../../Shared/afterLogin";

const SpecializationSelect = (props) => {
  //const clinicId = useSelector((state)=> state.auth.clinic_id);
  const clinicId = sessionStorage.getItem("clinicId");
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(null);
  const [handleSpecialization, setHandleSpecialization] = useState("");
  const [specializationOptions, setSpecializationOptions] = useState([]);

  useEffect(() => {
    getSpecializationList();
    dispatch(
      setSpecialization({ specilizations_id: null, specializations: null })
    );
  }, []);

  useEffect(() => {
    if (props?.setSelectDoctorInfo?.specialization) {
      const filter = specializationOptions.filter(
        (ele) =>
          ele.specializations === props.setSelectDoctorInfo.specialization
      );
      setHandleSpecialization(filter);
    }
  }, [props?.setSelectDoctorInfo]);

  const customStyles = {
    control: (base) => ({
      ...base,
      width: "100% !important",
      background: "#F5F6F7",
      boxShadow: "none",
      border: "none",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "none",
      },
      height: 35,
      minHeight: 35,
    }),
    menuList: () => ({
      maxHeight: 174,
      overflowY: "scroll",
      "::-webkit-scrollbar": {
        width: "10px",
      },
      "::-webkit-scrollbar-track": {
        background: "#fff",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#C1C1C1",
        borderRadius: "4px",
        height: "10px",
      },
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#A3A9B7",
        fontSize: "14px",
      };
    },
    option: (base) => ({
      ...base,
      border: `1px solid #DBDBDB`,
      padding: "10px",
      fontSize: "14px",
    }),
  };

  const getSpecializationList = () => {
    afterLoginApiAxios
      .get(
        `api/v1/public/clinic/${clinicId}/specializations` // "Specialization list",
      )
      .then((response) => {
        setSpecializationOptions(response.data.data);
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.status ? error.message : "Something went wrong!",
          timer: 5000,
        });
        setSpecializationOptions([]);
      });
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <i
          className="fa fa-caret-down"
          style={{ color: "#000", fontSize: "18px", color: "#17BAE0" }}
        ></i>
      </components.DropdownIndicator>
    );
  };

  const setSpecializationValue = (value) => {
    dispatch(setSpecialization(value));
    setHandleSpecialization(value);
    // remove the doctor list and selected doctor after change in specialization
    dispatch(setDoctorId(null));
    props.setDoctorList([]);
  };

  return (
    <>
      <Select
        styles={customStyles}
        placeholder="Select Specialization"
        //  defaultValue={selectedOption}
        value={handleSpecialization}
        onChange={(value) => setSpecializationValue(value)}
        options={specializationOptions}
        getOptionLabel={(specializationOption) =>
          specializationOption.specializations
        }
        getOptionValue={(specializationOption) =>
          specializationOption.specilizations_id
        }
        autoFocus={false}
        components={{
          DropdownIndicator: DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
      />
    </>
  );
};

export default SpecializationSelect;
