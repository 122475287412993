import { createSlice } from "@reduxjs/toolkit";

export const stepperSlice = createSlice({
  name: "selectPatient",
  initialState: {
    appointmentType:1,
    currentStepper: 1,
  },
  reducers: {
    setStepper: (state, action) => {
      state.currentStepper= action.payload;
    },
    setAppointmentType:(state,action) => {
      state.appointmentType = action.payload;
    }
  },
});

export const { setStepper,setAppointmentType } = stepperSlice.actions;

export default stepperSlice.reducer;
