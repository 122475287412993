import React, { Component } from "react";
import swal from "sweetalert";
import afterLoginApiAxios from "../../Shared/afterLogin";
import { doctorProfileFiles } from "../../Shared/APIList";
import moment from "moment";
import c2cLogo from "../../asstes/images/C2C-Logo.png";
import { connect } from "react-redux";

class Thankyou extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      token: "",
      txnid: sessionStorage.getItem("txnID"),
      email: sessionStorage.getItem("email"),
      message: "",
      doctor: null,
      start: "",
      date: "",
      time: "",
      paymentSuccess: false,
      URL: doctorProfileFiles,
      transactionPollingTimer: null,
      appointment: null,
      todayDate: "",
      startTime: "",
    };

    this.logOut = this.logOut.bind(this);
    sessionStorage.removeItem("fail_msg");
  }

  confirmPayment = () => {
    afterLoginApiAxios
      .post(
        "/patient/match-payu-hash-response",
        {
          transaction_id: sessionStorage.getItem("txnID"),
          patient_id: sessionStorage.getItem("user_id"),
        },
        {
          headers: {
            Authorization: `${window.sessionStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode == 200) {
          this.setState({ paymentSuccess: true });
          clearInterval(this.transactionPollingTimer);
          localStorage.clear();
        } else if (response.data.statusCode == 540) {
          //no trans found, 541= pending, 542= failed
          clearInterval(this.transactionPollingTimer);
          sessionStorage.setItem("fail_msg", response.data.message);
          this.props.history.push("/requesttimedout");
        } else if (response.data.statusCode == 542) {
          clearInterval(this.transactionPollingTimer);
          sessionStorage.setItem("fail_msg", response.data.message);
          this.props.history.push("/requesttimedout");
        }
      });
  };

  getDetails = () => {
    afterLoginApiAxios
      .post(
        "/patient/get_appointment_by_id",
        {
          appointment_id: sessionStorage.getItem("inclinic-online-id"),
          "authorizer-principal-id": {
            user_type: 2,
            user_id: sessionStorage.getItem("user_id"),
          },
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        this.setState({ appointment: response.data.data[0] });
        let date = response.data.data[0].appointment_date;
        let time = response.data.data[0].start_time;
        this.setState({
          todayDate: moment(date).format("DD MMM YYYY"),
          startTime: moment(time, "hh:mm:ssZ").format("hh:mm A"),
          doctor: {
            first_name: response.data.data[0]?.first_name,
            last_name: response.data.data[0]?.last_name,
            profile_picture_url: response.data.data[0]?.profile_picture_url,
          },
        });
      })
      .catch((error) => console.log("Error", error));
  };

  componentDidMount() {
    // disableHelpButton();
    window.scrollTo(0, 0);
    if (sessionStorage.getItem("free")) {
      this.setState({ paymentSuccess: true });
      if (sessionStorage.getItem("inclinic-online") == "true") {
        this.getDetails();
      }
    } else {
      this.transactionPollingTimer = setInterval(() => {
        this.confirmPayment();
      }, 5000);
      if (sessionStorage.getItem("inclinic-online") == "true") {
        this.getDetails();
      }
    }
  }

  logOut() {
    afterLoginApiAxios
      .post(
        "auth/user-logout",
        {
          user_token: `${sessionStorage.getItem("id_token")}`,
        },
        {
          headers: {
            Authorization: `${window.sessionStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        sessionStorage.removeItem("id_token");
        sessionStorage.removeItem("user_type");
        sessionStorage.removeItem("user_id");
        sessionStorage.removeItem("user_name");
        sessionStorage.removeItem("full_name");
        sessionStorage.removeItem("meeting_id");
        this.props.history.push("/patient/login");
      })
      .catch((err) => {
        console.log("error found");
        console.log(err);
        swal("", "An error occured, logging out now!", "info").then(() => {
          sessionStorage.removeItem("id_token");
          sessionStorage.removeItem("user_type");
          sessionStorage.removeItem("user_id");
          sessionStorage.removeItem("user_name");
          sessionStorage.removeItem("full_name");
          this.props.history.push("/patient/login");
        });
      });
  }

  componentWillMount() {
    if (sessionStorage.getItem("meeting_id") !== null) {
      afterLoginApiAxios
        .post(
          "/patient/get_appointment_by_id",
          {
            appointment_id: sessionStorage.getItem(
              "flag_appointment_id_instant"
            ),
          },
          {
            headers: {
              Authorization: `${window.sessionStorage.getItem("id_token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.statusCode === 200) {
            this.setState({ appointment: res.data.data[0] });
            let date = res.data.data[0].appointment_date;
            let time = res.data.data[0].start_time;
            this.setState({ todayDate: moment(date).format("DD MMM YYYY") });
            this.setState({
              startTime: moment(time, "hh:mm:ssZ").format("hh:mm A"),
            });

            afterLoginApiAxios({
              method: "post",
              url: "/patient/patient_invitation_action",
              headers: {
                authorization: sessionStorage.getItem("id_token"),
              },
              data: {
                patient_id: sessionStorage.getItem("user_id"),
                event_id: 4,
                instant_consult_key: sessionStorage.getItem("meeting_id"),
              },
            })
              .then((res) => {
                console.log(res);
              })
              .catch((err) => console.log(err));
          }
        });
    }
    var start = sessionStorage.getItem("start");
    var date = sessionStorage.getItem("date");
    var time = sessionStorage.getItem("time");
    var doctor = this.props.doctorDetails;

    this.setState({
      start: start,
      date: date,
      time,
      doctor: doctor,
    });
  }

  componentWillUnmount() {
    // enableHelpButton();
    sessionStorage.setItem("bolt_txnid", "");
    sessionStorage.removeItem("inclinic-online-id");
    sessionStorage.removeItem("is_associated_to_clinic");
    sessionStorage.removeItem("inclinic-online");
    sessionStorage.removeItem("free");
  }

  render() {
    var styles = {
      cardOdd: {
        backgroundColor: "#eeae01",
        color: "#454545",
      },
      cardEven: {
        backgroundColor: "#454545",
        color: "#eeae01",
      },
    };

    return (
      <div className="login-container">
        <div className="widgetModalDiv widgetModalBg">
          {this.state.paymentSuccess ? (
            <section
              style={{
                width: window.innerWidth > 768 ? "60%" : "100%",
              }}
            >
              <div
                className="bg-white shadow-md text-center"
                style={{
                  borderRadius: 15,
                  padding: window.innerWidth > 768 ? "1.5rem" : "20px 10px",
                }}
              >
                <h4
                  style={{
                    font:
                      window.innerWidth > 768
                        ? ""
                        : "normal normal 500 18px/22px Rubik, sans-serif",
                    marginBottom: window.innerWidth > 768 ? "" : "20px",
                  }}
                >
                  {" "}
                  Booking Confirmation{" "}
                </h4>
                <div className="widget-book-modal-center-content">
                  <div className={window.innerWidth > 768 ? "me-2" : "me-1"}>
                    <i
                      className="fa fa-thumbs-up successThumbs"
                      style={{
                        fontSize: window.innerWidth > 768 ? "102px" : "50px",
                        marginBottom: 12,
                      }}
                    ></i>
                  </div>
                  <div className={window.innerWidth > 768 ? "me-3" : "me-1"}>
                    {this.state?.doctor?.first_name !== undefined && (
                      <p
                        style={{
                          color: "#404760",
                          font:
                            window.innerWidth > 768
                              ? ""
                              : "normal normal normal 14px/17px Rubik, sans-serif",
                          marginBottom: window.innerWidth > 768 ? "" : "0",
                        }}
                      >
                        Your appointment is successfully scheduled with{" "}
                        <b>
                          {" "}
                          Dr.{" "}
                          {this.state?.doctor?.first_name +
                            " " +
                            this.state?.doctor?.last_name}
                          ,{" "}
                        </b>
                        <br />
                        on{" "}
                        {this.state.appointment ? (
                          <b>
                            {" "}
                            {this.state.todayDate} at {this.state.startTime}
                          </b>
                        ) : (
                          <b>
                            {" "}
                            {moment(this.state.date).format(
                              "DD MMM YYYY"
                            )} at {this.state.time}{" "}
                          </b>
                        )}{" "}
                        {!this.state.email === "" && (
                          <p>
                            You will shortly receive an email on your registered
                            ID with the details.
                          </p>
                        )}
                        <br />
                        <br />
                        {this.state.txnid ? (
                          <span style={{ color: "#F969A4" }}>
                            Transaction ID {this.state.txnid}
                          </span>
                        ) : null}
                      </p>
                    )}
                  </div>
                  <div>
                    <img
                      className="thankyou-profile-img"
                      style={{
                        width: window.innerWidth > 768 ? "" : "75px",
                        height: window.innerWidth > 768 ? "" : "75px",
                        marginBottom: window.innerWidth > 768 ? "" : "12px",
                        display:
                          window.innerWidth < 768 &&
                          !this.state?.doctor?.profile_picture_url
                            ? "none"
                            : "",
                      }}
                      src={
                        this.state.URL + this.state?.doctor?.profile_picture_url
                      }
                    />
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <section
              style={{
                width: window.innerWidth > 768 ? "65%" : "100%",
              }}
            >
              <div
                className="bg-white shadow-md p-4 text-center"
                style={{ borderRadius: 15 }}
              >
                <h4 style={{ fontWeight: "bold" }} className="mb-4">
                  {" "}
                  Processing your Booking...{" "}
                </h4>
                <b>Your booking is under process.</b>
                <br />
                <br />
                <p style={{ color: "#404760" }}>
                  {this.state.failure_msg} <br />
                  <br />
                  Please contact our Customer Happiness Team at{" "}
                  <b style={{ color: "#F969A4", padding: "0 5px 0 0" }}>
                    1800 267 9899
                  </b>
                  if you need help.
                </p>
              </div>
            </section>
          )}
          <div className="WidgetModalFooterLogo">
            Powered By <img alt="" src={c2cLogo} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    doctorDetails: state.auth.doctorDetails,
  };
};

export default connect(mapStateToProps)(Thankyou);
