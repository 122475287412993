import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    doctor_id: null,
    clinic_id: null,
    doctor_slug: null,
    doctorDetails: null,
  },
  reducers: {
    setAuthToken: (state, action) => {
      state.token = action.payload;
    },
    setDoctorId: (state, action) => {
      state.doctor_id = action.payload;
    },
    setClinicId: (state, action) => {
      state.clinic_id = action.payload;
    },
    setDoctorSlug: (state, action) => {
      state.doctor_slug = action.payload;
    },
    setDoctorDetails: (state, action) => {
      state.doctorDetails = action.payload;
    },
    setAuthSliceReset: (state) => {
      state.token = null;
      state.doctor_id = null;
      state.clinic_id = null;
      state.doctor_slug = null;
      state.doctorDetails = null;
    },
  },
});

export const {
  setAuthToken,
  setDoctorId,
  setDoctorDetails,
  setClinicId,
  setDoctorSlug,
  setAuthSliceReset,
} = authSlice.actions;

export default authSlice.reducer;
