import React, { useEffect, useState } from "react";
import LoginEnterPassword from "../Login/PatientLoginContainer/LoginEnterPassword";
import OtpLoginFullDoctor from "../Login/PatientLoginContainer/otploginFullDoctor";
import PatientOTPEnterScreen from "../Login/PatientLoginContainer/patientOTPEnterScreen";
import ResetPassword from "../Login/PatientLoginContainer/ResetPassword";
import SetPasswordFirstTime from "../Login/PatientLoginContainer/SetPassword";
import SignInEnterPhoneNumber from "../Login/PatientLoginContainer/SignInEnterPhoneNumber";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  STEPPER_DATA_PATIENT,
  STEPPER_ROUTE_DATA_PATIENT,
} from "../../Shared/constants";
import {
  setStepper,
  setAppointmentType,
} from "../../features/stepper/StepperSlice";

const LoginComponent = () => {
  const [showModal, setShowModal] = useState("");
  const appointmentType = useSelector((state) => state.stepper.appointmentType);
  const currentStepper = useSelector((state) => state.stepper.currentStepper);
  const token = useSelector((state) => state.auth.token);
  const authToken = sessionStorage.getItem("id_token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = STEPPER_DATA_PATIENT[appointmentType];
  const IsShowTeleConsult = useSelector(
    (state) => state.isTeleConsultSlice.IsTeleConsult
  );

  useEffect(() => {
    if (!authToken && showModal === "") {
      setShowModal("login");
    }
  }, []);

  useEffect(() => {
    if (authToken && !IsShowTeleConsult) {
      nextStepper();
    }
    if (authToken && IsShowTeleConsult) {
      dispatch(setAppointmentType(5));
      dispatch(setStepper(1));
      navigate("/patient/doctor/isVirtual/selectPatient");
    }
  }, [token]);

  const nextStepper = () => {
    data.length > currentStepper &&
      navigate(STEPPER_ROUTE_DATA_PATIENT[appointmentType][currentStepper]);
    // dispatch(setStepper(data.length > currentStepper ? currentStepper + 1 : currentStepper));
  };

  return (
    <>
      {showModal === "login" && (
        <SignInEnterPhoneNumber
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {showModal === "set-password" && (
        <SetPasswordFirstTime
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {showModal === "forgot-password" && (
        <ResetPassword showModal={showModal} setShowModal={setShowModal} />
      )}
      {showModal === "enter-password" && (
        <LoginEnterPassword showModal={showModal} setShowModal={setShowModal} />
      )}
      {showModal === "enter-otp" && (
        <PatientOTPEnterScreen
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {showModal === "otp-login" && (
        <OtpLoginFullDoctor showModal={showModal} setShowModal={setShowModal} />
      )}
    </>
  );
};

export default LoginComponent;
