import React from "react";
import SlotsContainer from "./index";

const BookSchedule = ({
  doctorId,
  setFollowUpEndDate,
  onDateSelect,
  onSlotSelect,
  setStartTime,
  className,
  slotsClass,
  hideAnnotation,
  consultType,
  followUpEndDate,
  setConsultData,
  followUpInfo,
  setCounter,
  bookFollowUp,
  fromCalender,
  calenderDate,
  track,
  selectedProcedure,
  procedureDetails,
  alternateNumber,
  doctor,
  apiConsultationType, //new add
  getSlotTime,
  handleSlotToEMpty,
  slugUrl,
  reschedule,
  handleInstruction,
  doctorSlug, //pasing doctor slug
  procedure_id, //passing procedure id for rescheduling
  type,
  selectedDateMeta,
  selectedClinic,
  handleDate,
  clinicId, // passing for patient side rescheduling
  procedures,
  procedureLoading,
}) => {
  return (
    <div className={"pro-calender " + className}>
      {hideAnnotation ? "" : <h2>BOOK APPOINTMENT</h2>}

      <SlotsContainer
        setCounter={setCounter}
        alternateNumber={alternateNumber}
        followUpInfo={followUpInfo}
        setConsultData={setConsultData}
        consultType={consultType}
        selectedDate={calenderDate}
        doctorId={doctorId}
        onSelect={onSlotSelect}
        setStartTime={setStartTime}
        className={slotsClass}
        bookFollowUp={bookFollowUp}
        selectedProcedure={selectedProcedure}
        procedureDetails={procedureDetails}
        doctor={doctor}
        slugUrl={slugUrl}
        reschedule={reschedule}
        apiConsultationType={apiConsultationType}
        getSlotTime={getSlotTime}
        handleSlotToEMpty={handleSlotToEMpty}
        handleInstruction={handleInstruction}
        doctorSlug={doctorSlug} //passing doctor slug for patient/doctor reschedule
        procedure_id={procedure_id} //passing procedure id for patient/doctor reschedule
        type={type}
        setFollowUpEndDate={setFollowUpEndDate}
        selectedClinic={selectedClinic}
        clinicID={clinicId} // passing for patient side rescheduling
        handleDate={handleDate}
        procedures={procedures}
        procedureLoading={procedureLoading}
      />
    </div>
  );
};

export default BookSchedule;
