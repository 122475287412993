// dependecies import ==>
import React from "react";
import { useSelector } from "react-redux";
// assets and scss import ==>
import "./bookAppointmentModal.scss";
// project file import ==>
import Stepper from "../Stepper";
// constant import ==>
import { STEPPER_DATA_PATIENT } from "../../Shared/constants";

const BookAppointmentModel = ({ children }) => {
  const appointmentType = useSelector((state) => state.stepper.appointmentType);

  return (
    <div className="book-appointment-modal-content">
      <div className="ba-modal-header">
        <h3 className="ba-modal-title">BOOK APPOINTMENT</h3>
      </div>
      <div className="ba-modal-body">
        <div className="ba-modal-banner">
          You can <b>book an online appointment</b> with the doctor by selecting
          the date and time of your preference below. In case you are looking
          for a{" "}
          <b>
            follow-up consultation, please book using the ‘Follow-up’ option on
            the right.
          </b>
        </div>
        <Stepper data={STEPPER_DATA_PATIENT[appointmentType]} />
        <div className="ba-modal-body-content-div">{children}</div>
      </div>
    </div>
  );
};

export default BookAppointmentModel;
