import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import "./customDropdown.css";
import ClinicIcon from "../../../asstes/images/clinic_icon.svg";

const CustomDropdown = ({
  options,
  placeholder,
  handleChange,
  handleBlur,
  customStyles,
  className,
  showSearch,
  isSearchable,
  noOptionsMessage,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const styles = {
    control: (base) => ({
      ...base,
      width: "100% !important",
      // background: " #F5F6F7 0% 0% no-repeat padding-box",
      background: "none",
      boxShadow: "none",
      border: "none",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "none",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      height: "40px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "@media only screen and (max-width: 767px)": {
        height: "36px",
      },
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "19px",
      borderRadius: "0px",
      // background:"red",
      width: "288px",
      marginLeft: "-48px",

      "@media only screen and (max-width: 767px)": {
        marginLeft: "-28px",
      },
    }),
    menuList: (provided) => ({
      ...provided,
      cursor: "pointer",
      maxHeight: "200px",
      paddingTop: "0px",
      "&::-webkit-scrollbar": {
        width: "10px",
        height: " 16px",
        display: "block !important",
        background: "none",
      },
      "&::-webkit-scrollbar-track": {
        background: "none",
        borderRadius: "50px",
      },
      "&::-webkit-scrollbar-thumb": {
        boxShadow: "inset 0 0 16px rgba(240, 232, 232, 1)",
        minHeight: "60px !important",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#888",
        border: "none",
      },
    }),
    indicatorsContainer: (base, data) => ({
      ...base,
      "@media only screen and (max-width: 767px)": {
        height:
          data?.selectProps?.value?.clinic_name == "My Clinic" ||
          data?.selectProps?.value?.clinic_locality === ""
            ? "100%"
            : "45%",
      },
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "black",
        fontWeight: "bold",
      };
    },
  };

  const formatGroupLabel = (data) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "14px",

        borderTop:
          data?.label === "PERSONAL CLINIC" ? "1px solid #D6DAE5" : "none",
        padding: "8px 8px 8px 0px",
      }}
    >
      <span className="ml-1">{data.label}</span>
    </div>
  );
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const Option = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className="mx-3 py-2"
          ref={innerRef}
          {...innerProps}
          style={{
            cursor: "pointer",
          }}
        >
          <div>
            <span style={{ fontSize: "1rem", textTransform: "capitalize" }}>
              {data?.clinic_name}
            </span>
          </div>

          <div style={{ color: "#A6ACB9" }}>
            <span
              className="my-1"
              style={{ fontSize: "12px", textTransform: "capitalize" }}
            >
              {data?.clinic_locality ? data?.clinic_locality : ""}
            </span>
          </div>
        </div>
      </div>
    );
  };
  const formatOptionLabel = (data, { context }) => {
    if (context === "value") {
      return (
        <>
          <div
            className="align-items-center justify-content-start"
            style={{
              height: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <span
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              {data?.clinic_name}
            </span>
            {/* <span className="ml-1"> - </span> */}
          </div>
          <div
            className="align-items-center justify-content-start"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <span
              style={{
                fontSize: "0.8rem",
                color: "#A6ACB9",
                textTransform: "capitalize",
              }}
            >
              {data?.clinic_locality ? data?.clinic_locality : ""}
            </span>
          </div>
        </>
      );
    }
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? (
            <i
              className="fa fa-caret-up"
              style={{ color: "black", fontSize: "18px", paddingTop: "2px" }}
            ></i>
          ) : (
            <i
              className="fa fa-caret-down"
              style={{ color: "black", fontSize: "18px", paddingTop: "2px" }}
            ></i>
          )}
        </components.DropdownIndicator>
      )
    );
  };
  return (
    <div
      className="d-flex w-100"
      style={{ minHeight: "60px !important", alignSelf: "center" }}
    >
      <img src={ClinicIcon} alt="clinicIcon" style={{ width: 22 }} />
      <Select
        className={`${className} selectContainer`}
        classNamePrefix="select"
        name="prescriptionSelector"
        formatOptionLabel={formatOptionLabel}
        styles={customStyles || styles}
        placeholder="Select Clinic"
        components={{
          Option,
          DropdownIndicator: DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        isSearchable={isSearchable}
        onChange={(opt) => {
          handleChange(opt);
        }}
        options={options}
        noOptionsMessage={() => noOptionsMessage || "No Result Found"}
        {...props}
        formatGroupLabel={formatGroupLabel}
      />
    </div>
  );
};

export default CustomDropdown;
