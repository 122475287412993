import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { styles, theme } from "../../../Shared/DropdownStyle";
import "./dateAndClinicDropdown.scss";
import moment from "moment";
import DateSelector from "../BookSchedule/DateSelector.component";
import ConsultationClinicIcon from "../../../asstes/images/consultation-clinic.svg";
import ConsultationClinicIconDisabled from "../../../asstes/images/consultation-clinic-disabled.svg";
import DropdownIcon from "../../../asstes/images/dropdown.svg";
import CalendarClinicIcon from "../../../asstes/images/calendar-clinic.svg";
import Calendar from "react-calendar";
import BookSchedule from "../BookSchedule/BookSchedule";
import { useSelector, useDispatch } from "react-redux";
import afterLoginApiAxios from "../../../Shared/afterLogin";
import {
  setTimeSlot,
  setDate,
} from "../../../features/selectTimeSlot/selectTimeSlotSlice";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import SelcetClinic from "../../SlectClinic/CustomDropdown/index";
import {
  setDoctorDetails,
  setDoctorId,
} from "../../../features/auth/authSlice";
import clinicImg from "../../../asstes/images/selectClinic.svg";
import { IsTeleConsult } from "../../../features/setIsTeleConsult/setIsTeleConsultSlice";
import { PreviousStepperValue } from "../../../features/setIsTeleConsult/setIsTeleConsultSlice";
import { STEPPER_ROUTE_DATA_PATIENT } from "../../../Shared/constants";
import {
  setStepper,
  setAppointmentType,
} from "../../../features/stepper/StepperSlice";
import CalenderIcon from "../../../asstes/images/calender_icon.png";
import DownArrowIcon from "../../../asstes/images/filled-down-arrow.png";
let options = [
  { id: 5, label: "In-Clinic Regular", value: "inclinic-regular" },
  { id: 6, label: "In-Clinic Follow-Up", value: "inclinic-follow-up" },
  { id: 0, label: "Tele-Consult Regular", value: "regular" },
  { id: 1, label: "Tele-Consult Follow-Up", value: "Follow-Up" },
];

const DateAndClinicDropdown = (props) => {
  const { doctor, setDoctor, setSloatNumber } = props;
  const [showCalendar, setShowCalendar] = useState(false);
  const [followUpEndDate, setFollowUpEndDate] = useState();
  const [proceduresValue, setProceduresValue] = useState({});

  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState({});
  const [calenderDate, setCalenderDate] = useState(new Date());
  const [fromCalender, setFromCalender] = useState(false);
  const [selectedClinic, setSelectedClinic] = useState();
  const [procedureLoading, setProcedureLoading] = useState(false);
  const [procedures, setProcedures] = useState([]);
  const slug = useSelector((state) => state?.auth?.doctor_slug);
  const [clinicList, setClinicList] = useState([]);
  const [consultation_type, setConsultationType] = useState(5);
  const [track, changeTrack] = useState(1);
  const [dateSelectEnable] = useState(true);
  const [procedureId, setProcedureId] = useState();
  const [selectedConsultationType, setSelectedConsultationType] = useState(0);
  const doctorId = useSelector((state) => state.auth.doctor_id);
  const params = useParams();
  const [instruction, setInstructions] = useState();
  // const [followUpBooking, setFollowUpBooking] = useState(false);
  const [showClinic] = useState(
    params?.bookingType === "doctor" && params?.isVirtual === "isClinic"
  );

  const clinic_id_from_redux = useSelector((state) => state.auth.clinic_id);
  const appointmentType = useSelector((state) => state.stepper.appointmentType);
  const currentStepper = useSelector((state) => state.stepper.currentStepper);
  const IsShowTeleConsult = useSelector(
    (state) => state.isTeleConsultSlice.IsTeleConsult
  );
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const selectDateHandler = (date) => {
    if (dateSelectEnable) {
      sessionStorage.setItem("date", moment(date.meta).format("YYYY-MM-DD"));
      setSelectedDate(date);
      dispatch(setDate(moment(date.meta).format("YYYY-MM-DD")));
      setCalenderDate(date.meta._d);
      dispatch(setTimeSlot(null));
      setSloatNumber(null);
    }
  };

  function ClinicData(value) {
    if (showClinic == true) {
      let payload = {
        doctor_id: value ? value : doctorId,
      };

      afterLoginApiAxios({
        method: "post",
        url: "/public/get-all-clinic-list-patient",

        headers: {
          authorization: sessionStorage.getItem("id_token"),
        },
        data: payload,
      })
        .then((response) => {
          if (response.data.response == 200) {
            let list = [];
            const clinicOptions = [
              {
                clinic_name: response?.data?.personal_clinic_details
                  .doctor_clinic_name
                  ? response?.data?.personal_clinic_details.doctor_clinic_name
                  : "My Clinic",
                clinic_locality:
                  response?.data?.personal_clinic_details
                    ?.doctor_clinic_address,
                doctor_id: sessionStorage.getItem("user_id"),
              },
            ];
            list = [
              {
                label: "OTHER CLINICS",
                options: response.data.clinc_list,
              },
              {
                label: "PERSONAL CLINIC",
                options: clinicOptions,
              },
            ];

            setClinicList(list);
          } else {
            if (response.data.message) {
              swal({
                title: "Error",
                text: response.data.message,
                type: "error",
                icon: "error",
                button: "OK",
              });
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }

  const updateProcedures = (data, regularFollowupList) => {
    const reformattedData = data?.map((data) => {
      return {
        label: data.procedure_name,
        id: data.id,
        value: data.id,
      };
    });
    const newProcedure = [];
    options.forEach((ele) => {
      let foundedElement = regularFollowupList.find(
        (obj) => obj.consultation_type == ele.id
      );
      if (foundedElement) {
        newProcedure.push(ele);
      }
    });
    let procedures = [...newProcedure, ...reformattedData];
    setProcedures(procedures);
    if (procedures?.length > 0) {
      handleProcedureChange(procedures[0]);
    } else {
      setProceduresValue({});
      setConsultationType("");
    }
  };

  function handleInstruction(data) {
    if (procedureId) {
      data.procedure_list.map((data) => {
        if (data.id == procedureId) {
          setInstructions(data.instruction);
        }
      });
    } else {
      data.regular_followup_list.map((data) => {
        if (data.consultation_type == consultation_type) {
          setInstructions(data.instruction);
        }
      });
    }
  }
  useEffect(() => {
    setProcedureLoading(true);
    afterLoginApiAxios
      .post("/public/get-doctor-details-by-slug-rev1", {
        doctor_url_short_code: slug,
        ...((appointmentType === 1 ||
          appointmentType === 3 ||
          appointmentType === 2) && {
          clinic_id:
            appointmentType === 3
              ? selectedClinic?.clinic_id
              : clinic_id_from_redux,
        }),
      })
      .then((response) => {
        if (response.data.statusCode == 200) {
          setProcedureLoading(false);
          handleInstruction(response.data, procedureId, consultation_type);
          sessionStorage.setItem(
            "doctor",
            JSON.stringify(response.data.doctor)
          );
          dispatch(setDoctorId(response?.data?.doctor?.doctor_id));
          dispatch(setDoctorDetails(response?.data?.doctor));
          setDoctor(response?.data?.doctor);
          showClinic && ClinicData(response?.data?.doctor?.doctor_id);
          updateProcedures(
            response?.data?.procedure_list,
            response?.data?.regular_followup_list
          );
        } else {
          setProcedureLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }, [selectedClinic?.clinic_id]);

  const handleDate = (date) => {
    setCalenderDate(date);
    setFromCalender(true);
    setShowCalendar(false);
    changeTrack(track + 1);
    dispatch(setTimeSlot(null));
    setSloatNumber(null);
  };

  const bookFollowup = () => {
    sessionStorage.removeItem("is_followup");
    // setFollowUpBooking(true);
    dispatch(PreviousStepperValue({ currentStepper, appointmentType }));
    dispatch(IsTeleConsult(true));

    if (token) {
      dispatch(setAppointmentType(5));
      dispatch(setStepper(1));
      navigate("/patient/doctor/isVirtual/selectPatient");
    } else {
      navigate(STEPPER_ROUTE_DATA_PATIENT[appointmentType][currentStepper + 1]);
    }

    if (sessionStorage.getItem("id_token")) {
      // bookAppointmentWithoutLogin(true);
      //  setFollowUpBooking(true);
    } else {
      //   history.replace(`/doctor/${slug}/login/`);
      //   setShowPatientLoginModal(true);
    }
  };

  const handleProcedureChange = (selectedOption) => {
    handleDate(new Date());
    if (
      selectedOption.value != "regular" &&
      selectedOption.value != "Follow-Up" &&
      selectedOption.value != "inclinic-regular" &&
      selectedOption.value != "inclinic-follow-up"
    ) {
      sessionStorage.setItem("procedure_consultation_type", "4");
      sessionStorage.setItem("procedure_id", selectedOption.id);
      setProcedureId(selectedOption.id);
      setSelectedConsultationType("");
      sessionStorage.removeItem("consultation-type");
      setProceduresValue(selectedOption);
      sessionStorage.removeItem("Tele_Consult_Follow_Up");
    } else {
      sessionStorage.removeItem("procedure_id");
      sessionStorage.setItem("procedure_consultation_type", selectedOption.id);
      setProcedureId("");

      setConsultationType(selectedOption?.id);
      setSelectedConsultationType(selectedOption.id);
      setProceduresValue(selectedOption);
      sessionStorage.setItem("consultation-type", selectedOption.id);
      if (selectedOption.value === "Follow-Up") {
        sessionStorage.setItem("Tele_Consult_Follow_Up", selectedOption.value);
      } else {
        sessionStorage.removeItem("Tele_Consult_Follow_Up");
      }
    }
    setProceduresValue(selectedOption);
    if (selectedOption.value == "Follow-Up") {
      bookFollowup();
    }
    dispatch(setTimeSlot(null));
  };

  const createInstructionUI = () => {
    return (
      <div className="instruction-view">
        <b>INSTRUCTIONS :</b> {instruction}
      </div>
    );
  };
  const handleOnChange = (clinic) => {
    !selectedClinic?.clinic_id && setInstructions("");
    handleDate(new Date());
    setProceduresValue({
      id: 5,
      label: "In-Clinic Regular",
      value: "inclinic-regular",
    });
    setConsultationType(procedures[0]?.id);
    sessionStorage.removeItem("procedure_id");
    sessionStorage.setItem("procedure_consultation_type", 5);
    if (clinic !== undefined && clinic !== null && clinic !== "") {
      setSelectedClinic(clinic);
      setProcedureId("");
      setProceduresValue(procedures[0]);
      if (
        clinic.clinic_id !== undefined &&
        clinic.clinic_id !== null &&
        clinic.clinic_id !== ""
      ) {
        sessionStorage.setItem("clinicId", clinic.clinic_id);
      } else {
        sessionStorage.setItem("clinicId", "");
      }
    }
  };
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? (
            <i
              className="fa fa-caret-up"
              style={{
                color: !props.isDisabled && "black",
                fontSize: "18px",
                paddingTop: "2px",
              }}
            ></i>
          ) : (
            <i
              className="fa fa-caret-down"
              style={{
                color: !props.isDisabled && "black",
                fontSize: "18px",
                paddingTop: "2px",
              }}
            ></i>
          )}
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <div className="date-and-clinic-dropdown">
      {IsShowTeleConsult && (
        <div className="tele-consult-header">
          {window.innerWidth < 768 && (
            <div className="float-right" style={{ marginBottom: "10px" }}>
              Valid till{" "}
              <b>
                {" "}
                {followUpEndDate &&
                  moment(new Date(followUpEndDate)).format("DD MMM, YYYY")}
              </b>{" "}
              | Patient Name <b>{sessionStorage.getItem("patient_name")}</b>
            </div>
          )}
          <div className="cal-width">
            <div
              className="booking-date d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => setShowCalendar(!showCalendar)}
            >
              <span className="week-day">
                {moment(calenderDate).format("dddd,")}
              </span>
              <span className="cal-date ms-1">
                {moment(calenderDate).format("DD MMM, YYYY")}
                <span className="ms-1">
                  <img src={CalenderIcon} alt="calender icon" />{" "}
                </span>
              </span>
              <span className="ms-1">
                <img src={DownArrowIcon} alt="calender arrow" />{" "}
              </span>
            </div>
            <div>
              {showCalendar && (
                <Calendar
                  onChange={handleDate}
                  value={calenderDate}
                  minDate={new Date()}
                  maxDate={moment(new Date(followUpEndDate))._d}
                />
              )}
            </div>
          </div>
          {window.innerWidth > 768 && (
            <div className="float-right">
              Valid till{" "}
              <b>
                {" "}
                {followUpEndDate &&
                  moment(new Date(followUpEndDate)).format("DD MMM, YYYY")}
              </b>{" "}
              | Patient Name <b>{sessionStorage.getItem("patient_name")}</b>
            </div>
          )}
        </div>
      )}
      {!IsShowTeleConsult && (
        <div
          className="date-clinic-dropdown-main-box"
          style={{ justifyContent: showClinic ? "space-between" : "normal" }}
        >
          {showClinic && (
            <div
              className="date-clinic-dropdown-main-box-sec-box"
              style={{ borderLeft: "0" }}
            >
              <div className="d-flex justify-content-center">
                <SelcetClinic
                  className="select_dropdownOptions"
                  showSearch={false}
                  defaultValue={selectedClinic}
                  value={selectedClinic}
                  handleChange={handleOnChange}
                  isSearchable={false}
                  noOptionsMessage={""}
                  placeholder={"Select Clinic"}
                  options={clinicList}
                />
              </div>
            </div>
          )}

          <div
            style={{
              width: !showClinic && window.innerWidth > 768 ? "50%" : "",
            }}
          >
            <div
              className="date-clinic-dropdown-main-box-sec-box"
              style={{ cursor: "pointer" }}
              onClick={() => setShowCalendar(!showCalendar)}
            >
              <span className="ml-1 ">
                <img
                  src={CalendarClinicIcon}
                  alt="calender icon"
                  style={{
                    color: "darkpink",
                    paddingRight: "10px",
                    paddingBottom: "2px",
                    paddingTop: "1px",
                  }}
                />
              </span>
              <div className="date-calendar-placeholder">
                {moment(calenderDate).format("dddd, DD MMM, YYYY")}
              </div>
              <span className="ml-1 ms-3 calendar-icon-dropdwon">
                <img src={DropdownIcon} alt="calender arrow" />
              </span>
            </div>
            {showCalendar && (
              <div
                style={{ width: "85", marginTop: "15px" }}
                className="d-flex justify-content-center pointer"
              >
                <Calendar
                  onChange={handleDate}
                  value={calenderDate}
                  minDate={new Date()}
                  maxDate={moment(new Date()).add(12, "months")._d}
                  className="calendar-view"
                />
              </div>
            )}
          </div>

          <div
            style={{
              width: !showClinic && window.innerWidth > 768 ? "50%" : "",
            }}
            className="date-clinic-dropdown-main-box-sec-box"
          >
            <div className="d-flex justify-content-center">
              <img
                src={
                  showClinic && !selectedClinic
                    ? ConsultationClinicIconDisabled
                    : ConsultationClinicIcon
                }
                style={{ width: 22 }}
                alt="clinicIcon"
              />
              <Select
                classNamePrefix="patient-side-procedure"
                name="patient-side-procedure"
                styles={styles}
                value={proceduresValue}
                isDisabled={showClinic && !selectedClinic ? true : false}
                onChange={handleProcedureChange}
                options={procedures}
                placeholder="Select Consultation"
                components={{
                  DropdownIndicator: DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                theme={theme}
                isSearchable={procedures?.length > 0}
              />
            </div>
          </div>
        </div>
      )}
      {showClinic ? (
        selectedClinic && (
          <>
            {createInstructionUI()}
            <DateSelector
              instruction={instruction}
              onSelect={selectDateHandler}
              selectedDate={selectedDate}
              fromCalender={fromCalender}
              calenderDate={calenderDate}
              track={track}
            />
          </>
        )
      ) : (
        <>
          {createInstructionUI()}
          <DateSelector
            onSelect={selectDateHandler}
            selectedDate={selectedDate}
            fromCalender={fromCalender}
            calenderDate={calenderDate}
            track={track}
          />
        </>
      )}
      {!IsShowTeleConsult &&
        (showClinic ? (
          selectedClinic ? (
            <BookSchedule
              consultType={consultation_type === 1 ? 0 : consultation_type}
              hideAnnotation
              track={track}
              fromCalender={fromCalender}
              calenderDate={calenderDate}
              clinicId={selectedClinic?.clinic_id}
              selectedProcedure={proceduresValue}
              doctorSlug={slug}
              setStartTime={(e) => {
                if (e) {
                  sessionStorage.setItem("time", e);
                }
              }}
              doctor={doctor}
              doctorId={doctorId}
              handleInstruction={handleInstruction}
              onSlotSelect={(e) => {
                if (e) {
                  dispatch(setTimeSlot(e));
                  setSloatNumber(e);
                  sessionStorage.setItem("slot_id", e);
                }
              }}
              handleDate={handleDate}
              procedures={procedures}
              procedureLoading={procedureLoading}
            />
          ) : (
            <div id="foto" style={{ textAlign: "center" }}>
              <img
                src={clinicImg}
                className="no-data-image"
                alt="calender icon"
              />
            </div>
          )
        ) : (
          <BookSchedule
            consultType={consultation_type === 1 ? 0 : consultation_type}
            hideAnnotation
            track={track}
            fromCalender={fromCalender}
            calenderDate={calenderDate}
            selectedProcedure={proceduresValue}
            clinicId={clinic_id_from_redux}
            doctorSlug={slug}
            setStartTime={(e) => {
              if (e) {
                sessionStorage.setItem("time", e);
              }
            }}
            doctor={doctor}
            doctorId={doctorId}
            onSlotSelect={(e) => {
              if (e) {
                dispatch(setTimeSlot(e));
                setSloatNumber(e);
                sessionStorage.setItem("slot_id", e);
              }
            }}
            handleDate={handleDate}
            handleInstruction={handleInstruction}
            procedures={procedures}
            procedureLoading={procedureLoading}
          />
        ))}

      {IsShowTeleConsult && (
        <BookSchedule
          hideAnnotation
          track={track}
          fromCalender={fromCalender}
          calenderDate={calenderDate}
          selectedProcedure={proceduresValue}
          clinicId={clinic_id_from_redux}
          doctorSlug={slug}
          setStartTime={(e) => {
            if (e) {
              sessionStorage.setItem("time", e);
            }
          }}
          handleDate={handleDate}
          doctor={doctor}
          doctorId={doctorId}
          onSlotSelect={(e) => {
            if (e) {
              dispatch(setTimeSlot(e));
              setSloatNumber(e);
              sessionStorage.setItem("slot_id", e);
            }
          }}
          //calenderDate={calenderDate}
          // setConsultData={setConsultData}
          setFollowUpEndDate={setFollowUpEndDate}
          followUpEndDate={followUpEndDate}
          consultType={"1"}
          //    setStartTime={setStartTime}
          //   doctorId={doctor?.doctor_id}
          onDateSelect={(e) => {
            setCalenderDate(e.meta._d);
            setSelectedDate(e);
          }}
          //   onSlotSelect={setSelectedSlot}
          //   setCounter={setCounter}
          selectedDateMeta={selectedDate}
          procedures={procedures}
          procedureLoading={procedureLoading}
        />
      )}
    </div>
  );
};

export default DateAndClinicDropdown;
