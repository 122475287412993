import React, { Component } from "react";
import MultipleFileSelector from "../../../ReusableComponents/MultipleImageSelector/MultipleFileSelector";
import { SESSIONSTORAGEKEY } from "../../../Shared/Helper/sessionStorageUtils";

class CaregiverFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientageProof: "",
      disabled: false,
      patientAdult: false,
    };
  }

  caregiverNameExists = sessionStorage.getItem(
    SESSIONSTORAGEKEY.CAREGIVER_NAME
  );
  caregivergovtIdExists = sessionStorage.getItem(
    SESSIONSTORAGEKEY.CAREGIVER_GOVT_ID
  );

  callback = () => {
    this.props.callbackFromParent(this.state.patientageProof);
  };

  is_minor_patient = () => {
    let today = new Date();
    let birthDate = new Date(this.props.dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  render() {
    const {
      caregiverName,
      caregiverEmail,
      caregivergovtID,
      onChange,
      onBlur,
      formErrors,
      patientPhone,
      epatientPhone,
      registered_for_self,
      isNew,
      removeMandatoryBasedOnConsultationType,
    } = this.props;

    let is_patient_minor = this.is_minor_patient();
    let patientAdult = is_patient_minor > 18 ? true : false;
    const caregiverLabel =
      registered_for_self == 1
        ? " CAREGIVER'S"
        : registered_for_self == 2
        ? "HCP'S"
        : null;
    //  const disabled = this.state.disabled;
    const imageSelectordisabled =
      this.state.patientageProof.length > 0 ? true : false;

    return (
      <div className="mobile-box-related">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="row" style={{ color: "grey" }}>
              <div className="col-lg-12">
                <div
                  className="box-title"
                  style={{
                    fontSize: 14,
                    marginBottom: 14,
                    color: "black",
                    textTransform: "uppercase",
                    fontWeight: 700,
                    marginLeft: "-15px",
                  }}
                >
                  Additional Details
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-6">
                <div className="form-group">
                  <label>
                    {`${caregiverLabel}`} Name
                    {!removeMandatoryBasedOnConsultationType && (
                      <span className="color-pink">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="caregiverName"
                    value={
                      registered_for_self == "1"
                        ? caregiverName
                        : registered_for_self == "2"
                        ? caregiverName
                        : ""
                    }
                    onChange={onChange("caregiverName")}
                    onBlur={onBlur}
                    disabled={
                      isNew == "false" && this.caregiverNameExists
                        ? true
                        : false
                    }
                  />{" "}
                  <span style={{ color: "#F969A4" }}>
                    {formErrors["caregiverName"]}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="form-group">
                  <label>PATIENT'S PHONE NUMBER</label>
                  {/* <span className="color-pink">(Optional)</span> */}
                  <input
                    type="text"
                    name="patientPhone"
                    onKeyDown={(e) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 190 ||
                        e.keyCode === 189) &&
                      e.preventDefault()
                    }
                    className="form-control"
                    value={
                      registered_for_self == "1"
                        ? patientPhone
                        : registered_for_self == "2"
                        ? epatientPhone
                        : ""
                    }
                    onChange={onChange("patientPhone")}
                    onBlur={onBlur}
                    disabled={isNew == "false" ? true : false}
                    // disabled={!!epatientPhone}
                  />
                  <span style={{ color: "#F969A4" }}>
                    {formErrors["patientPhone"]}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-6">
                <div className="form-group">
                  <label>
                    {`${caregiverLabel}`} EMAIL
                    {/* {patientAdult ? (
                      <span className="color-pink">*</span>
                    ) : (
                      <span className="color-pink">*</span>
                    )} */}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="caregiverEmail"
                    value={
                      registered_for_self == "1"
                        ? caregiverEmail
                        : registered_for_self == "2"
                        ? caregiverEmail
                        : ""
                    }
                    onChange={onChange("caregiverEmail")}
                    onBlur={onBlur}
                    disabled={isNew == "false" ? true : false}
                  />{" "}
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="form-group">
                  <label>
                    {`${caregiverLabel}`} GOVT ID
                    {patientAdult || removeMandatoryBasedOnConsultationType ? (
                      ""
                    ) : (
                      <span className="color-pink">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="caregivergovtID"
                    value={
                      registered_for_self == "1"
                        ? caregivergovtID
                        : registered_for_self == "2"
                        ? caregivergovtID
                        : ""
                    }
                    onKeyDown={(e) =>
                      (e.keyCode === 95 || e.keyCode === 189) &&
                      e.preventDefault()
                    }
                    onChange={onChange("caregivergovtID")}
                    onBlur={onBlur}
                    disabled={
                      isNew == "false" && this.caregivergovtIdExists
                        ? true
                        : false
                    }
                  />
                  <span style={{ color: "#F969A4" }}>
                    {patientAdult ? "" : formErrors["caregivergovtID"]}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-6">
                {patientAdult ? null : (
                  <div className="text-left">
                    <label>
                      {`${caregiverLabel}`} AGE PROOF
                      {patientAdult ||
                      removeMandatoryBasedOnConsultationType ? (
                        ""
                      ) : (
                        <span className="color-pink">*</span>
                      )}
                    </label>
                    {patientAdult ? (
                      <div>Age Proof Submitted</div>
                    ) : (
                      <>
                        <MultipleFileSelector
                          chooseFileText="Choose File"
                          showWarning
                          disabled={imageSelectordisabled}
                          single={true}
                          selectedImages={(imageArray) => {
                            this.setState(
                              {
                                patientageProof: imageArray,
                              },
                              this.callback
                            );
                          }}
                        />
                        <span style={{ color: "#F969A4" }}>
                          {patientAdult ? "" : formErrors["caregiverageProof"]}
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CaregiverFields;
