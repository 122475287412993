import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./globalStyles/doctorProfileStyle.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RouteComponent from "./routes/RouteComponent";
import LoginComponent from "./Components/LoginComponent/LoginComponent";
import Thankyou from "./Components/ThankyouPage/ThankyouPage";
import Failed from "./Components/FailedPaymentPage/FailedPaymentPage";
import RequestTimedOut from "./Components/RequestTimedOut/RequestTimedOut";
import { useWillMount } from "./hooks/useWillMount";
import { setAuthSliceReset } from "./features/auth/authSlice";
import { setPhoneNumber } from "./features/setNumber/phoneNumberSlice";
import { useDispatch } from "react-redux";
import { resetPatientDetials } from "./features/user/userSlice";
import ReviewPage from "./Components/ReviewPage/ReviewPage";
import { IsTeleConsult } from "./features/setIsTeleConsult/setIsTeleConsultSlice";
const App = () => {
  const dispatch = useDispatch();

  useWillMount(() => {
    dispatch(setAuthSliceReset());
    dispatch(resetPatientDetials());
    dispatch(setPhoneNumber(""));
    dispatch(IsTeleConsult(false));
    sessionStorage.clear();
  });

  return (
    <Router>
      <Routes>
        <Route
          path="/:type/:bookingType/:isVirtual/*"
          element={<RouteComponent />}
        />
        <Route path="/patientLogin" element={<LoginComponent />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/failed" element={<Failed />} />
        <Route path="/requesttimedout" element={<RequestTimedOut />} />
        <Route path="/reviewpage" element={<ReviewPage />} />
        <Route path="*" element={<>Page Not Found.</>} />
      </Routes>
    </Router>
  );
};

export default App;
