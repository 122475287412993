import React, { useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import { Modal } from "react-bootstrap";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setAuthToken } from "../../../features/auth/authSlice";
import swal from "sweetalert";
import afterLoginApiAxios from "../../../Shared/beforeLogin";
import "./styles.css";
import "./login.scss";
import C2CLogo from "../../../asstes/images/C2C-Logo.png";

function SetPasswordFirstTime({ showModal, setShowModal }) {
  const phoneNumber = useSelector((state) => state.selectNumber.number);
  const dispatch = useDispatch();

  let location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const handleContinueClick = (e) => {
    e.preventDefault();
    setPasswordError(false);

    if (passwordInput.length <= 5) {
      setPasswordError("Password must be at least 6 characters");
      return;
    }

    afterLoginApiAxios
      .post("patient/set-password", {
        id_token: sessionStorage.getItem("id_token"),
        new_password: passwordInput,
        headers: {
          Authorization: sessionStorage.getItem("id_token"),
        },
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            if (
              location &&
              location.state &&
              location.state.redirectTo == false
            ) {
              return;
            }
            dispatch(setAuthToken(sessionStorage.getItem("id_token")));
          } else {
            setPasswordError("Something Went Wrong!");
          }
        }
      })
      .catch(function (error) {});
  };

  if (phoneNumber == "") {
    return setShowModal("");
  }

  return (
    <div className="login-container">
      <div className="widgetModalDiv widgetModalBg">
        <div className="WidgetModalContentDiv">
          <div className="w-100 d-flex align-items-center justify-content-center">
            <ArrowBackIcon
              onClick={() => {}}
              style={{
                position: "relative",
                right: window.innerWidth > 768 ? "100px" : "56px",
              }}
              className="color-blue cursor-pointer"
            />
            <span className="login-container-title">Set Password</span>
          </div>
          <span className="login-container-subtitle">
            Set password for easier access (minimum 6 characters required)
          </span>
          <div
            className={
              "w-100 d-flex flex-row justify-content-between " +
              (window.innerWidth > 768 ? "mt-3" : "")
            }
          >
            <div className="d-inline-block login-muted-text">
              +91-{phoneNumber}
            </div>
            <Edit setShowModal={setShowModal} />
          </div>
          <div
            className={"w-100 " + (window.innerWidth > 768 ? "mt-2" : "mt-3")}
          >
            <form
              className="d-flex flex-column align-items-center justify-content-center"
              onSubmit={(e) => {
                handleContinueClick(e);
              }}
            >
              <FormControl
                hiddenLabel={true}
                size="small"
                margin="dense"
                fullWidth={true}
              >
                <FilledInput
                  id="pwd-input"
                  aria-describedby="my-helper-text"
                  aria-label="Set-password"
                  placeholder="Enter Password"
                  disableUnderline={true}
                  required
                  aria-required
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <ShowPassword
                      setShowPassword={setShowPassword}
                      showPassword={showPassword}
                    />
                  }
                  value={passwordInput}
                  onChange={(e) => {
                    setPasswordInput(e.target.value);
                  }}
                />
              </FormControl>
              <div
                className={
                  "input-error text-center " +
                  (window.innerWidth > 768 ? "mt-1" : "")
                }
              >
                {passwordError ? passwordError : ""}
              </div>
              <button
                className="btn primary-cta-button"
                style={{ marginTop: window.innerWidth > 768 ? "" : "30px" }}
              >
                Submit
              </button>
            </form>
          </div>
          <div
            className="mt-3 textLink font-weight-bold"
            style={{ minWidth: "fit-content" }}
          >
            <div
              onClick={() => {
                dispatch(setAuthToken(sessionStorage.getItem("id_token")));
              }}
            >
              Skip for Now
            </div>
          </div>
        </div>

        <div className="WidgetModalFooterLogo">
          Powered By <img alt="" src={C2CLogo} />
        </div>
      </div>
    </div>
  );
}

export default SetPasswordFirstTime;

const Edit = ({ setShowModal }) => {
  return (
    <span
      className="login-primary-text cursor-pointer"
      onClick={() => {
        setShowModal("login");
      }}
    >
      Edit
      <EditIcon className="color-blue cursor-pointer" fontSize="inherit" />
    </span>
  );
};

function ShowPassword(props) {
  const { setShowPassword, showPassword = false } = props;

  if (showPassword === false) {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityIcon />
      </span>
    );
  } else {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityOffIcon />
      </span>
    );
  }
}
