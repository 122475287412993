import moment from "moment/moment";
import React, { Component } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import "./patientDetails.scss";
import swal from "sweetalert";
import DatePickerNew from "../../ReusableComponents/DatePickerNew/DatePickerNew";
import { Modal } from "react-bootstrap";
import MultipleImageSelector from "../../ReusableComponents/MultipleImageSelector/MultipleFileSelector";
import afterLoginApiAxios from "../../Shared/afterLogin";
import { getSignedUrl } from "../../Shared/SignedAPIUrl";
import CaregiverFields from "./InputFields/CaregiverFields";
import { withRouter } from "../../Shared/withRouter";
import { getPathNameHelper } from "../../Shared/Helper";
import C2CLogo from "../../asstes/images/C2C-Logo.png";
import LoaderLogo from "../../asstes/images/loader.svg";
import CheckedIcon from "../../asstes/images/ok_icon.svg";
import personBlueIcon from "../../asstes/images/person-24px.svg";
import addCircleIcon from "../../asstes/images/plus-icon.svg";
import { setStepper } from "../../features/stepper/StepperSlice";
import { connect } from "react-redux";
import { PIN_CODE_VALIDATION } from "./ApiHandler/apiConstant";
import { getPinCodeValidation } from "./ApiHandler/apiIndex";
const getPathName = (path) => {
  return path.slice(0, path.lastIndexOf("/"));
};

const customStyles = {
  control: (base) => ({
    ...base,
    width: "100%",
    background: " #F5F6F7 0% 0% no-repeat padding-box",
    border: "1px solid #DBDBDB",
    borderRadius: "5px",
    height: "45px",
  }),
  container: (base) => ({
    ...base,
    width: "100%",
  }),
};

const formValid = (formErrors) => {
  let valid = true;
  Object.values(formErrors).forEach((val) => {
    if (!!val) {
      val.length > 0 && (valid = false);
    }
  });
  return valid;
};
const onlyDigitRegex = RegExp(/^[6789]\d{9}$/);
const emailRegex = RegExp(/[\w-]+@([\w-]+\.)+[\w-]+/);
const onlyAlphaRegex = RegExp(/^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/);
const onlyAlphaNumeric = RegExp(/ ^[a-zA-Z0-9]+$/);
const pincodeRegex = RegExp(/^[1-9][0-9]{5}$/);
const addressRegex = RegExp(/^[^-\s][a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_\s-]+$/);
class PatientDetails extends Component {
  constructor(props, context) {
    super(props, context);
    var type = null;
    //   var type = this.props.router.location.match.params.doctorType;
    var phone = sessionStorage.getItem("user_name");
    this.state = {
      isFormValid: false,
      loader: false,
      typeIconClass_someone: "colorGrey",
      typeIconClass_you: "colorTypeIcon",
      typeIconClass_someone_border: "btnBorderGray",
      typeIconClass_you_border: "btnBorderBlue",
      patientType:
        sessionStorage.getItem("registration_type") === "0"
          ? "patient"
          : "caregiver",
      drugDiv: "",
      habitDiv: "",
      medicationDiv: "",
      new_user: "",
      new_account: "",
      sid: sessionStorage.getItem("slot_id"),
      start: "",
      date: sessionStorage.getItem("date"),
      doctor: this.props.doctorDetails,
      name: sessionStorage.getItem("patient_name") || "",
      sex: sessionStorage.getItem("patient_gender") || "Male",
      email: sessionStorage.getItem("patient_email") || "",
      registered_for_self: sessionStorage.getItem("registration_type") || "0",
      caregiverEmail: sessionStorage.getItem("caregiver_email") || "",
      caregiverName: sessionStorage.getItem("caregiver_name") || "",
      caregivergovtID: sessionStorage.getItem("caregiver_govt_id") || "",
      selectedPatient: sessionStorage.getItem("selectedPatient") || null,
      meetingId: sessionStorage.getItem("meeting_id") || null,
      consultationType: sessionStorage.getItem("consultation-type"),
      patient: "",
      age: "",
      dob: new Date(),
      dateofbirth: "",
      patient_dob: "",
      is_follow_up: "",
      followup: 0,

      city: "",
      drug_allergy: "",
      habits: [],
      medication: "",
      is_taking_medicine: 0,
      caregiver: false,
      terms: false,
      phone_number: phone,
      phone: phone,
      otp: "",

      purpose: "",
      prev_prescription: [],
      file: [],
      fileFlag: true,
      message: "",
      any_drug_allergy: 0,
      any_habit: 0,
      acceptFlag: false, //flag chnaged to hide notification modal

      docType: type,
      prevPatient: null,
      patientPhone: "",

      caregiverageProof: [],

      ecaregiverName: null,
      ecaregiverEmail: null,
      ecaregiverageProof: [],
      ecaregivergovtID: null,
      epatientPhone: null,
      address: "",
      pincode: "",

      selectState: [
        { label: "Andhra Pradesh", value: 37 },
        { label: "Arunachal Pradesh", value: 12 },
        { label: "Assam", value: 18 },
        { label: "Bihar", value: 10 },
        { label: "Chattisgarh", value: 22 },
        { label: "Delhi", value: 7 },
        { label: "Goa", value: 30 },
        { label: "Gujarat", value: 24 },
        { label: "Haryana", value: 6 },
        { label: "Himachal Pradesh", value: 2 },
        { label: "Jammu and Kashmir", value: 1 },
        { label: "Jharkhand", value: 20 },
        { label: "Karnataka", value: 29 },
        { label: "Kerala", value: 32 },
        { label: "Lakshadweep Islands", value: 31 },
        { label: "Madhya Pradesh", value: 23 },
        { label: "Maharashtra", value: 27 },
        { label: "Manipur", value: 14 },
        { label: "Meghalaya", value: 17 },
        { label: "Mizoram", value: 15 },
        { label: "Nagaland", value: 13 },
        { label: "Odisha", value: 21 },
        { label: "Pondicherry", value: 34 },
        { label: "Punjab", value: 3 },
        { label: "Rajasthan", value: 8 },
        { label: "Sikkim", value: 11 },
        { label: "Tamil Nadu", value: 33 },
        { label: "Telangana", value: 36 },
        { label: "Tripura", value: 16 },
        { label: "Uttar Pradesh", value: 9 },
        { label: "Uttarakhand", value: 5 },
        { label: "West Bengal", value: 19 },
        { label: "Andaman And Nicobar Islands", value: 35 },
        { label: "Chandigarh", value: 4 },
        { label: "Dadra and Nagar Haveli", value: 26 },
        { label: "Daman and Diu", value: 25 },
        { label: "Ladakh", value: 38 },
      ],
      stateCityData: null,
      stateIndex: 0,
      formErrors: {
        name: "",
        dob: "",
        gender: "",
        city: "",
        drug_allergy: "",
        habits: "",
        medication: "",
        phone_number: "",
        otp: "",
        email: "",
        purpose: "",
        file_msg: "",
        caregiver: "",
        caregiverEmail: "",
        patientPhone: "",
        caregiverName: "",
        caregiverageProof: "",
        caregivergovtID: "",
        address: "",
        pincode: "",
        stateCityData: "",
      },
      validation_msg: "",
      invalid_dob: false,
      invalid_day: false,
      invalid_month: false,
      flag_appointment_id_instant: "",
      dateError: false,
      UserDOB: "",
      removeMandatoryBasedOnConsultationType: false,
      isPurposeAddressMandatory: true,
      dobDate: {},
    };
    this.handleChange.bind(this);
    this.handleChangeDate.bind(this);
    this.onSubmit.bind(this);
    this.onOtpSend.bind(this);
    this.handleOtp.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.fileCheck = this.fileCheck.bind(this);
    this.handlePatientType = this.handlePatientType.bind(this);
    this.acceptDiv = this.acceptDiv.bind(this);
    this.temp_file_arr = [];
    this.logOut = this.logOut.bind(this);
    this.getUserDOB = this.getUserDOB.bind(this);
    this.openCheckout = this.openCheckout.bind(this);
  }

  componentWillUnmount() {
    sessionStorage.removeItem("ecaregiverName");
    sessionStorage.removeItem("ecaregiverEmail");
    sessionStorage.removeItem("ecaregivergovtID");
    sessionStorage.removeItem("epatientPhone");
    //   enableHelpButton();
    sessionStorage.setItem("flag_appointment_id", "");
    document.body.style.overflow = "";
  }

  componentWillMount() {
    document.body.style.overflow = "";
    this.setState({ ecaregiverName: sessionStorage.getItem("ecaregiverName") });
    this.setState({
      ecaregiverEmail: sessionStorage.getItem("ecaregiverEmail"),
    });
    this.setState({
      ecaregivergovtID: sessionStorage.getItem("ecaregivergovtID"),
      epatientPhone: sessionStorage.getItem("epatientPhone"),
    });

    document.body.style.overflow = "";
    sessionStorage.setItem("flag_appointment_id", "");
    var sid = sessionStorage.getItem("schedule_sid");
    var start = sessionStorage.getItem("schedule_start");
    var date = sessionStorage.getItem("schedule_date");
    var new_user = sessionStorage.getItem("schedule_new_user");
    var new_account = sessionStorage.getItem("existing_user_book_new_account");
    var patient = sessionStorage.getItem("existing_user_book_patient");
    var patient_dob = sessionStorage.getItem("patient_dob");
    var is_follow_up = sessionStorage.getItem(
      "existing_user_book_is_follow_up"
    );

    sessionStorage.removeItem("paymentID");
    sessionStorage.removeItem("orderID");
    sessionStorage.removeItem("signature");
    sessionStorage.removeItem("amount");
    sessionStorage.removeItem("convenience_fee");
    sessionStorage.removeItem("gst_on_convenience_fee");

    var reg_type = sessionStorage.getItem("patient_reg_type");

    var name = sessionStorage.getItem("patient_name");
    var email = sessionStorage.getItem("patient_email") || "";
    var gender = sessionStorage.getItem("patient_gender");
    var dob = sessionStorage.getItem("patient_dob");
    var patient_address = sessionStorage.getItem("patient_address");
    var patient_pincode = sessionStorage.getItem("patient_pincode");
    var state_name = sessionStorage.getItem("state_name");
    var state_code = Number(sessionStorage.getItem("state_code"));
    var patient_is_drug_allergy_present =
      sessionStorage.getItem("patient_is_drug_allergy_present") === "True"
        ? 1
        : 0;
    var patient_is_habit_present =
      sessionStorage.getItem("patient_is_habit_present") === "True" ? 1 : 0;
    var is_taking_medicine =
      sessionStorage.getItem("is_taking_medicine") === "True" ? 1 : 0;
    var medication = sessionStorage.getItem("medication")
      ? sessionStorage.getItem("medication")
      : "";
    var patient_drug_allergy = sessionStorage.getItem("patient_drug_allergy")
      ? sessionStorage.getItem("patient_drug_allergy")
      : "";
    var patient_habits = sessionStorage.getItem("patient_habits");
    var stateIndexres = this.state.selectState.findIndex((c) =>
      state_code
        ? c.value === state_code
        : c.label?.toLowerCase() === state_name?.toLowerCase()
    );
    var tempDate = new Date();
    var yr = tempDate.getFullYear();
    let formErrors = this.state.formErrors;

    if (sessionStorage.getItem("patient_dob")) {
      if (yr - Number(patient_dob.substring(0, 4)) < 18) {
        formErrors.dob =
          "Only a caregiver can book an appointment on behalf of the minor9";
        this.setState({
          formErrors: formErrors,
        });
      }
    }

    if (reg_type && reg_type !== "undefined") {
      this.setState({
        registered_for_self: reg_type,
        prevPatient: reg_type,
      });
    }

    if (patient_is_drug_allergy_present) {
      this.setState({ drugDiv: "true" });
    }

    if (patient_is_habit_present) {
      this.setState({ habitDiv: "true" });
    }

    if (is_taking_medicine) {
      this.setState({ medicationDiv: "true" });
    }

    var gen = "";
    if (gender) {
      gen = gender;
    } else {
      gen = "Male";
    }

    this.setState(
      {
        sid: sid,
        start: start,
        date: date,
        new_account: new_account,
        patient: patient,
        name: name,
        email: email,
        patient_dob: patient_dob,
        dob: dob,
        gender: gen,
        is_follow_up: is_follow_up,
        new_user: new_user,
        purpose: "",
        any_drug_allergy: patient_is_drug_allergy_present,
        any_habit: patient_is_habit_present,
        is_taking_medicine: is_taking_medicine,
        drug_allergy: patient_drug_allergy,
        habits: sessionStorage.getItem("patient_habits")
          ? patient_habits.split(",")
          : "",
        medication: medication,
        address: patient_address,
        pincode: patient_pincode,
        state_name: state_name,
        stateCityData:
          stateIndexres === -1
            ? null
            : {
                state_name: state_name,
                state_code: state_code,
              },
        isPurposeAddressMandatory: !["4", "5", "6"].includes(
          sessionStorage.getItem("consultation-type")
        ),
      },
      () => {}
    );
  }

  updateInvalidDobError = () => {
    let invalid =
      this.state.invalid_day || this.state.invalid_month ? true : false;
    let msg = invalid ? "Invalid Date" : "";
    this.setState({
      invalid_dob: invalid,
      validation_msg: msg,
    });
  };

  validateDate = (date) => {
    if (date.target.name === "month") {
      let invalid = date.target.value < 0 || date.target.value > 12;
      this.setState({ invalid_month: invalid });
    } else if (date.target.name === "day") {
      let invalid = date.target.value < 0 || date.target.value > 31;
      this.setState({ invalid_day: invalid });
    }
    this.updateInvalidDobError();
  };

  handlePatientType(type) {
    let formErrors = this.state.formErrors;

    if (type === "you") {
      formErrors.caregiver = "";

      this.setState({
        patientType: "patient",
        typeIconClass_you: "colorTypeIcon",
        typeIconClass_someone: "colorGrey",
        typeIconClass_someone_border: "btnBorderGray",
        typeIconClass_you_border: "btnBorderBlue",
        registered_for_self: "0",
        removeMandatoryBasedOnConsultationType: false,
      });
    } else {
      formErrors.caregiver = "Please select caregiver";
      formErrors.dob = "";
      formErrors.address = "";
      formErrors.purpose = "";
      this.setState({
        patientType: "caregiver",
        typeIconClass_you: "colorGrey",
        typeIconClass_someone: "colorTypeIcon",
        typeIconClass_someone_border: "btnBorderBlue",
        typeIconClass_you_border: "btnBorderGray",
        registered_for_self: "9",
        formErrors: formErrors,
        removeMandatoryBasedOnConsultationType: ["4", "5", "6"].includes(
          sessionStorage.getItem("consultation-type")
        ),
      });
    }

    this.setState({
      formErrors: formErrors,
    });
  }

  fileCheckUpload = async (files) => {
    let prec = [];
    for (const file of files) {
      let signedUrl = await getSignedUrl(
        {
          file_size: file.size,
          doc_title: file.name,
          user_type: sessionStorage.getItem("user_type"),
          use_case: "book-appointment-add-details_rev1",
        },
        file.fileDate
      );
      if (signedUrl) {
        prec.push({
          value: signedUrl.fields["key"],
          name: file.name,
        });
      }
    }
    if (prec.length === files.length) return prec;
  };

  getFiles(files) {
    this.temp_file_arr = [];
    this.setState({ file: files });

    for (var i = 0; i < this.state.file.length; i++) {
      this.fileCheck(this.state.file[i].name, i);
    }
  }
  fileCheck(obj, index) {
    let formErrors = this.state.formErrors;
    var filePath = obj;
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.svg|\.pdf|\.doc)$/i;
    if (!allowedExtensions.exec(filePath)) {
      alert(
        "Please upload file having extensions .jpeg/.jpg/.png/.gif/.svg/.doc only."
      );
      formErrors.file_msg = "Invalid File Type";
      this.setState(
        {
          message: "Invalid File Type.",
          fileFlag: false,
          formErrors: formErrors,
          file: "",
        },
        () => {}
      );
    } else {
      formErrors.file_msg = "";
      var test_arr = {};
      test_arr.name = this.state.file[index].name;
      test_arr.value = this.state.file[index].base64;
      this.temp_file_arr.push(test_arr);
      this.setState(
        {
          message: "",
          fileFlag: true,
          prev_prescription: this.temp_file_arr,
          formErrors: formErrors,
        },
        () => {}
      );
    }
  }

  getUserDOB = (date, errorStatus) => {
    this.setState({ dobDate: date });
    if (
      date.year &&
      date?.year?.toString()?.length === 4 &&
      date.month &&
      date.day
    ) {
      const newDate = new Date(Date.UTC(date.year, date.month - 1, date.day));
      this.handleChangeDate(newDate);
      this.setState({ dateError: errorStatus });
    } else {
      let formErrors = this.state.formErrors;
      if (!date.year && !date.month && !date.day) {
        formErrors.dob = "DOB is mandatory";
        this.setState({
          formErrors: formErrors,
        });
        this.setState({ dateError: true });
      } else {
        formErrors.dob = "";
        this.setState({
          formErrors: formErrors,
        });
        this.setState({ dateError: true });
      }
    }
  };

  selectCaregiver = () => (event) => {
    const { value } = event.target;

    if (value == "1") {
      let formErrors = this.state.formErrors;
      formErrors.caregiverEmail = "";
      formErrors.caregiverName = "";
      formErrors.caregivergovtID = "";
      formErrors.caregiver_govt_id = "";
      formErrors.patientPhone = "";
      formErrors.ecaregiverageProof = "";
      formErrors.caregiver = "";
      formErrors.dob = "";
      this.setState({
        registered_for_self: value,
        formErrors: formErrors,
        validation_msg: "",
      });
    } else if (value == "2") {
      let formErrors = this.state.formErrors;
      formErrors.caregiverEmail = "";
      formErrors.caregiverName = "";
      formErrors.caregivergovtID = "";
      formErrors.caregiver_govt_id = "";
      formErrors.patientPhone = "";
      formErrors.ecaregiverageProof = "";
      formErrors.caregiver = "";
      formErrors.dob = "";
      this.setState({
        registered_for_self: value,
        formErrors: formErrors,
        validation_msg: "",
      });
    } else if (value == "9") {
      let formErrors = this.state.formErrors;
      formErrors.caregiver = "Please select a caregiver";
      formErrors.dob = "";

      this.setState({
        formErrors: formErrors,
        registered_for_self: value,
      });
    }
  };

  logOut() {
    afterLoginApiAxios
      .post(
        "auth/user-logout",
        {
          user_token: `${sessionStorage.getItem("id_token")}`,
        },
        {
          headers: {
            Authorization: `${window.sessionStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        sessionStorage.removeItem("id_token");
        sessionStorage.removeItem("user_type");
        sessionStorage.removeItem("user_id");
        sessionStorage.removeItem("user_name");
        sessionStorage.removeItem("full_name");
        sessionStorage.removeItem("meeting_id");
        this.props.router.navigate("/patient/login");
      })
      .catch((err) => {
        swal("", "An error occured, logging out now!", "info").then(() => {
          sessionStorage.removeItem("id_token");
          sessionStorage.removeItem("user_type");
          sessionStorage.removeItem("user_id");
          sessionStorage.removeItem("user_name");
          sessionStorage.removeItem("full_name");
          sessionStorage.removeItem("meeting_id");
          this.props.router.navigate("/patient/login");
        });
      });
  }

  handleChangeDate = (date) => {
    let formErrors = this.state.formErrors;
    formErrors.dob = date === null ? "DOB is Mandatory" : "";

    this.setState({
      validation_msg: "",
    });

    var dt = new Intl.DateTimeFormat("en-GB").format(date);
    var dateofbirth =
      dt.substring(6, 10) + "-" + dt.substring(3, 5) + "-" + dt.substring(0, 2);

    var tempDate = new Date();
    var yr = tempDate.getFullYear();

    if (yr - Number(dt?.substring(6, 10)) < 18) {
      if (
        this.state.registered_for_self == 1 ||
        this.state.registered_for_self == 2
      ) {
        formErrors.dob = "";
      } else {
        formErrors.dob =
          "Only a caregiver can book an appointment on behalf of the minor 10";
      }
      this.setState({ formErrors });
    }

    this.setState(
      {
        dateofbirth: dateofbirth,
        dob: date,
        formErrors: formErrors,
        invalid_dob: false,
        invalid_day: false,
        invalid_month: false,
      },
      () => {}
    );
  };

  handlePincodeValidation = (pincode) => {
    let payload = {
      pincode: pincode,
    };
    if (pincode.length === 6) {
      this.setState(
        (prevState) => ({
          pincode: pincode,
          state_name:
            prevState?.pincode?.length < 6 ? "" : prevState.state_name,
        }),
        () => {
          getPinCodeValidation(payload, PIN_CODE_VALIDATION)
            .then(([res, err]) => {
              if (res?.data?.statusCode === 200) {
                const stateCityData = res?.data?.data;
                this.setState({
                  stateCityData: stateCityData,
                  formErrors: {
                    ...this.state.formErrors,
                    pincode: null,
                  },
                });
              } else {
                const errorMessage = "Please enter valid Pincode";
                this.setState({
                  stateCityData: null,
                  pincodeError: true,
                  formErrors: {
                    ...this.state.formErrors,
                    pincode: errorMessage,
                  },
                });
              }
            })
            .catch((err) => console.log(err));
        }
      );
    } else {
      this.setState({
        pincode: pincode,
        state_name: "",
        stateCityData: null,
        pincodeError: false,
      });
    }
  };

  handleChange = (name) => (event) => {
    const target = event.target;
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;

    this.setState({
      validation_msg: "",
    });

    if (name === "registered_for_self") {
      this.setState(
        {
          registered_for_self: event.target.value,
        },
        () => {}
      );
    } else if (name === "name") {
      formErrors.name = value.length > 0 ? "" : "Patient Name is Mandatory";
      this.setState(
        {
          name: event.target.value,
          formErrors: formErrors,
        },
        () => {}
      );
    } else if (name === "email") {
      this.setState(
        {
          email: event.target.value,
          formErrors: formErrors,
        },
        () => {}
      );
    } else if (name === "purpose") {
      formErrors.purpose = value.length > 0 ? "" : "Purpose is Mandatory";
      this.setState(
        {
          purpose: event.target.value,
          formErrors: formErrors,
        },
        () => {}
      );
    } else if (name === "dob") {
      formErrors.dob =
        value.length > 0 && value.length <= 11
          ? ""
          : "Patient DOB is Incorrect";

      formErrors.dob = value.length === 0 ? "Patient DOB is Mandatory" : "";

      this.setState(
        {
          dob: event.target.value,
          formErrors: formErrors,
        },
        () => {}
      );
    } else if (name === "sex") {
      formErrors.gender = value.length > 0 ? "" : "Gender is Mandatory";
      this.setState(
        {
          gender: event.target.value,
        },
        () => {}
      );
      if (event.target.value === "Male") {
        this.setState({
          sex: "Male",
        });
      } else if (event.target.value == "Others") {
        this.setState({
          sex: "Others",
        });
      } else {
        this.setState({
          sex: "Female",
        });
      }
    } else if (name === "city") {
      formErrors.city = value.length > 0 ? "" : "City is Mandatory";
      this.setState(
        {
          city: event.target.value,
          formErrors: formErrors,
        },
        () => {}
      );
    } else if (name === "any_drug_allergy") {
      this.setState(
        {
          any_drug_allergy: event.target.value,
        },
        () => {}
      );
      if (event.target.value === 1) {
        let formErrors = this.state.formErrors;
        formErrors.drug_allergy = this.state.drug_allergy
          ? ""
          : "Drug Allergy cannot be blank";
        this.setState({
          drugDiv: "true",
          formErrors: formErrors,
        });
      } else {
        let formErrors = this.state.formErrors;
        formErrors.drug_allergy = "";
        this.setState({
          drugDiv: "",
          formErrors: formErrors,
        });
      }
    } else if (name === "any_habit") {
      this.setState(
        {
          any_habit: event.target.value,
        },
        () => {}
      );
      if (event.target.value === 1) {
        let formErrors = this.state.formErrors;
        formErrors.habits = this.state.habits ? "" : "Habits cannot be blank";
        this.setState({
          habitDiv: "true",
        });
      } else {
        let formErrors = this.state.formErrors;
        formErrors.habits = "";
        this.setState({
          habitDiv: "",
          formErrors: formErrors,
        });
      }
    } else if (name === "is_taking_medicine") {
      this.setState(
        {
          is_taking_medicine: event.target.value,
        },
        () => {}
      );
      if (event.target.value === 1) {
        let formErrors = this.state.formErrors;
        formErrors.medication = this.state.medication
          ? ""
          : "Medication cannot be blank";
        this.setState({
          medicationDiv: "true",
        });
      } else {
        let formErrors = this.state.formErrors;
        formErrors.medication = "";
        this.setState({
          medicationDiv: "",
          formErrors: formErrors,
        });
      }
    } else if (name === "followup") {
      this.setState(
        {
          followup: event.target.value,
        },
        () => {}
      );
      if (event.target.value === 1) {
        this.setState({
          followup: 1,
        });
      } else {
        this.setState({
          followup: 0,
        });
      }
    } else if (name === "medication") {
      let formErrors = this.state.formErrors;

      formErrors.medication = event.target.value
        ? ""
        : "Medication cannot be blank";
      this.setState(
        {
          medication: event.target.value,
          formErrors: formErrors,
        },
        () => {}
      );
    } else if (name === "drug_allergy") {
      let formErrors = this.state.formErrors;
      formErrors.drug_allergy = event.target.value
        ? ""
        : "Drug allergy cannot be blank";
      this.setState(
        {
          drug_allergy: event.target.value,
          formErrors: formErrors,
        },
        () => {}
      );
    } else if (name === "habits") {
      let formErrors = this.state.formErrors;
      formErrors.habits = event.target.value ? "" : "Habits cannot be blank";
      this.setState(
        {
          habits: event.target.value,
          formErrors: formErrors,
        },
        () => {}
      );
    } else if (name === "caregiver") {
      const value =
        event.target.name === "caregiver" ? target.checked : target.value;

      this.setState(
        {
          caregiver: value,
        },
        () => {}
      );
    } else if (name === "terms") {
      const value =
        event.target.name === "terms" ? target.checked : target.value;

      this.setState(
        {
          terms: value,
        },
        () => {}
      );
    } else if (name === "email" && this.state.email !== "") {
      formErrors.email = emailRegex.test(value)
        ? ""
        : "Please enter valid email";

      this.setState(
        {
          email: value,
          formErrors: formErrors,
        },
        () => {}
      );
    } else if (name === "caregiverEmail") {
      this.setState({ caregiverEmail: value, ecaregiverEmail: value });
    } else if (name === "patientPhone") {
      this.setState({ patientPhone: value, epatientPhone: value });
    } else if (name === "caregiverName") {
      let onlyAlpha = value.replace(/[^A-Za-z ]/gi, "");
      let caregiver_name = onlyAlpha.replace(/[^\w\s]/gi, "");
      this.setState({
        caregiverName: caregiver_name,
        ecaregiverName: caregiver_name,
      });
      // (this.state.registered_for_self == 1)?(this.setState({ caregiverName: caregiver_name })):(this.setState({ ecaregiverName: caregiver_name }));
    } else if (name === "caregivergovtID") {
      let remove_special_char = value.replace(/[^\w\s]/gi, "");
      let caregiver_govt_id = remove_special_char.replace(/\s/g, "");
      this.setState({
        caregivergovtID: caregiver_govt_id,
        ecaregivergovtID: caregiver_govt_id,
      });
    } else if (name === "address") {
      this.setState({ address: value });
    } else if (name === "pincode") {
      const pincode = event.target.value;
      const formErrors = this.state.formErrors;

      this.handlePincodeValidation(pincode);
      this.setState({
        pincode: pincode,
        formErrors: formErrors,
      });
    } else {
      if (value.length > 0) {
        formErrors.phone_number = onlyDigitRegex.test(value)
          ? ""
          : "Please enter valid Phone Numbers";
        this.setState(
          {
            phone_number: event.target.value,
            formErrors: formErrors,
          },
          () => {}
        );
        //this.setState({formErrors});
      } else {
        formErrors.phone_number =
          value.length === 10 ? "" : " Phone Number is Mandatory";
        this.setState(
          {
            phone_number: event.target.value,
            formErrors: formErrors,
          },
          () => {}
        );
      }
    }
  };
  handleOtp(event) {
    const { value } = event.target;
    let formErrors = this.state.formErrors;

    formErrors.otp = value.length > 0 ? "" : " Provide Data For OTP";
    this.setState(
      {
        otp: event.target.value,
        formErrors: formErrors,
      },
      () => {}
    );
  }

  async onSubmit(event) {
    this.setState(
      {
        formErrors: {},
      },
      () => {}
    );

    let formErrors = this.state.formErrors;

    formErrors.dob = "";
    formErrors.address = "";

    this.setState({ formErrors: formErrors });
    const { name, value } = event.target;
    if (name === "email" && this.state.email !== "") {
      formErrors.email = emailRegex.test(value)
        ? ""
        : "Please enter valid email";
      this.setState(
        {
          email: value,
          formErrors: formErrors,
        },
        () => {}
      );
    }
    formErrors = this.state.formErrors;

    var tempDate = new Date();
    var yr = tempDate.getFullYear();
    var dt;
    var dateofbirth;
    if (sessionStorage.getItem("patient_dob")) {
      dt = sessionStorage.getItem("patient_dob");
      dateofbirth = dt;
      this.setState({
        dateofbirth: dateofbirth,
      });
    } else {
      if (this.state.dob && !this.state.dateError) {
        dt = new Intl.DateTimeFormat("en-GB").format(this.state.dob);
        dateofbirth =
          dt.substring(6, 10) +
          "-" +
          dt.substring(3, 5) +
          "-" +
          dt.substring(0, 2);
        this.setState({
          dateofbirth: dateofbirth,
        });
      } else {
        formErrors.dob =
          !this?.state?.dobDate?.year &&
          !this?.state?.dobDate?.month &&
          !this?.state?.dobDate?.day
            ? "DOB is mandatory"
            : "Please enter a valid dob";
      }
    }

    var habits_string = "";

    if (this.state.habits.length > 0) {
      for (var i = 0; i < this.state.habits.length; i++) {
        habits_string = habits_string + this.state.habits[i];
        habits_string = habits_string + ", ";
      }

      let formErrors = this.state.formErrors;
      formErrors.habits =
        this.state.habits.length > 0 ? "" : "Habits cannot be blank";
      formErrors.habit =
        this.state.habits.length > 0 ? "" : "Habits cannot be blank";
      var valid_msg = "";
      this.setState({
        formErrors: formErrors,
        validation_msg: valid_msg,
      });
    }

    if (this.state.is_taking_medicine === 1) {
      let formErrors = this.state.formErrors;
      formErrors.medication =
        this.state.medication.length > 0 ? "" : "Medication cannot be blank";
      this.setState({
        formErrors: formErrors,
      });
    }
    if (this.state.any_drug_allergy === 1) {
      let formErrors = this.state.formErrors;
      formErrors.drug_allergy =
        this.state.drug_allergy.length > 0
          ? ""
          : "Drug Allergy cannot be blank";
      this.setState({
        formErrors: formErrors,
      });
    }
    if (this.state.any_habit === 1) {
      let formErrors = this.state.formErrors;
      formErrors.habits =
        this.state.habits.length > 0 ? "" : "Habits cannot be blank";
      this.setState({
        formErrors: formErrors,
      });
    }

    if (this.state.patientType === "patient") {
      let formErrors = this.state.formErrors;

      if (this.state.invalid_dob) {
        var valid_msg = "Invalid Date";
      } else if (yr - Number(dt?.substring(6, 10)) < 18) {
        formErrors.dob =
          "Only a caregiver can book an appointment on behalf of the minor1";
        var valid_msg =
          "Only a caregiver can book an appointment on behalf of the minor2";
      } else {
      }
      this.setState(
        {
          formErrors: formErrors,
          validation_msg: valid_msg,
        },
        () => {}
      );
    }

    if (this.state.patient_dob && this.state.prevPatient) {
      let formErrors = this.state.formErrors;
      formErrors.dob = "";
      var valid_msg = "";
      this.setState({
        formErrors: formErrors,
        validation_msg: valid_msg,
      });
    }

    if (this.state.registered_for_self > 2) {
      let formErrors = this.state.formErrors;
      formErrors.caregiver = "Please select a caregiver";
      var valid_msg = "Please select a caregiver";
      this.setState({
        formErrors: formErrors,
        validation_msg: valid_msg,
      });
    }
    if (
      Number(dateofbirth?.substring(5, 7)) > 12 ||
      Number(dateofbirth?.substring(8, 10)) > 31
    ) {
      let formErrors = this.state.formErrors;
      formErrors.dob = "Please enter a valid dob";
      var valid_msg = "Please enter a valid dob";
      this.setState({
        formErrors: formErrors,
        validation_msg: valid_msg,
      });
    }
    const {
      patientPhone,
      caregiverEmail,
      phone_number,
      registered_for_self,
      caregiverName,
      caregiverageProof,
      caregivergovtID,
      ecaregiverName,
      ecaregivergovtID,
      ecaregiverEmail,
      epatientPhone,
      address,
      pincode,
      stateCityData,
      purpose,
    } = this.state;
    let allowValid = true;
    let age = this.is_minor_patient();
    let patient_adult = age > 18 ? true : false;
    if (registered_for_self == 1 || registered_for_self == 2) {
      if (
        !this.state.removeMandatoryBasedOnConsultationType &&
        !caregiverageProof.length
      ) {
        formErrors.caregiverageProof = patient_adult
          ? ""
          : "Age Proof is Mandatory";
        valid_message = !formErrors.caregiverageProof
          ? valid_message
          : patient_adult
          ? ""
          : "Age Proof is Mandatory";
      } else {
        formErrors.caregiverageProof = "";
      }
      if (
        (ecaregiverEmail && ecaregiverName && ecaregivergovtID) ||
        this.state.removeMandatoryBasedOnConsultationType
      ) {
        allowValid = true;
      } else {
        allowValid =
          !caregiverName || !caregiverageProof.length || !caregivergovtID
            ? false
            : true;

        if (patient_adult && ecaregiverName) {
          allowValid = true;
        } else if (!patient_adult && ecaregiverName) {
          allowValid =
            !caregivergovtID || !caregiverageProof.length ? false : true;
        } else if (patient_adult) {
          allowValid = !caregiverName ? false : true;
        } else {
          allowValid =
            !caregiverName || !caregiverageProof.length || !caregivergovtID
              ? false
              : true;
        }
      }
      this.setState({
        formErrors: formErrors,
        validation_msg: valid_msg,
      });
    }

    if (
      formValid(this.state.formErrors) &&
      !this.state.invalid_dob &&
      this.state.registered_for_self <= 2 &&
      allowValid &&
      (this.state.isPurposeAddressMandatory ? address && purpose : true) &&
      pincode &&
      stateCityData &&
      !this.state.dateError
    ) {
      var any_drug = this.state.any_drug_allergy === "1" ? true : false;
      var any_habit = this.state.any_habit === "1" ? true : false;
      var any_medicine = this.state.is_taking_medicine === "1" ? true : false;
      var followup = !!sessionStorage.getItem("is_followup");

      let that = this;
      this.setState(
        {
          loader: true,
          validation_msg: "",
        },
        () => {}
      );

      let prec = await this.fileCheckUpload(this.state.prev_prescription);
      if (prec === undefined) {
        this.setState(
          {
            loader: false,
          },
          () => {}
        );
        swal({
          title: "Invalid File type!",
          text: "Please upload file having extensions .jpeg/.jpg/.png/.gif/.pdf/.doc only.",
          type: "error",
          icon: "warning",
        }).then(function () {});
        return;
      }
      let age = "";
      if (dateofbirth) {
        let startDate = moment(dateofbirth, "YYYY-MM-DD");
        let endDate = moment();
        const duration = moment.duration(endDate.diff(startDate));
        age = `${
          duration.years() +
          " years " +
          duration.months() +
          " months " +
          duration.days() +
          " days"
        }`;
      }
      let newpatient = sessionStorage.getItem("isNew");
      let formData = {};
      if (this.state.meetingId === null) {
        let consultation_type = followup
          ? sessionStorage.getItem("followup_consultation_type")
          : sessionStorage.getItem("consultation-type");
        formData = {
          registration_type: this.state.registered_for_self,
          name: this.state.name,
          dob: dateofbirth,
          gender: this.state.sex,
          prev_prescription: prec || "",
          any_drug_allergy: any_drug,
          any_habit: any_habit,
          is_taking_medicine: any_medicine,
          medication: this.state.medication,
          drug_allergy: this.state.drug_allergy,
          habits: habits_string,
          purpose_of_appointment: this.state.purpose,
          email: this.state.email || "",
          appointment_date: sessionStorage.getItem("date")
            ? sessionStorage.getItem("date")
            : moment().format("YYYY-MM-DD"), //added for date instant consultatio
          appointment_slot_id: sessionStorage.getItem("slot_id"),
          is_follow_up: followup,
          is_accept_terms: this.state.terms,
          is_accept_non_self_terms: this.state.caregiver,
          caregiverName: ecaregiverName ? ecaregiverName : caregiverName,
          caregiverEmail: ecaregiverEmail ? ecaregiverEmail : caregiverEmail,
          caregivergovtID: ecaregivergovtID
            ? ecaregivergovtID
            : caregivergovtID,
          caregiverageProof: caregiverageProof,
          phone_number: sessionStorage.getItem("user_name"),
          patientPhone: epatientPhone ? epatientPhone : patientPhone,
          patient_address: address,
          patient_pincode: pincode,
          state_code: this.state.stateCityData
            ? this.state.stateCityData?.state_code
            : "",
          consultation_type: consultation_type,
          consultation_book_method: `${
            consultation_type === "5" || consultation_type === "6"
              ? "in-clinic"
              : ""
          }`,
          age: age,
          new_patient: newpatient === "true" ? "True" : "False",
        };
        if (
          newpatient === "false" ||
          newpatient === "False" ||
          newpatient === null
        ) {
          let selectedData = JSON.parse(this.state.selectedPatient);
          formData = {
            ...formData,
            patient_account_id: selectedData.patient_account_id,
          };
        }
      } else {
        formData = {
          registration_type: this.state.registered_for_self,
          name: this.state.name,
          dob: dateofbirth,
          gender: this.state.sex,
          prev_prescription: prec || "",
          any_drug_allergy: any_drug,
          any_habit: any_habit,
          is_taking_medicine: any_medicine,
          medication: this.state.medication,
          drug_allergy: this.state.drug_allergy,
          habits: habits_string,
          // clinic_id:clinic_id,
          purpose_of_appointment: this.state.purpose,
          email: this.state.email || "",
          appointment_date: sessionStorage.getItem("date")
            ? sessionStorage.getItem("date")
            : moment().format("YYYY-MM-DD"), //added for date instant consultation
          appointment_slot_id: sessionStorage.getItem("slot_id"),
          is_follow_up: followup,
          is_accept_terms: this.state.terms,
          is_accept_non_self_terms: this.state.caregiver,
          caregiverName: ecaregiverName ? ecaregiverName : caregiverName,
          caregiverEmail: ecaregiverEmail ? ecaregiverEmail : caregiverEmail,
          caregivergovtID: ecaregivergovtID
            ? ecaregivergovtID
            : caregivergovtID,
          caregiverageProof: caregiverageProof,
          phone_number: sessionStorage.getItem("user_name"),
          patientPhone: epatientPhone ? epatientPhone : patientPhone,
          patient_address: address,
          patient_pincode: pincode,
          state_code: this.state.stateCityData
            ? this.state.stateCityData?.state_code
            : "",
          consultation_type: 2,
          meeting_id: this.state.meetingId,
          start_time: moment(new Date()).format("HH:mm:ssZ"),
          end_time: moment(new Date()).add(15, "minutes").format("HH:mm:ssZ"),
          age: age,
          new_patient: newpatient === "true" ? "True" : "False",
        };

        if (
          newpatient === "false" ||
          newpatient === "False" ||
          newpatient === null
        ) {
          let selectedData = JSON.parse(this.state.selectedPatient);
          formData = {
            ...formData,
            patient_account_id: selectedData.patient_account_id,
          };
        }
      }
      const clinicId = sessionStorage.getItem("clinicId");

      if (clinicId !== "" && clinicId !== undefined && clinicId !== null) {
        formData["clinic_id"] = clinicId;
      }
      afterLoginApiAxios
        .post("patient/book-appointment-add-details-rev3", formData, {
          headers: {
            // Authorization: sessionStorage.getItem("id_token"),
            Authorization: sessionStorage.getItem("id_token"),
            "X-Application-Source": "widget",
          },
        })
        .then((response) => {
          if (response) {
            this.setState(
              {
                loader: false,
                validation_msg: "",
              },
              () => {}
            );
            var responseData = response.data;

            if (responseData) {
              if (responseData.statusCode === 200) {
                if (responseData.appointment_id) {
                  this.setState({ isFormValid: true });
                  sessionStorage.removeItem("is_followup");
                  sessionStorage.setItem(
                    "flag_appointment_id",
                    responseData.appointment_id
                  );
                  sessionStorage.setItem(
                    "flag_appointment_id_instant",
                    responseData.appointment_id
                  );
                  sessionStorage.setItem("patient_name", formData.name);
                  sessionStorage.setItem("patient_email", formData.email);
                  sessionStorage.removeItem("ecaregiverName");
                  sessionStorage.removeItem("ecaregiverEmail");
                  sessionStorage.removeItem("ecaregivergovtID");
                  sessionStorage.removeItem("caregiver_name");
                  sessionStorage.removeItem("caregiver_email");
                  sessionStorage.removeItem("caregiver_govt_id");
                  sessionStorage.removeItem("selectedPatient");
                  sessionStorage.removeItem("epatientPhone");
                  sessionStorage.removeItem("registration_type");
                  sessionStorage.removeItem("registered_for_self");
                  this.setState({
                    ecaregiverEmail: "",
                    ecaregiverName: "",
                    ecaregivergovtID: "",
                  });
                  // this.props.router.navigate(`${getPathName(this.props.router.location.pathname)}/reviewpage`);
                  this.props.router.navigate(`/reviewpage`);
                }
              } else {
                let message = responseData.message;
                let reload = true;
                if (
                  sessionStorage.getItem("appointment_slot_locked") === "true"
                ) {
                  reload = false;
                  message =
                    "The slot is blocked for 10 minutes. Please try after such time.";
                }
                swal({
                  title: "Booking Failed!",
                  text: message,
                  type: "error",
                  icon: "warning",
                }).then(function () {
                  if (reload) {
                    //    window.location.reload();
                  }
                });
              }
            }
          }
        })
        .catch(function (error) {
          that.setState({ loader: false });
          swal({
            title: "Booking Failed!",
            text: "Please Try Again",
            type: "error",
            icon: "warning",
          });
        });
      // aclling update API
      if (sessionStorage.getItem("isNew") === "false") {
        let selectedData = JSON.parse(this.state.selectedPatient);
        afterLoginApiAxios
          .post(
            "public/update-patient-details",
            {
              user_id: sessionStorage.getItem("user_id"),
              user_type: sessionStorage.getItem("user_type"),
              patient_account_id: selectedData.patient_account_id,
              patient_id: selectedData.patient_id,
              patient_dob:
                this.state.dob && moment(this.state.dob).format("YYYY-MM-DD"),
              patient_email: this.state.email,
              patient_gender: this.state.sex,
              patient_name: this.state.name,
              formatted_uhid: selectedData.formatted_uhid,
            },
            // { },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: sessionStorage.getItem("id_token"),
              },
            }
          )
          .then((response) => {
            if (response.data) {
              if (response.data.statusCode === 200) {
                console.log("success");
              } else {
                // TODO: Error handling and logging
              }
            } else {
              // TODO: Error handling and logging
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      formErrors = {
        name: "",
        dob: "",
        gender: "",
        city: "",
        drug_allergy: "",
        habits: "",
        medication: "",
        phone_number: "",
        otp: "",
        email: "",
        purpose: "",
        caregiver: "",
        patientPhone: "",
        caregiverEmail: "",
        caregiverageProof: "",
        stateCityData: this.state.formErrors.stateCityData,
        pincode: this.state.formErrors.pincode,
      };
      var valid_message = "";
      this.setState({ formErrors: formErrors });
      if (this.state.any_habit === 1) {
        if (!this.state.habit) {
          formErrors.habit = "Please Provide Data For Habit";
          valid_message = "Habits field cannot be blank";
          this.setState({ formErrors, validation_msg: valid_message });
        }
      }

      if (!this.state.name) {
        formErrors.name = "Name is Mandatory";
        valid_message = "Name is Mandatory";
        this.setState({ formErrors });
      } else if (this.state.isPurposeAddressMandatory && !this.state.purpose) {
        formErrors.purpose = "Purpose is Mandatory";
        valid_message = "Purpose is Mandatory";
        this.setState({ formErrors });
      } else if (!this.state.gender) {
        formErrors.gender = "Gender is Mandatory";
        this.setState({ formErrors });
      } else if (this.state.any_drug_allergy === 1) {
        if (!this.state.drug_allergy) {
          formErrors.drug_allergy = "Please Provide Data For Drug Allergy";
          valid_message = "Drug Allergy field cannot be blank";
          this.setState({ formErrors });
        }
      } else if (this.state.any_habit === 1) {
        if (!this.state.habit) {
          formErrors.habit = "Please Provide Data For Habit";
          valid_message = "Habits field cannot be blank";
          this.setState({ formErrors, validation_msg: valid_message });
        }
      } else if (this.state.is_taking_medicine === 1) {
        if (!this.state.medication) {
          formErrors.medication = "Please Provide Data For Mediction";
          valid_message = "Medication cannot be blank";
          this.setState({ formErrors });
        }
      } else if (this.state.date === "" || this.state.sid === "") {
        this.setState(
          {
            validation_msg: "Please re-select an appointment date and time",
          },
          () => {}
        );
      } else if (this.state.invalid_dob) {
        valid_message = "Invalid Date";
      } else if (yr - Number(this.state.dateofbirth.substring(0, 4)) < 18) {
        if (registered_for_self == 1 || registered_for_self == 2) {
          formErrors.dob = "";
          valid_message = "";
        } else {
          formErrors.dob =
            "Only a caregiver can book an appointment on behalf of the minor3";
          valid_message =
            "Only a caregiver can book an appointment on behalf of the minor4";
          this.setState({ formErrors });
        }
      } else if (!this.state.dob || this.state.dateError) {
        formErrors.dob =
          !this?.state?.dobDate?.year &&
          !this?.state?.dobDate?.month &&
          !this?.state?.dobDate?.day
            ? "DOB is mandatory"
            : "Please enter a valid dob";
        valid_message =
          !this?.state?.dobDate?.year &&
          !this?.state?.dobDate?.month &&
          !this?.state?.dobDate?.day
            ? "DOB is mandatory"
            : "";
        this.setState({ formErrors });
      }

      if (
        Number(this.state.dateofbirth.substring(5, 7)) > 12 ||
        Number(this.state.dateofbirth.substring(8, 10)) > 31
      ) {
        formErrors.dob = "Please enter a valid dob";
        valid_message = "Please enter a valid dob";
        this.setState({ formErrors });
      }

      if (this.state.registered_for_self == 9) {
        formErrors.caregiver = "Please select a caregiver";
        valid_message = "Please select a caregiver";
        this.setState({ formErrors });
      } else if (
        this.state.registered_for_self == 0 &&
        yr - Number(this.state.dateofbirth.substring(0, 4)) < 18
      ) {
        formErrors.dob =
          "Only a caregiver can book an appointment on behalf of the minor7";
        valid_message = this.state.invalid_dob
          ? "Invalid Date"
          : "Only a caregiver can book an appointment on behalf of the minor8";
        this.setState({ formErrors });
      }

      if (!pincode) {
        formErrors.pincode = "Pincode is Mandatory";
        valid_message = !formErrors.pincode
          ? valid_message
          : "Pincode is Mandatory";
        this.setState({ formErrors, validation_msg: valid_message });
      } else if (!pincodeRegex.test(pincode)) {
        formErrors.pincode = "Please enter valid Pincode";
        valid_message = !formErrors.pincode
          ? valid_message
          : "Please enter valid Pincode";
        this.setState({ formErrors, validation_msg: valid_message });
      }
      if (this.state.isPurposeAddressMandatory && !address) {
        formErrors.address = "Address is Mandatory";
        valid_message = !formErrors.address
          ? valid_message
          : "Address is Mandatory";
        this.setState({ formErrors, validation_msg: valid_message });
      } else if (address) {
        formErrors.address = addressRegex.test(address)
          ? null
          : "Please enter valid Address";
        valid_message = !formErrors.address
          ? valid_message
          : "Please enter valid Address";
        this.setState({ formErrors, validation_msg: valid_message });
      }
      if (registered_for_self == 1 || registered_for_self == 2) {
        let caregiverType =
          registered_for_self == 1
            ? "CG"
            : registered_for_self == 2
            ? "HCP"
            : null;
        formErrors.dob = "";
        valid_message = this.state.invalid_dob ? "Invalid Date" : valid_message;
        if (ecaregiverEmail && ecaregiverName && ecaregivergovtID) {
          if (this.state.isPurposeAddressMandatory && !this.state.purpose) {
            formErrors.purpose = "Purpose is Mandatory";
            valid_message = "Purpose is Mandatory";
            this.setState({ formErrors, validation_msg: valid_message });
          }
          if (
            !this.state.removeMandatoryBasedOnConsultationType &&
            !caregiverageProof.length
          ) {
            formErrors.caregiverageProof = patient_adult
              ? ""
              : "Age Proof is Mandatory";
            valid_message = !formErrors.caregiverageProof
              ? valid_message
              : patient_adult
              ? ""
              : "Age Proof is Mandatory";
          } else {
            formErrors.caregiverageProof = "";
          }
          return;
        } else {
          if (
            !this.state.removeMandatoryBasedOnConsultationType &&
            !caregiverageProof.length
          ) {
            formErrors.caregiverageProof = patient_adult
              ? ""
              : "Age Proof is Mandatory";
            valid_message = !formErrors.caregiverageProof
              ? valid_message
              : patient_adult
              ? ""
              : "Age Proof is Mandatory";
          } else {
            formErrors.caregiverageProof = "";
          }
          if (
            !this.state.removeMandatoryBasedOnConsultationType &&
            !caregivergovtID
          ) {
            formErrors.caregivergovtID = patient_adult
              ? ""
              : `GOVT ID of ${caregiverType} is Mandatory`;
            valid_message = !formErrors.caregivergovtID
              ? valid_message
              : patient_adult
              ? ""
              : `GOVT ID of ${caregiverType} is Mandatory`;
          } else if (caregivergovtID) {
            formErrors.caregivergovtID = onlyAlphaNumeric.test(caregivergovtID)
              ? ""
              : "";
          }
          if (caregiverEmail.length > 0) {
            formErrors.caregiverEmail = emailRegex.test(caregiverEmail)
              ? ""
              : "Please enter valid email";
            valid_message = !formErrors.caregiverEmail
              ? valid_message
              : formErrors.caregiverEmail;
          }

          if (patientPhone.length > 0) {
            formErrors.patientPhone = onlyDigitRegex.test(patientPhone)
              ? patientPhone.length === 10
                ? ""
                : "Please enter valid mobile number"
              : "Please enter valid mobile number";
            valid_message = valid_message
              ? valid_message
              : formErrors.patientPhone;
            if (phone_number === `+91${patientPhone}`) {
              formErrors.patientPhone = `The phone number entered for the patient and ${caregiverType} is the same`;
              valid_message = !formErrors.patientPhone
                ? valid_message
                : `The phone number entered for the patient and ${caregiverType} is the same`;
            }
          }
          if (caregiverName) {
            formErrors.caregiverName = onlyAlphaRegex.test(caregiverName)
              ? ""
              : "Please enter valid name";
            valid_message = !formErrors.caregiverName
              ? valid_message
              : formErrors.caregiverName;
            if (
              caregiverName.trim().toLowerCase() ===
              this.state.name.trim().toLowerCase()
            ) {
              formErrors.caregiverName = `${caregiverType} and Patient name is same`;
              valid_message = !formErrors.caregiverName
                ? valid_message
                : `${caregiverType} and Patient name is same`;
            }
          } else if (
            !this.state.removeMandatoryBasedOnConsultationType &&
            !caregiverName
          ) {
            formErrors.caregiverName = "Name is Mandatory";
            valid_message = !formErrors.caregiverName
              ? valid_message
              : "Name is Mandatory";
          }
          if (this.state.isPurposeAddressMandatory && !this.state.purpose) {
            formErrors.purpose = "Purpose is Mandatory";
            valid_message = "Purpose is Mandatory";
          }
          this.setState({ formErrors, validation_msg: valid_message });
        }
      }

      this.setState({
        validation_msg: valid_message,
      });
    }
  }

  openCheckout() {
    let options = {
      key: "rzp_test_nApPxXOgyoSLAs",
      amount: this.state.doctor.total_fees * 100, // 2000 paise = INR 20, amount in paisa
      name: "Connect2Clinic",
      description: "Online Consultation",
      image: process.env.PUBLIC_URL + "/images/c2.png",
      handler: function (response) {
        this.props.router.navigate(
          `${getPathName(this.props.router.location.pathname)}/thankyou`
        );
      },
      prefill: {
        name: this.state.name,
        contact: this.state.phone,
        email: this.state.email,
      },
      theme: {
        color: "#17BAE0",
      },
    };
    let rzp = new window.Razorpay(options);
    rzp.open();
  }

  acceptDiv() {
    this.setState({
      acceptFlag: false,
    });
    document.body.style.overflow = "";
  }

  onOtpSend(event) {
    let formErrors = this.state.formErrors;
    if (!this.state.phone_number) {
      formErrors.phone_number = "At first Please Provide Phone Number";
      this.setState({ formErrors });
    } else {
      var userType = "";
      if (this.state.new_user === 0) {
        userType = "existing-user-register";
      }

      afterLoginApiAxios
        .post("public/send-otp", {
          phone_no: this.state.phone_number,
          channel_id: userType,
        })
        .then((response) => {
          if (response) {
            if (response.data.hasOwnProperty("statusCode")) {
              let message = response.data.message;
              if (response.data.statusCode === 200) {
                swal({
                  title: "Success!",
                  text: message,
                  type: "success",
                }).then(function () {
                  //window.location.reload();
                });
              } else {
                swal({
                  title: "Failed!",
                  text: message,
                  type: "error",
                  icon: "warning",
                }).then(function () {
                  //window.location.reload();
                });
              }
            } else {
              swal({
                title: "Failed!",
                text: "Something Went Wrong!!Try Again",
                type: "error",
                icon: "warning",
              }).then(function () {});
            }
          } else {
            swal({
              title: "Failed!",
              text: "Something Went Wrong!!Try Again",
              type: "error",
              icon: "warning",
            }).then(function () {});
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  componentDidMount() {
    //   disableHelpButton();
    window.scrollTo(0, 0);
  }

  handleBlur = (e) => {
    const {
      caregiverEmail,
      patientPhone,
      phone_number,
      caregiverName,
      caregivergovtID,
      ecaregiverEmail,
      ecaregiverName,
      ecaregivergovtID,
      epatientPhone,
      registered_for_self,
      address,
      pincode,
      purpose,
      selectedState,
      stateCityData,
    } = this.state;

    console.log("imp log", this.state.removeMandatoryBasedOnConsultationType);

    let age = this.is_minor_patient();
    let patient_adult = age > 18 ? true : false;
    let caregiverType =
      registered_for_self == 1 ? "CG" : registered_for_self == 2 ? "HCP" : null;
    let formErrors = this.state.formErrors;
    const { name } = e.currentTarget;
    if (name === "address") {
      if (this.state.isPurposeAddressMandatory && !address) {
        formErrors.address = "Address is Mandatory";
      } else if (address) {
        formErrors.address = addressRegex.test(address)
          ? ""
          : "Please enter valid Address";
      }
    }
    if (name === "pincode") {
      if (!pincode) {
        formErrors.pincode = "Pincode is Mandatory";
      } else if (!stateCityData) {
        formErrors.pincode = "Please enter valid Pincode";
      }
    }
    if (name === "purpose") {
      if (this.state.isPurposeAddressMandatory && !purpose) {
        formErrors.purpose = "Purpose is Mandatory";
      } else if (purpose) {
        formErrors.purpose = addressRegex.test(purpose)
          ? null
          : "Please enter valid Purpose";
      }
    }
    if (e.currentTarget.id === "react-select-3-input") {
      formErrors.selectedState = !selectedState ? "State is Mandatory" : "";
    }
    this.setState({ formErrors: formErrors });

    if (
      ecaregiverName && ecaregiverEmail && ecaregivergovtID && epatientPhone
        ? epatientPhone
        : null
    ) {
      return;
    } else {
      if (name === "caregiverEmail") {
        if (caregiverEmail.length > 0) {
          formErrors.caregiverEmail = emailRegex.test(caregiverEmail)
            ? ""
            : "Please enter valid email";
        }
      }

      if (name === "patientPhone") {
        if (patientPhone.length > 0) {
          formErrors.patientPhone = onlyDigitRegex.test(patientPhone)
            ? patientPhone.length === 10
              ? ""
              : "Please Enter valid Mobile Number"
            : "Please Enter valid Mobile Number";
          if (phone_number === `+91${patientPhone}`) {
            formErrors.patientPhone = `The phone number entered for the patient and ${caregiverType} is the same`;
          }
        } else if (!patientPhone) {
          formErrors.patientPhone = "";
        }
      }

      if (name === "caregiverName") {
        if (caregiverName && caregiverName.length > 0) {
          formErrors.caregiverName = onlyAlphaRegex.test(caregiverName)
            ? ""
            : "Please enter valid name";
          if (
            caregiverName.trim().toLowerCase() ===
            this.state.name.trim().toLowerCase()
          ) {
            formErrors.caregiverName = `${caregiverType} and Patient name is same`;
          }
        } else if (
          !this.state.removeMandatoryBasedOnConsultationType &&
          !caregiverName
        ) {
          formErrors.caregiverName = "Name is Mandatory";
        }
      }
      if (name === "caregivergovtID") {
        if (caregivergovtID.length > 0) {
          formErrors.caregivergovtID = onlyAlphaNumeric.test(caregivergovtID)
            ? ""
            : "";
        } else if (
          !this.state.removeMandatoryBasedOnConsultationType &&
          !caregivergovtID
        ) {
          formErrors.caregivergovtID = patient_adult
            ? ""
            : `GOVT ID of ${caregiverType} is Mandatory`;
        }
      }
      this.setState({ formErrors });
    }
  };

  is_minor_patient = () => {
    let today = new Date();
    let birthDate = new Date(this.state.dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  handleState = (selectedOption) => {
    this.setState({ selectedState: selectedOption });
  };

  render() {
    const {
      ecaregiverEmail,
      ecaregiverName,
      ecaregiverageProof,
      ecaregivergovtID,
      epatientPhone,
    } = this.state;
    let selectedData = JSON.parse(this.state.selectedPatient);

    return (
      <div>
        <section className="pro-body-content">
          <div className="pro-content">
            <div className="container patient-details-wrapper">
              <div className="row m-2">
                <div className="patient-content-col col-md-12 col-xs-12">
                  <div className="patient-details-wraper">
                    <div className="col-sm-12 col-lg-12 patient-details-header-container">
                      <div className="patient-details-header">
                        ADD PATIENTS DETAILS
                      </div>
                      {selectedData?.formatted_uhid && (
                        <div className="patient-uhid">
                          <b>UHID: {selectedData?.formatted_uhid}</b>
                        </div>
                      )}
                    </div>

                    <div className="col-sm-12 col-lg-12 patient-details-inputs">
                      <div className="row">
                        <div className="col-sm-12 col-lg-6 text-left patient-details-textfield">
                          <label>
                            Full Name<span className="color-pink">*</span>
                          </label>
                          <div className="patientInput">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className="w-100 patient-inputs-form"
                              placeholder="Patient Name"
                              onChange={this.handleChange("name")}
                              value={this.state.name}
                            />
                            <span style={{ color: "#F969A4" }}>
                              {this.state.formErrors["name"]}
                            </span>
                          </div>
                        </div>

                        <div className="col-sm-12 col-lg-6 text-left patient-details-textfield">
                          <label>Email</label>
                          <div className="patientInput">
                            <input
                              type="text"
                              name="email"
                              id="email"
                              disabled={
                                sessionStorage.getItem("patient_email")
                                  ? true
                                  : false
                              }
                              value={this.state.email}
                              placeholder="Patient Email ID"
                              className="w-100 patient-inputs-form"
                              onChange={this.handleChange("email")}
                            />
                            <span style={{ color: "#F969A4" }}>
                              {this.state.formErrors["email"]}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-lg-6 text-left patient-details-textfield">
                          <label>
                            Date of Birth<span className="color-pink">*</span>
                          </label>
                          {this.state.dob && this.state.patient_dob ? (
                            <div className="text-left">
                              <p>
                                {moment(this.state.dob).format("DD MMM YYYY")}
                              </p>
                            </div>
                          ) : (
                            <div
                              onBlur={this.validateDate.bind(this)}
                              className="patient-detail-date-picker"
                            >
                              {
                                <DatePickerNew
                                  get_selected_Date={(userDOB, errorStatus) =>
                                    this.getUserDOB(userDOB, errorStatus)
                                  }
                                />
                              }
                              <br />

                              <div
                                style={{
                                  color: "#F969A4",
                                  marginTop: "30px",
                                }}
                              >
                                {this.state.dateError === true &&
                                !this.state.formErrors["dob"]
                                  ? "Please enter a valid dob"
                                  : this.state.formErrors["dob"]}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-sm-12 col-lg-6 text-left patient-details-textfield">
                          <label>
                            Gender<span className="color-pink">*</span>
                          </label>

                          <div className="text-left gender-options">
                            <div className="form-check-inline d-flex align-items-center">
                              <input
                                className=""
                                type="radio"
                                name="sex"
                                id="gender_yes"
                                checked={
                                  this.state.sex === "Male" ? true : false
                                }
                                value={"Male"}
                                onChange={this.handleChange("sex")}
                              />
                              <label
                                className="form-check-label patientDetails-gender-label-align mt-1"
                                for="is_gender_true"
                              >
                                Male
                              </label>
                            </div>
                            <div className="form-check-inline d-flex align-items-center">
                              <input
                                className=""
                                type="radio"
                                name="sex"
                                id="gender_no"
                                checked={
                                  this.state.sex === "Female" ? true : false
                                }
                                value="Female"
                                onChange={this.handleChange("sex")}
                              />
                              <label
                                className="form-check-label patientDetails-gender-label-align mt-1"
                                for="is_gender_false"
                              >
                                Female
                              </label>
                            </div>
                            <div className="form-check-inline d-flex align-items-center">
                              <input
                                className=""
                                type="radio"
                                name="sex"
                                id="gender_intersex"
                                checked={
                                  this.state.sex === "Others" ? true : false
                                }
                                value="Others"
                                onChange={this.handleChange("sex")}
                              />
                              <label
                                className="form-check-label patientDetails-gender-label-align mt-1"
                                for="is_gender_true"
                              >
                                Others
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-lg-12 text-left patient-details-textfield">
                          <label>
                            Address
                            {this.state.isPurposeAddressMandatory && (
                              <span className="color-pink">*</span>
                            )}
                          </label>
                          <div>
                            <input
                              type="text"
                              className="patient-inputs-form w-100"
                              placeholder="Enter your Address"
                              // maxLength="300"
                              name="address"
                              onChange={this.handleChange("address")}
                              onBlur={this.handleBlur}
                              value={this.state.address}
                            />
                            <span style={{ color: "#F969A4" }}>
                              {this.state.formErrors["address"]}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-lg-6 text-left patient-details-textfield">
                          <div>
                            <label>
                              Pincode
                              <span className="color-pink">*</span>
                            </label>
                            <input
                              type="number"
                              className="w-100 patient-inputs-form"
                              placeholder="Enter your Pincode"
                              name="pincode"
                              id="pincode"
                              maxLength="6"
                              value={this.state.pincode}
                              onInput={this.maxLengthCheck}
                              onKeyDown={(e) =>
                                (e.keyCode === 69 ||
                                  e.keyCode === 187 ||
                                  e.keyCode === 61 ||
                                  e.keyCode === 190 ||
                                  e.keyCode === 189) &&
                                e.preventDefault()
                              }
                              onChange={this.handleChange("pincode")}
                              onBlur={this.handleBlur}
                            />
                            <span style={{ color: "#F969A4" }}>
                              {this.state.formErrors["pincode"]}
                            </span>
                          </div>
                        </div>

                        <div className="col-sm-12 col-lg-6 text-left patient-details-textfield">
                          <div>
                            <label>State</label>
                            <input
                              className="w-100 patient-inputs-form input-disabled custom-cursor"
                              style={{ color: "grey" }}
                              type="text"
                              name="state"
                              value={
                                this.state.stateCityData
                                  ? this.state.stateCityData?.state_name
                                  : sessionStorage.getItem("isNew") === "true"
                                  ? ""
                                  : this.state.state_name
                              }
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-lg-12 text-left patient-details-textfield">
                          <label>
                            Purpose of Consultation
                            {this.state.isPurposeAddressMandatory && (
                              <span className="color-pink">*</span>
                            )}
                          </label>
                          <div className="text-left">
                            <input
                              type="text"
                              name="purpose"
                              id="purpose"
                              className="w-100 patient-inputs-form"
                              onChange={this.handleChange("purpose")}
                              placeholder="Describe your symptoms, concerns, and help required."
                              onBlur={this.handleBlur}
                            />
                            <p>
                              Medical history (**these details will remain super
                              confidential and won't be shared with anyone)
                            </p>
                            <span style={{ color: "#F969A4" }}>
                              {this.state.formErrors["purpose"]}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-lg-12">
                          <label>
                            Upload any previous medical transcripts, test
                            results, diagnostic reports and more.
                          </label>

                          <MultipleImageSelector
                            showWarning
                            maxFileSize={20}
                            selectedImages={(imageArray) => {
                              this.setState({
                                prev_prescription: imageArray,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-lg-12 text-left mt-4">
                          <label for="exampleFormControlTextarea1">
                            Is this for you or someone else?
                          </label>
                          {this.state.registered_for_self &&
                          this.state.prevPatient ? (
                            <div>
                              {this.state.registered_for_self == "0"
                                ? "You"
                                : "Someone"}
                              {this.state.registered_for_self == "1"
                                ? "(Family/Friend)"
                                : null}
                              {this.state.registered_for_self == "2"
                                ? "(Healthcare Professional)"
                                : null}
                            </div>
                          ) : (
                            <div className="caregiver-you-someone-btns-wrapper">
                              <>
                                <div
                                  className={
                                    "caregiver-you-btn " +
                                    this.state.typeIconClass_you_border
                                  }
                                  style={{
                                    border: `1px solid ${this.state.typeIconClass_you}`,
                                  }}
                                  onClick={this.handlePatientType.bind(
                                    this,
                                    "you"
                                  )}
                                >
                                  <div
                                    className={
                                      "caregiver-buttons " +
                                      this.state.typeIconClass_you
                                    }
                                  >
                                    {this.state.typeIconClass_you ===
                                    "colorGrey" ? (
                                      <img
                                        className="btn-icon-gray"
                                        src={personBlueIcon}
                                        alt="x"
                                      />
                                    ) : (
                                      <img
                                        className="person-btn-icon"
                                        src={personBlueIcon}
                                        alt="x"
                                      />
                                    )}
                                    You
                                  </div>
                                  <div className="check-icon-container">
                                    {this.state.typeIconClass_someone ===
                                      "colorGrey" && (
                                      <img
                                        className="checked-icon"
                                        src={CheckedIcon}
                                        alt="x"
                                      />
                                    )}
                                  </div>
                                </div>
                              </>

                              <div
                                className={
                                  "caregiver-someone-btn " +
                                  this.state.typeIconClass_someone_border
                                }
                                onClick={this.handlePatientType.bind(
                                  this,
                                  "someone"
                                )}
                              >
                                <div
                                  className={
                                    "caregiver-buttons " +
                                    this.state.typeIconClass_someone
                                  }
                                >
                                  {this.state.typeIconClass_someone ===
                                  "colorTypeIcon" ? (
                                    <img
                                      className="btn-icons"
                                      src={addCircleIcon}
                                      alt="x"
                                    />
                                  ) : (
                                    <img
                                      className="btn-plus-icon-gray"
                                      src={addCircleIcon}
                                      alt="x"
                                    />
                                  )}
                                  Someone else
                                </div>
                                <div className="check-icon-container">
                                  {this.state.typeIconClass_someone ===
                                    "colorTypeIcon" && (
                                    <img
                                      className="checked-icon"
                                      src={CheckedIcon}
                                      alt="x"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {this.state.patientType === "caregiver" &&
                        !this.state.prevPatient ? (
                          <div className="col-sm-12 col-lg-12 mt-4">
                            <div className="row">
                              <div className="col-md-5">
                                <label>
                                  How are you related?
                                  <span className="color-pink">*</span>
                                </label>
                                <br />
                                <div className="ext-left">
                                  <select
                                    className="w-100"
                                    name="selectCaregiver"
                                    id="selectCaregiver"
                                    onChange={this.selectCaregiver()}
                                    value={
                                      this.state.registered_for_self
                                        ? this.state.registered_for_self
                                        : ""
                                    }
                                  >
                                    <option hidden>
                                      Select a relationship
                                    </option>
                                    <option value="1">
                                      Family / Friend (Caregiver)
                                    </option>
                                    <option value="2">
                                      Healthcare Professional
                                    </option>
                                  </select>
                                </div>
                                <br />
                                <span style={{ color: "#F969A4" }}>
                                  {this.state.formErrors["caregiver"]}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {this.state.registered_for_self == 1 ? (
                      <div>
                        <CaregiverFields
                          caregiverName={
                            sessionStorage.getItem("caregiver_name") ||
                            this.state.caregiverName
                          }
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          formErrors={this.state.formErrors}
                          patientPhone={
                            sessionStorage.getItem("patient_phone") ||
                            this.state.patientPhone
                          }
                          caregiverEmail={
                            sessionStorage.getItem("caregiver_email") ||
                            this.state.caregiverEmail
                          }
                          caregivergovtID={
                            sessionStorage.getItem("caregiver_govt_id") ||
                            this.state.caregivergovtID
                          }
                          ecaregiverEmail={ecaregiverEmail}
                          ecaregiverName={ecaregiverName}
                          ecaregiverageProof={ecaregiverageProof}
                          ecaregivergovtID={ecaregivergovtID}
                          epatientPhone={epatientPhone}
                          dob={this.state.dob}
                          registered_for_self={this.state.registered_for_self}
                          callbackFromParent={(data) =>
                            this.setState({ caregiverageProof: data })
                          }
                          registration_type={sessionStorage.getItem(
                            "registration_type"
                          )}
                          isNew={sessionStorage.getItem("isNew")}
                          removeMandatoryBasedOnConsultationType={
                            this.state.removeMandatoryBasedOnConsultationType
                          }
                        />
                        <div className="row justify-content-center mt-3">
                          <div className="col-lg-8">
                            <div className="form-group form-check mb-0 mobile-screen-checkbox">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="caregiver"
                                id="caregiver"
                                checked={this.state.caregiver}
                                onChange={this.handleChange("caregiver")}
                                style={{
                                  border: "1px solid rgba(0,0,0,.25)",
                                  backgroundColor: this.state.caregiver
                                    ? "#17bae0"
                                    : "",
                                }}
                              />
                              <label
                                className="form-check-label"
                                for="is_caregiver_false"
                              >
                                I HEREBY AGREE THAT I AM AN AUTHORISED CAREGIVER
                                carrying a formal authorisation or a document
                                establishing my relationship with the patient to
                                REPRESENT the patient during TELEMEDICINE
                                CONSULTATION. I have BEEN VERIFIED BY THE
                                PATIENT IN A PREVIOUS IN-PERSON CONSULT.
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.state.registered_for_self == 2 ? (
                      <div>
                        <CaregiverFields
                          caregiverName={
                            sessionStorage.getItem("caregiver_name") ||
                            this.state.caregiverName
                          }
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          formErrors={this.state.formErrors}
                          patientPhone={
                            sessionStorage.getItem("patient_phone") ||
                            this.state.patientPhone
                          }
                          caregiverEmail={
                            sessionStorage.getItem("caregiver_email") ||
                            this.state.caregiverEmail
                          }
                          caregivergovtID={
                            sessionStorage.getItem("caregiver_govt_id") ||
                            this.state.caregivergovtID
                          }
                          ecaregiverEmail={ecaregiverEmail}
                          ecaregiverName={ecaregiverName}
                          ecaregiverageProof={ecaregiverageProof}
                          ecaregivergovtID={ecaregivergovtID}
                          epatientPhone={epatientPhone}
                          dob={this.state.dob}
                          registered_for_self={this.state.registered_for_self}
                          callbackFromParent={(imageArray) =>
                            this.setState({ caregiverageProof: imageArray })
                          }
                          registration_type={sessionStorage.getItem(
                            "registration_type"
                          )}
                          isNew={sessionStorage.getItem("isNew")}
                          removeMandatoryBasedOnConsultationType={
                            this.state.removeMandatoryBasedOnConsultationType
                          }
                        />

                        <div className="row justify-content-center mt-3">
                          <div className="col-lg-8">
                            <div className="form-group form-check mb-0 mobile-screen-checkbox">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="caregiver"
                                id="caregiver"
                                checked={this.state.caregiver}
                                onChange={this.handleChange("caregiver")}
                                style={{
                                  border: "1px solid rgba(0,0,0,.25)",
                                  backgroundColor: this.state.caregiver
                                    ? "#17bae0"
                                    : "",
                                }}
                              />
                              <label
                                className="form-check-label"
                                for="is_caregiver_false"
                              >
                                I HAVE also BEEN AUTHORIZED BY THE PATIENT TO
                                ACCESS HIS/HER MEDICAL RECORDS AND ENGAGE IN
                                TELEMEDICINE CONSULTATION ON BEHALF OF THE
                                PATIENT. I HEREBY AGREE THAT I WILL MAINTAIN
                                CONFIDENTIALITY FOR PATIENT’S HEALTH
                                CONDITION/RECORDS AND WILL NOT SHARE ANY
                                INFORMATION WITH anyone WITHOUT the PATIENT’S
                                CONSENT.
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="row agreement-outer-row mt-4">
                      <div
                        style={{
                          marginBottom: 10,
                          padding: window.innerWidth > 768 ? "" : "0",
                        }}
                      >
                        {this.state.consultationType === "4" ||
                        this.state.consultationType === "5" ||
                        this.state.consultationType === "6" ? (
                          ""
                        ) : (
                          <p>
                            Note: The Doctor can ask a patient for an in-person
                            visit if required.
                          </p>
                        )}
                      </div>
                      <div className="col-sm-12 col-lg-12 agreement-outer">
                        <input
                          className="form-check-input agreement-checkbox"
                          type="checkbox"
                          name="terms"
                          id="terms"
                          checked={this.state.terms}
                          onChange={this.handleChange("terms")}
                          style={{ border: "1px solid #939393" }}
                        />

                        <label
                          className="form-check-label agreement-text"
                          for="is_terms_false"
                        >
                          I agree to Connect2Clinic's{" "}
                          <a
                            href="/terms.html"
                            target="_blank"
                            style={{ color: "#17BAE0", textDecoration: "none" }}
                          >
                            Terms and Conditions.
                          </a>{" "}
                        </label>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 col-md-12 text-center">
                        <span style={{ color: "#F969A4" }}>
                          {this.state.validation_msg}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <Footer  nextIsDisabled={!this.state.terms} nextHandler={this.onSubmit.bind(this)}/> */}
        <div className="ba-modal-footer">
          <div className="ba-modal-footerbtn">
            <button
              className="back_btn"
              onClick={() => {
                if (this.props.redux.IsShowTeleConsult) {
                  this.props.router.navigate(
                    `${getPathNameHelper(
                      this.props.router.location.pathname
                    )}/selectDateTime`
                  );
                } else {
                  this.props.router.navigate(
                    `${getPathNameHelper(
                      this.props.router.location.pathname
                    )}/selectPatient`
                  );
                }
                this.props.redux.dispatch(
                  setStepper(this.props.redux.currentStepper - 1)
                );
              }}
            >
              Back{" "}
            </button>
            <button
              className="save_continue_btn"
              disabled={!this.state.terms}
              onClick={this.onSubmit.bind(this)}
            >
              Save & Continue{" "}
            </button>
          </div>
          <div className="side-footer-logo">
            <span>Powered By</span> <img src={C2CLogo} alt="c2c" />{" "}
          </div>
        </div>

        <div className="container-fluid">
          {this.state.acceptFlag ? (
            <div className="row">
              <span
                className="pt-5 pb-5 col-sm-12 col-lg-4 offset-lg-5 text-center ml-0 mr-0 fixed"
                style={{ zIndex: "2", position: "fixed" }}
              >
                <strong>
                  NOTE: The doctor can ask a patient for an in-person visit if
                  required.{" "}
                </strong>
                <br />
                <div
                  className="btn btn-light m-3"
                  onClick={this.acceptDiv}
                  style={{ backgroundColor: "#17BAE0", color: "#fff" }}
                >
                  I Accept
                </div>
                <Link
                  className="btn btn-dark m-3"
                  to={`/doctor/${this.state.doctor.short_url_slug}`}
                  style={{ backgroundColor: "#f5f6f7", color: "#17BAE0" }}
                >
                  Decline
                </Link>
              </span>
            </div>
          ) : null}
        </div>

        {/* Modal */}
        <Modal
          show={this.state.loader}
          onHide={this.closeModal}
          style={{ backgroundColor: "#7fffd400" }}
          centered
        >
          <Modal.Body>
            <div className="row please-wait-modal">
              <div className="col-sm-12 col-lg-12 text-center">
                Please wait. . .
                <img src={LoaderLogo} alt="loader" />
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* <GlobalFooter /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    doctorDetails: state.auth.doctorDetails,
  };
};

export default withRouter(connect(mapStateToProps)(PatientDetails));
