import { createSlice } from "@reduxjs/toolkit";

export const selectDoctorSlice = createSlice({
  name: "selectDoctor",
  initialState: {
    specialization: { id: null, name: null },
    selectedDoctorID: null,
  },
  reducers: {
    setSpecializations: (state, action) => {
      state.specialization.id = action.payload.specilizations_id;
      state.specialization.name = action.payload.specilizations;
    },
    setDoctorID: (state, action) => {
      state.selectedDoctorID = action.payload;
    },
    setselectDoctorSliceReset: (state) => {
      state.specialization.id = null;
      state.specialization.name = null;
      state.selectedDoctorID = null;
    },
  },
});

export const { setSpecializations, setDoctorID, setselectDoctorSliceReset } = selectDoctorSlice.actions;

export default selectDoctorSlice.reducer;
