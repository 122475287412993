import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select , {components} from "react-select";
import swal from "sweetalert";
import { setDoctorId, setDoctorSlug } from "../../features/auth/authSlice";
import afterLoginApiAxios from "../../Shared/afterLogin";


const DoctorSelect = (props) => {
    const dispatch = useDispatch();
    const SelectedSpecialization = useSelector((state) => state.user.specialization);
    //const clinicId = useSelector((state) => state.auth.clinic_id);
    const clinicId = sessionStorage.getItem("clinicId");
    const [selectedOption, setSelectedOption] = useState({});
    const [doctorOptions, setDoctorOptions] = useState([]);

    useEffect(() => {
      dispatch(setDoctorId(null));
      dispatch(setDoctorSlug(null));
    }, [])

    useEffect(() => {
      getDoctorList();
      setSelectedOption({});
      dispatch(setDoctorId(null));
      dispatch(setDoctorSlug(null));
    }, [SelectedSpecialization,clinicId])
  
  const customStyles = {
    control: (base) => ({
      ...base,
      width: "100% !important",
      background: "#F5F6F7",
      boxShadow: "none",
      border: "none",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "none",
      },
      height: 35,
      minHeight: 35,
    }),
    menuList: () => ({
      maxHeight: 174,
      overflowY: "scroll",
      "::-webkit-scrollbar": {
        width: "10px"
      },
      "::-webkit-scrollbar-track": {
        background: "#fff" 
      },
      "::-webkit-scrollbar-thumb": {
        background: "#C1C1C1", 
        borderRadius: "4px",
        height: "10px",
      },
      
    }),
    placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: "#A3A9B7",
          fontSize:"14px"
        };
      },
    input: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: "#A3A9B7",
          fontSize:"14px"
        };
      },
    option: (base) => ({
        ...base,
        border: `1px solid #DBDBDB`,
        padding:"10px",
        fontSize:"14px"
      }),
  };

  const getDoctorList = () =>{
  afterLoginApiAxios.get(
      `api/v1/public/specializations/${SelectedSpecialization.id?SelectedSpecialization.id : "all"}/doctors?clinic_id=${clinicId ? clinicId : "all"}`, // "doctor list",
    )
    .then((response) => {
      setDoctorOptions(response.data.data);
    })
    .catch((error) => {
      swal({
        icon: "error",
        title: error.status ? error.message : "Something went wrong!",
        timer: 5000,
      });
      setDoctorOptions([]);
    });

   }


  const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <i
              className="fa fa-caret-down"
              style={{ color: "#000", fontSize: "18px", color:"#17BAE0" }}
            ></i>
        </components.DropdownIndicator>
      )
  };

  const setDoctor = (value) => {
    dispatch(setDoctorId(value.id));
    dispatch(setDoctorSlug(value.short_url_slug));
    setSelectedOption(value)
    props.setSelectDoctorID(value.id)
    props.setSelectDoctorInfo(value)
   };

  return (
    <>
        <Select
         styles={customStyles}
         placeholder="Select Doctor"
        //  defaultValue={selectedOption}
         value={selectedOption.name?selectedOption:""}
         onChange={(value) => setDoctor(value)}
         options={doctorOptions}
         getOptionLabel={(doctorOption) => doctorOption.name}
         getOptionValue={(doctorOption) => doctorOption.id}
         autoFocus={false}
         components={{
          DropdownIndicator: DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        />
    </>
  );
};

export default DoctorSelect;