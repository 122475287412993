import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import authSlice from "../features/auth/authSlice";
import selectDoctorReducer from "../features/selectDoctor/selectDoctorSlice";
import selectPatientSlice from "../features/selectPatient/selectPatientSlice";
import selectTimeSlotSlice from "../features/selectTimeSlot/selectTimeSlotSlice";
import phoneNumberSlice from "../features/setNumber/phoneNumberSlice";
import stepperSlice from "../features/stepper/StepperSlice";
import userSlice from "../features/user/userSlice";
import storage from "redux-persist/lib/storage/session";
import setIsTeleConsultSlice from "../features/setIsTeleConsult/setIsTeleConsultSlice";
const reducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  selectDoctor: selectDoctorReducer,
  selectPatient: selectPatientSlice,
  selectTimeSlot: selectTimeSlotSlice,
  selectNumber: phoneNumberSlice,
  stepper: stepperSlice,
  isTeleConsultSlice: setIsTeleConsultSlice,
});

const persistConfig = {
  key: "root",
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);
export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
