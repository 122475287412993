import { createSlice } from "@reduxjs/toolkit";

export const slectedTimeSlot = createSlice({
  name: "selectPatient",
  initialState: {
    selectedtimeSlotID: null,
    date: null,
  },
  reducers: {
    setTimeSlot: (state, action) => {
      state.selectedtimeSlotID = action.payload;
    },
    setDate: (state, action) => {
      state.selectedDate = action.payload;
    },
  },
});

export const { setTimeSlot, setDate } = slectedTimeSlot.actions;

export default slectedTimeSlot.reducer;
