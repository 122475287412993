import React, { useEffect, useState } from "react";
import moment from "moment";
import afterLoginApiAxios from "../../../Shared/afterLogin";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

const DateSelector = ({
  onSelect,
  consultType = 5,
  selectedDate,
  fromCalender,
  calenderDate,
  track,
  selectedDateMeta,
}) => {
  const [dateArrayState, setDateArray] = useState([]);
  const doctorDetails = useSelector((state) => state.auth.doctorDetails);
  useEffect(() => {
    let clinic_id = sessionStorage.getItem("clinicId");
    if (selectedDateMeta) {
      afterLoginApiAxios
        .post(
          "patient/patient-follow-up-slots-by-doctor-id",
          {
            patient_id: sessionStorage.getItem("user_id"),
            patient_name: sessionStorage.getItem("patient_name"),
            patient_dob: sessionStorage.getItem("patient_dob"),
            patient_account_id: sessionStorage.getItem("patient_account_id"),
            consultation_type: sessionStorage.getItem(
              "followup_consultation_type"
            ),
            doctor_id: doctorDetails?.doctor_id,
            date: moment(selectedDateMeta.meta).format("YYYY-M-D"),
            ...(clinic_id && { clinic_id: clinic_id }),
          },
          {
            headers: {
              authorization: sessionStorage.getItem("id_token"),
            },
          }
        )
        .then((res) => {
          if (res?.data?.follow_up_end_date) {
            handletrack(res.data.follow_up_end_date);
          } else {
            handletrack();
          }
        })
        .catch((err) => console.error(err));
    } else {
      handletrack();
    }
  }, [track]);

  const handletrack = (followUpEndDates) => {
    // Makes 7 Day Array
    const dateArray = [];
    if (consultType === 1) {
      //Follow Up
      if (fromCalender && !moment(calenderDate).isSame(new Date(), "day")) {
        if (followUpEndDates) {
          const todayDate = new Date(moment(new Date()).format("YYYY-MM-DD"));
          const tillFllowUpDate = new Date(
            moment(new Date(followUpEndDates)).format("YYYY-MM-DD")
          );
          const diffTime = Math.abs(tillFllowUpDate - todayDate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          for (let i = 0; i < diffDays + 1; ++i) {
            dateArray.push({
              id: i,
              date: moment().add(i, "day").format("D MMM"),
              day: moment().add(i, "day").format("dddd"),
              meta: moment().add(i, "day"),
            });
          }
          onSelect(
            dateArray[
              moment(
                moment(new Date(followUpEndDates)).format("YYYY MM DD")
              ).diff(moment(moment(calenderDate).format("YYYY MM DD")), "day")
            ]
          );
          setDateArray(dateArray);
        } else {
          if (
            moment(moment(calenderDate).format("YYYY MM DD")).diff(
              moment(moment(new Date()).format("YYYY MM DD")),
              "day"
            ) === 1
          ) {
            for (let i = 0; i < 7; ++i) {
              dateArray.push({
                id: i,
                date: moment().add(i, "day").format("D MMM"),
                day: moment().add(i, "day").format("dddd"),
                meta: moment().add(i, "day"),
              });
            }
            onSelect(dateArray[1]);
            setDateArray(dateArray);
          } else if (
            moment(moment(calenderDate).format("YYYY MM DD")).diff(
              moment(moment(new Date()).format("YYYY MM DD")),
              "day"
            ) === 2
          ) {
            for (let i = 0; i < 7; ++i) {
              dateArray.push({
                id: i,
                date: moment().add(i, "day").format("D MMM"),
                day: moment().add(i, "day").format("dddd"),
                meta: moment().add(i, "day"),
              });
            }
            onSelect(dateArray[2]);
            setDateArray(dateArray);
          } else if (
            moment(moment(calenderDate).format("YYYY MM DD")).diff(
              moment(moment(new Date()).format("YYYY MM DD")),
              "day"
            ) === 3
          ) {
            for (let i = 0; i < 7; ++i) {
              dateArray.push({
                id: i,
                date: moment().add(i, "day").format("D MMM"),
                day: moment().add(i, "day").format("dddd"),
                meta: moment().add(i, "day"),
              });
            }
            onSelect(dateArray[3]);
            setDateArray(dateArray);
          } else if (
            moment(
              moment(new Date(followUpEndDates)).format("YYYY MM DD")
            ).diff(moment(moment(calenderDate).format("YYYY MM DD")), "day") ===
            0
          ) {
            let adjustedDate = new Date(followUpEndDates);
            for (let i = 6; i >= 0; i--) {
              dateArray.push({
                id: i,
                date: moment(adjustedDate).subtract(i, "day").format("D MMM"),
                day: moment(adjustedDate).subtract(i, "day").format("dddd"),
                meta: moment(adjustedDate).subtract(i, "day"),
              });
            }
            onSelect(dateArray[dateArray.length - 1]);
            setDateArray(dateArray);
          } else if (
            moment(
              moment(new Date(followUpEndDates)).format("YYYY MM DD")
            ).diff(moment(moment(calenderDate).format("YYYY MM DD")), "day") ===
            1
          ) {
            let adjustedDate = new Date(followUpEndDates);
            for (let i = 6; i >= 0; i--) {
              dateArray.push({
                id: i,
                date: moment(adjustedDate).subtract(i, "day").format("D MMM"),
                day: moment(adjustedDate).subtract(i, "day").format("dddd"),
                meta: moment(adjustedDate).subtract(i, "day"),
              });
            }
            onSelect(dateArray[dateArray.length - 2]);
            setDateArray(dateArray);
          } else if (
            moment(
              moment(new Date(followUpEndDates)).format("YYYY MM DD")
            ).diff(moment(moment(calenderDate).format("YYYY MM DD")), "day") ===
            2
          ) {
            let adjustedDate = new Date(followUpEndDates);
            for (let i = 6; i >= 0; i--) {
              dateArray.push({
                id: i,
                date: moment(adjustedDate).subtract(i, "day").format("D MMM"),
                day: moment(adjustedDate).subtract(i, "day").format("dddd"),
                meta: moment(adjustedDate).subtract(i, "day"),
              });
            }
            onSelect(dateArray[dateArray.length - 3]);
            setDateArray(dateArray);
          } else {
            for (let i = 3; i >= 1; i--) {
              dateArray.push({
                id: i,
                date: moment(calenderDate).subtract(i, "days").format("D MMM"),
                day: moment(calenderDate).subtract(i, "days").format("dddd"),
                meta: moment(calenderDate).subtract(i, "days"),
              });
            }
            dateArray.push({
              id: 0,
              date: moment(calenderDate).format("D MMM"),
              day: moment(calenderDate).format("dddd"),
              meta: moment(calenderDate),
            });
            for (let i = 1; i <= 3; i++) {
              dateArray.push({
                id: i + 3,
                date: moment(calenderDate).add(i, "days").format("D MMM"),
                day: moment(calenderDate).add(i, "days").format("dddd"),
                meta: moment(calenderDate).add(i, "days"),
              });
            }
            onSelect(dateArray[3]);
            setDateArray(dateArray);
          }
        }
      } else {
        if (followUpEndDates) {
          const todayDate = new Date(moment(new Date()).format("YYYY-MM-DD"));
          const tillFllowUpDate = new Date(
            moment(new Date(followUpEndDates)).format("YYYY-MM-DD")
          );
          const diffTime = Math.abs(tillFllowUpDate - todayDate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          for (let i = 0; i < diffDays + 1; ++i) {
            dateArray.push({
              id: i,
              date: moment().add(i, "day").format("D MMM"),
              day: moment().add(i, "day").format("dddd"),
              meta: moment().add(i, "day"),
            });
          }
          onSelect(dateArray[0]);
          setDateArray(dateArray);
        } else {
          for (let i = 0; i < 7; ++i) {
            dateArray.push({
              id: i,
              date: moment().add(i, "day").format("D MMM"),
              day: moment().add(i, "day").format("dddd"),
              meta: moment().add(i, "day"),
            });
          }
          onSelect(dateArray[0]);
          setDateArray(dateArray);
        }
      }
    } else {
      //Normal consultation
      if (fromCalender && !moment(calenderDate).isSame(new Date(), "day")) {
        if (
          moment(moment(calenderDate).format("YYYY MM DD")).diff(
            moment(moment(new Date()).format("YYYY MM DD")),
            "day"
          ) === 1
        ) {
          for (let i = 0; i < 7; ++i) {
            dateArray.push({
              id: i,
              date: moment().add(i, "day").format("D MMM"),
              day: moment().add(i, "day").format("dddd"),
              meta: moment().add(i, "day"),
            });
          }
          onSelect(dateArray[1]);
          setDateArray(dateArray);
        } else if (
          moment(moment(calenderDate).format("YYYY MM DD")).diff(
            moment(moment(new Date()).format("YYYY MM DD")),
            "day"
          ) === 2
        ) {
          for (let i = 0; i < 7; ++i) {
            dateArray.push({
              id: i,
              date: moment().add(i, "day").format("D MMM"),
              day: moment().add(i, "day").format("dddd"),
              meta: moment().add(i, "day"),
            });
          }
          onSelect(dateArray[2]);
          setDateArray(dateArray);
        } else if (
          moment(moment(calenderDate).format("YYYY MM DD")).diff(
            moment(moment(new Date()).format("YYYY MM DD")),
            "day"
          ) === 3
        ) {
          for (let i = 0; i < 7; ++i) {
            dateArray.push({
              id: i,
              date: moment().add(i, "day").format("D MMM"),
              day: moment().add(i, "day").format("dddd"),
              meta: moment().add(i, "day"),
            });
          }
          onSelect(dateArray[3]);
          setDateArray(dateArray);
        } else if (
          moment(moment(calenderDate).format("YYYY MM DD")).diff(
            moment(moment(new Date()).format("YYYY MM DD")),
            "day"
          ) === 90
        ) {
          for (let i = 6; i >= 0; i--) {
            dateArray.push({
              id: i,
              date: moment(calenderDate).subtract(i, "days").format("D MMM"),
              day: moment(calenderDate).subtract(i, "days").format("dddd"),
              meta: moment(calenderDate).subtract(i, "days"),
            });
          }
          onSelect(dateArray[dateArray.length - 1]);
          setDateArray(dateArray);
        } else if (
          moment(moment(calenderDate).format("YYYY MM DD")).diff(
            moment(moment(new Date()).format("YYYY MM DD")),
            "day"
          ) === 89
        ) {
          let adjustedDate = moment(calenderDate).add(1, "days")._d;
          for (let i = 6; i >= 0; i--) {
            dateArray.push({
              id: i,
              date: moment(adjustedDate).subtract(i, "days").format("D MMM"),
              day: moment(adjustedDate).subtract(i, "days").format("dddd"),
              meta: moment(adjustedDate).subtract(i, "days"),
            });
          }
          onSelect(dateArray[dateArray.length - 2]);
          setDateArray(dateArray);
        } else if (
          moment(moment(calenderDate).format("YYYY MM DD")).diff(
            moment(moment(new Date()).format("YYYY MM DD")),
            "day"
          ) === 88
        ) {
          let adjustedDate = moment(calenderDate).add(2, "days")._d;
          for (let i = 6; i >= 0; i--) {
            dateArray.push({
              id: i,
              date: moment(adjustedDate).subtract(i, "days").format("D MMM"),
              day: moment(adjustedDate).subtract(i, "days").format("dddd"),
              meta: moment(adjustedDate).subtract(i, "days"),
            });
          }
          onSelect(dateArray[dateArray.length - 3]);
          setDateArray(dateArray);
        } else {
          for (let i = 3; i >= 1; i--) {
            dateArray.push({
              id: i,
              date: moment(calenderDate).subtract(i, "days").format("D MMM"),
              day: moment(calenderDate).subtract(i, "days").format("dddd"),
              meta: moment(calenderDate).subtract(i, "days"),
            });
          }
          dateArray.push({
            id: 0,
            date: moment(calenderDate).format("D MMM"),
            day: moment(calenderDate).format("dddd"),
            meta: moment(calenderDate),
          });
          for (let i = 1; i <= 3; i++) {
            dateArray.push({
              id: i + 3,
              date: moment(calenderDate).add(i, "days").format("D MMM"),
              day: moment(calenderDate).add(i, "days").format("dddd"),
              meta: moment(calenderDate).add(i, "days"),
            });
          }
          onSelect(dateArray[3]);
          setDateArray(dateArray);
        }
      } else {
        for (let i = 0; i < 7; ++i) {
          dateArray.push({
            id: i,
            date: moment().add(i, "day").format("D MMM"),
            day: moment().add(i, "day").format("dddd"),
            meta: moment().add(i, "day"),
          });
        }
        onSelect(dateArray[0]);
        setDateArray(dateArray);
      }
    }
  };
    const isAfterOneYear = (date) => {
      const given = moment(date);
      const addOneYear = moment().add(12, "months");
      if (addOneYear.isAfter(given)) {
        return false;
      }
      return true;
    };

    return (
      <div className={"scrtabs-tab-container w-100"}>
        <div className="scrtabs-tab-scroll-arrow">
          <span className="fa fa-angle-left" />
        </div>
        <div className="scrtabs-tabs-fixed-container">
          <div className="scrtabs-tabs-movable-container">
            <ul className="nav nav-tabs" role="tablist">
              {dateArrayState.map((date, i) => (
                <li
                  role={"presentation"}
                  key={date.id}
                  style={{
                    background: "transparent",
                    pointerEvents: isAfterOneYear(date?.meta?._d) && "none",
                  }}
                >
                  <a
                    href="#"
                    role="tab"
                    onClick={() => onSelect(date)}
                    data-toggle="tab"
                  >
                    <h4
                      className={
                        "data-selector-label " +
                        (selectedDate.id === date.id
                          ? "data-selector-label-selected"
                          : "")
                      }
                    >
                      {moment(moment(date.meta._d).format("YYYY MM DD")).diff(
                        moment(moment(new Date()).format("YYYY MM DD")),
                        "day"
                      ) === 0
                        ? "Today"
                        : moment(
                            moment(date.meta._d).format("YYYY MM DD")
                          ).diff(
                            moment(moment(new Date()).format("YYYY MM DD")),
                            "day"
                          ) === 1
                        ? "Tomorrow"
                        : date.date}
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "100",
                        }}
                      >
                        {date.day}
                      </span>
                    </h4>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
};

export default DateSelector;
