import { createSlice } from "@reduxjs/toolkit";

export const setIsTeleConsult = createSlice({
  name: "setIsTeleConsult",
  initialState: {
    IsTeleConsult: false,
    appointmentType: 0,
    currentStepper: 0,
  },
  reducers: {
    IsTeleConsult: (state, action) => {
      state.IsTeleConsult = action.payload;
    },
    PreviousStepperValue: (state, action) => {
      state.currentStepper = action.payload.currentStepper;
      state.appointmentType = action.payload.appointmentType;
    },
  },
});

export const { IsTeleConsult,PreviousStepperValue } = setIsTeleConsult.actions;

export default setIsTeleConsult.reducer;
