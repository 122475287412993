import React, { Component } from 'react'
import moment, { duration } from 'moment'
import './coundownTimerStyle.css'
import { withRouter } from "../../Shared/withRouter";
class CountdownTimer extends Component {
  constructor (props) {
    super(props)
    //Initial time of component
    this.loadTime = moment().add(7, 'minutes')
    this.state = {
      minutes: 0,
      seconds: 0
    }
  }

  setCountdown () {
    //Present time
    const present = moment()

    //Calculate Duration and convert to min and sec
    const timeDuration = duration(this.loadTime.diff(present))

    const mins = timeDuration.minutes()
    const secs = timeDuration.seconds()

    //Set the state
    this.setState({
      minutes: mins,
      seconds: secs
    })
  }

  //Run setCountdown every second to give us the time left in min and sec
  componentDidMount () {
    this.setCountdown()
    this.interval = setInterval(() => {
      this.setCountdown()
    }, 1000)
  }

  componentDidUpdate () {
    if (this.state.minutes === 0 && this.state.seconds === 0) {
      sessionStorage.removeItem('clinicId')
      sessionStorage.removeItem('clinic_id')
      this.props.router.navigate("/thankyou");
    }
  }

  //Clear up the interval calculation method when its unmounting
  componentWillUnmount () {
    clearInterval(this.interval)
  }

  addZero (time) {
    if (time < 10) return `0${time}`
    return time
  }

  render () {
    return (
      <div className='countdown-container'>
        {`Please complete your payment within ${this.addZero(
          this.state.minutes
        )} : ${this.addZero(this.state.seconds)}`}
      </div>
    )
  }
}

export default withRouter(CountdownTimer)
