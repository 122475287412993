import React, { Component } from "react";
import c2cLogo from "../../asstes/images/C2C-Logo.png";

class RequestTimedOut extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      msg: localStorage.getItem("fail_msg"),
    };
  }

  render() {
    return (
      <div className="login-container">
        <div className="widgetModalDiv widgetModalBg">
          <section style={{ width: "60%" }}>
            <div
              className="bg-white shadow-md p-4 text-center"
              style={{ borderRadius: 15 }}
            >
              <div className="row">
                <div className="col-md-8 offset-md-2 text-center doctor-profile">
                  <h3>Booking Request Failed</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 offset-md-2 text-center doctor-profile pt-4">
                  <p style={{ color: "#404760" }}>{this.state.msg}</p>
                </div>
              </div>
            </div>
          </section>
          <div className="WidgetModalFooterLogo">
            Powered By <img alt="" src={c2cLogo} />
          </div>
        </div>
      </div>
    );
  }
}

export default RequestTimedOut;
