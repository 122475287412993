export const styles = {
  control: (base) => ({
    ...base,
    width: "100% !important",
    // background: " #F5F6F7 0% 0% no-repeat padding-box",
    background: "none",
    boxShadow: "none",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "none",
    },
    height: "25px",
    minHeight: "25px",
    fontWeight: "bold",
  }),
  valueContainer: (base) => ({
    ...base,
    height: "40px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media only screen and (max-width: 767px)": {
      height: "36px",
    },
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "19px",
    borderRadius: "0px",
    // background:"red",
    width: "288px",
    marginLeft: "-48px",
  }),

  menuList: (provided) => ({
    ...provided,
    cursor: "pointer",
    maxHeight: "200px",
    paddingTop: "0px",
    "&::-webkit-scrollbar": {
      width: "16px",
      height: " 16px",
      display: "block !important",
      background: "none",
    },
    "&::-webkit-scrollbar-track": {
      background: "none",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      boxShadow: "inset 0 0 16px rgba(240, 232, 232, 1)",
      minHeight: "60px !important",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#888",
      border: "none",
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "black",
      fontWeight: "bold",
    };
  },
  indicatorsContainer: (base, data) => ({
    ...base,
      color:  "red !important",
  }),
};

export const theme = (theme) => ({
  ...theme,
  borderRadius: "5px",
  colors: {
    ...theme.colors,
    primary25: "#fff",
    primary: "#5AD5F2",
  },
});
