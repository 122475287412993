import React from "react";
import "./Loader.css";

export default function () {
  return (
    <div className={"loader__root"}>
      <div className={"loader"}>Loading...</div>
    </div>
  );
}
