import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userDetails:{
        doctor_name:null,
        patient_id:null,
        patient_appiontment_id:null,
        patient_appointment_date:null,
        patient_appointment_start_date_time: null,
        patient_consultation_type:null,
        patient_account_id:null,
        patient_address:null,
        patient_name:null,
        patient_gender:null,
        patient_dob:null,
        patient_age_proof:null,
        patient_email:null,
        patient_phone:null,
        patient_caregiver_email:null,
        patient_caregiver_govt_id:null,
        patient_caregiver_name:null,
        patient_is_follow_up:null,
        patient_is_taking_medicine:null,
        patient_pincode:null,
        patient_statecode:null,
        patient_registration_type:null,
    },
    specialization :{
        id:null,
        name:null,
    }
  },
  reducers: {
    setDoctorName: (state, action) => {
        state.userDetails.doctor_name = action.payload;
      },
    setPatient: (state, action) => {
        state.userDetails.patient_id = action.payload.patient_id;
        state.userDetails.patient_appiontment_id = action.payload.appointment_id;
        state.userDetails.patient_appointment_date = action.payload.appointment_date;
        state.userDetails.patient_appointment_start_date_time = action.payload.appointment_start_date_time;
        state.userDetails.patient_consultation_type = action.payload.consultation_type;
        state.userDetails.patient_account_id = action.payload.patient_account_id;
        state.userDetails.patient_address = action.payload.patient_address;
        state.userDetails.patient_name = action.payload.patient_name;
        state.userDetails.patient_gender = action.payload.patient_gender;
        state.userDetails.patient_dob = action.payload.patient_dob;
        state.userDetails.patient_age_proof = action.payload.age_proof;
        state.userDetails.patient_email = action.payload.email;
        state.userDetails.patient_phone = action.payload.patient_phone;
        state.userDetails.patient_caregiver_email = action.payload.caregiver_email;
        state.userDetails.patient_caregiver_govt_id = action.payload.caregiver_govt_id;
        state.userDetails.patient_caregiver_name = action.payload.caregiver_name;
        state.userDetails.patient_is_follow_up = action.payload.is_follow_up;
        state.userDetails.patient_is_taking_medicine = action.payload.is_taking_medicine;
        state.userDetails.patient_pincode = action.payload.patient_pincode;
        state.userDetails.patient_statecode = action.payload.state_code;
        state.userDetails.patient_registration_type = action.payload.registration_type;
      },
    setSpecialization: (state, action) => {
        state.specialization.id = action.payload.specilizations_id;
        state.specialization.name = action.payload.specializations;
    },

    resetPatientDetials:(state)=>{
      state.userDetails.patient_id  = null; 
      state.userDetails.patient_appiontment_id  = null; 
      state.userDetails.patient_appointment_date  = null; 
      state.userDetails.patient_appointment_start_date_time  = null; 
      state.userDetails.patient_consultation_type  = null;
      state.userDetails.patient_account_id  = null;
      state.userDetails.patient_address  = null;
      state.userDetails.patient_name  = null; 
      state.userDetails.patient_gender  = null; 
      state.userDetails.patient_dob  = null; 
      state.userDetails.patient_age_proof  = null; 
      state.userDetails.patient_email  = null;
      state.userDetails.patient_phone  = null; 
      state.userDetails.patient_caregiver_email  = null; 
      state.userDetails.patient_caregiver_govt_id  = null; 
      state.userDetails.patient_caregiver_name  = null; 
      state.userDetails.patient_is_follow_up  = null; 
      state.userDetails.patient_is_taking_medicine  = null; 
      state.userDetails.patient_pincode  = null; 
      state.userDetails.patient_statecode  = null; 
      state.userDetails.patient_registration_type  = null; 
   
    }
  },
});

export const {setSpecialization, setDoctorName, setPatient, resetPatientDetials } = userSlice.actions;

export default userSlice.reducer;
