import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

function PrivateRoute() {
    // let authToken = useSelector(state => state.auth.token);
    let authToken = sessionStorage.getItem("id_token")
    let location = useLocation();
    if (!authToken) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/patientLogin" state={{ from: location }} />;
    }
  
    return <Outlet />;
  }
  
  export default PrivateRoute;